import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import "../cohort.chart.monthly/chart.css";
import { regionColors, siteGrpColors } from "../colors";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const WeeklyChart = ({ view }) => {
  const { simulationResults } = useStudyPlan();
  const { regionChartWeekly, siteGroupChartWeekly } = simulationResults;
  const data = view === "region" ? regionChartWeekly : siteGroupChartWeekly;
  const colors = view === "region" ? regionColors : siteGrpColors;
  if (!data) {
    return null;
  }

  return (
    <ComposedChart
      width={1000}
      height={550}
      margin={{ top: 5, right: 15, bottom: 80, left: 15 }}
      data={data.Group ?? []}
      style={{ alignSelf: "center" }}
    >
      <Tooltip wrapperStyle={{ fontFamily: "sans-serif" }} />
      <Legend layout="horizontal" verticalAlign="top" align="center" />
      <XAxis
        dataKey="date"
        fontFamily="sans-serif"
        scale="band"
        angle={-90}
        dy={50}
        dx={-5}
        interval={3}
      />
      <YAxis
        interval={0}
        tickcount={10}
        label={{
          fontFamily: "sans-serif",
          value: "Sites",
          angle: -90,
          position: "insideLeft",
          fill: "#666666",
        }}
        domain={["dataMin", "dataMax + 1"]}
        allowDataOverflow
        fontFamily="sans-serif"
        yAxisId="1"
        type="number"
      />
      <YAxis
        label={{
          fontFamily: "sans-serif",
          value: "Patients",
          angle: 90,
          position: "insideRight",
          fill: "#666666",
        }}
        domain={["dataMin", "dataMax + 10"]}
        allowDataOverflow
        fontFamily="sans-serif"
        yAxisId="2"
        type="number"
        orientation="right"
      />
      {data.Bars.map((dataKey, index) => (
        <Bar
          yAxisId="1"
          stackId="a"
          dataKey={dataKey}
          key={dataKey}
          barSize={20}
          fill={colors[index]}
        />
      ))}
      <Line
        yAxisId="2"
        type="monotone"
        dataKey="Screened"
        stroke="#D8E52E"
        strokeWidth={4}
        dot={false}
      />
      <Line
        yAxisId="2"
        type="monotone"
        dataKey="Enrolled"
        stroke="#F38945"
        strokeWidth={4}
        dot={false}
      />
      <Line
        yAxisId="2"
        type="monotone"
        dataKey="Completed"
        stroke="#b3b3b3"
        strokeWidth={4}
        dot={false}
      />
    </ComposedChart>
  );
};

export default WeeklyChart;
