import React, { useState, useEffect } from "react";
import styled from "styled-components";
import API from "../app/utils/api";
import { useSnackbar } from "../app/contexts/snackbar.context";

import SitegroupSearch from "./sitegroup.search";
import SitegroupDetails from "./sitegroup.details";
import { useHistory, useParams } from "react-router-dom";

const AdminData = () => {
  const [sitegroups, setSitegroups] = useState([]);
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (sitegroups.length === 0) {
      API.getAdminSiteGroups()
        .then((res) => {
          setSitegroups(res.data);
          const [first] = res.data;
          if (id) {
            return history.push(`/ctpo/admin-data/${id}`);
          } else if (first.siteGroupId) {
            return history.push(`/ctpo/admin-data/${first.siteGroupId}`);
          }
        })
        .catch((err) => {
          // showSnackbar("Error when fetching sitegroups", "error");
        });
    }
  }, [history, id, showSnackbar, sitegroups.length]);

  return (
    <Layout>
      <SitegroupSearch sitegroups={sitegroups} />
      <SitegroupDetails sitegroups={sitegroups} />
    </Layout>
  );
};

export default AdminData;

const Layout = styled.div`
  padding: 20px 0;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 350px 1fr;
  height: calc(100vh - 80px);
  width: 100%;
  max-width: 1600px;
`;
