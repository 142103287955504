import React, { useState } from "react";
import styled from "styled-components";
import XLSX from "xlsx-js-style";

import { Modal } from "../../../../../../app/components";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";

import { COLUMN_HEADERS as DURATION_HEADERS } from "../../country.start.up.durations/table.utils";
import { COLUMN_HEADERS as TIMELINE_HEADERS } from "../../timeline.summaries/table.utils";
import { COLUMN_HEADERS as SUMMARY_HEADERS } from "../../summary.table/table.utils";
import { COLUMN_HEADERS as PROPOSAL_HEADERS } from "../../summary.table/table.proposal.utils";
import { COLUMN_HEADERS as ENROLLMENT_HEADERS } from "../../study.and.enrollment.milestones/table.utils";
import { COLUMN_HEADERS as START_UP_HEADERS } from "../../country.start.up.milestones/table.utils";

import {
  calculateDropOutRate,
  calculateScreenFailRate,
} from "../../summary.table";
import moment from "moment";
import {
  BodyStyle,
  DEFAULT_DATE_CONFIG,
  HeaderStyle1,
  HeaderStyle2,
  getCellType,
} from "../../../../../../app/contexts/helpers";

const ProposalHeader1Style = {
  font: { bold: true, color: { rgb: "FFFFFF" }, sz: 12 },
  fill: { fgColor: { rgb: "00B0F0" } },
  innerWidth: 20,
  alignment: { wrapText: true, horizontal: "center" },
  border: {
    top: { style: "thin", color: { rgb: "5A5A5A" } },
    right: { style: "thin", color: { rgb: "5A5A5A" } },
    bottom: { style: "thin", color: { rgb: "5A5A5A" } },
    left: { style: "thin", color: { rgb: "5A5A5A" } },
  },
};

// https://www.npmjs.com/package/xlsx-js-style
/*
  This component is used to export the tables in the simulation results page.
  It uses the `xlsx-js-style` library to style the excel sheet.
  The styling is done by passing the `s` property to the cell object.
  The `s` property is an object that contains the styling properties for the cell.
  The styling properties are defined in the `helpers.js` file in the `app/contexts` folder.
  The `s` property is passed to the cell object in the `styleTableContent` function.
  The `styleTableContent` function is used to style the table content.
  The `styleTableContent` function is called in the `download_*` functions.
  The `download_*` functions are used to download the tables in the simulation results page.
  The `download_*` functions are called in the `exportAsCSV` function.
  The `exportAsCSV` function is called when the user clicks the `Export` button.
*/

const ExportTablesModal = ({ open = false, detailsView, onClose, type }) => {
  const { simulationResults, studyPlan } = useStudyPlan();
  const { sponsorName, projectId, studyId, studyPlanName } = studyPlan;
  const {
    durationTable = [],
    timelineTable = [],
    enrollmentTable = [],
    startupTable = [],
    summaryTable = [],
    detailsByWeek = [],
    detailsByMonth = [],
    detailsByWeekNonCumulative = [],
    detailsByMonthNonCumulative = [],
    summaryTableCohort = [],
    detailsByWeekCohort = [],
    detailsByMonthCohort = [],
    detailsByWeekNonCumulativeCohort = [],
    detailsByMonthNonCumulativeCohort = [],
  } = simulationResults;
  const [isChecked, setIsChecked] = useState({});
  const filename_base = `${sponsorName}_${studyPlanName}_${new Date().toString()}_${projectId}_${studyId}`;

  const handleClose = () => {
    setIsChecked({});
    onClose();
  };
  const handleChange = (e, value) => {
    const { name } = e.target;
    setIsChecked((prev) => ({ ...prev, [name]: value }));
  };

  const getDateObject = (str) => {
    const offset = new Date().getTimezoneOffset();
    let dateValue =
      offset > 0
        ? moment(str).add(offset, "minutes").toDate()
        : moment(str).subtract(offset, "minutes").toDate();
    return dateValue === "Invalid Date" ? str : dateValue;
  };

  const combineVertical = (source, additional) => {
    let separator = [];
    let maxRowLength = 0;
    source.forEach((val) => {
      if (val.length > maxRowLength) maxRowLength = val.length;
    });
    additional.forEach((val) => {
      if (val.length > maxRowLength) maxRowLength = val.length;
    });

    source = source.map((value) => {
      while (value.length < maxRowLength) {
        value.push("");
      }
      return value;
    });

    additional = additional.map((value) => {
      while (value.length < maxRowLength) {
        value.push("");
      }
      return value;
    });

    while (separator.length < maxRowLength) {
      separator.push("");
    }

    const destination = [...source, separator, ...additional];

    return destination;
  };

  const combineHorizontal = (source, additional) => {
    const destination = [];
    const length = Math.max(source.length, additional.length);

    for (let i = 0; i < length; i++) {
      let row = [];
      if (i < source.length) {
        row = [...source[i]];
      }

      if (i < additional.length) {
        row = [...row, " ", ...additional[i].slice(1)];
      }
      destination.push(row);
    }

    return destination;
  };

  const exportAsCSV = () => {
    if (Object.keys(isChecked).length === 0) return;

    const wb = XLSX.utils.book_new();
    if (isChecked.countryDurations) {
      const tableContent = download_durations();
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 20 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Country Start-Up Durations"
      );
    }

    if (isChecked.timeline) {
      const tableContent = download_timeline();
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 40 } : { width: 15 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(wb, tableSheet, "Timeline Durations");
    }

    if (isChecked.studyAndEnrollment) {
      const tableContent = download_studyAndEnrollment();
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 10 } : { width: 15 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(wb, tableSheet, "Enrollment Milestones");
    }

    if (isChecked.summaryCohort) {
      const tableContent = download_summaryTable("cohort");
      const summaryTable_sheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 15 }
        );
        summaryTable_sheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        summaryTable_sheet,
        "Summary Table-Cohort"
      );
    }

    if (isChecked.summary) {
      const summaryTable_content = download_summaryTable();
      const summaryTable_sheet = XLSX.utils.aoa_to_sheet(
        summaryTable_content,
        DEFAULT_DATE_CONFIG
      );
      if (summaryTable_content.length > 0) {
        const sheetCols = summaryTable_content[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 15 }
        );
        summaryTable_sheet["!cols"] = sheetCols;
      }

      XLSX.utils.book_append_sheet(wb, summaryTable_sheet, "Summary Table");
    }

    if (isChecked.countryMilestones) {
      const tableContent = download_startupTable();
      const tableSheet = XLSX.utils.aoa_to_sheet(
        download_startupTable(),
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 20 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Country Start-Up Milestones"
      );
    }

    if (isChecked.detailsMonthly) {
      const tableContent = download_detailsTableMonthly();
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 5 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Details Monthly(Cumulative)"
      );
    }

    if (isChecked.detailsMonthlyNonCumulative) {
      const tableContent = download_detailsTableMonthlyNonCumulative();
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 5 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Details Monthly(Non-Cumulative)"
      );
    }

    if (isChecked.detailsWeekly) {
      const tableContent = download_detailsTableWeekly();
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 5 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Details Weekly(Cumulative)"
      );
    }

    if (isChecked.detailsWeeklyNonCumulative) {
      const tableContent = download_detailsTableWeeklyNonCumulative();
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 5 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Details Weekly(Non-Cumulative)"
      );
    }

    if (isChecked.detailsMonthlyCohort) {
      const tableContent = download_detailsTableMonthly("cohort");
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 5 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Details Monthly(Cum)-Cohort"
      );
    }

    if (isChecked.detailsMonthlyNonCumulativeCohort) {
      const tableContent = download_detailsTableMonthlyNonCumulative("cohort");
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 5 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Details Monthly(Non-Com)-Cohort"
      );
    }

    if (isChecked.detailsWeeklyCohort) {
      const tableContent = download_detailsTableWeekly("cohort");
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 5 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Details Weekly(Cum)-Cohort"
      );
    }

    if (isChecked.detailsWeeklyNonCumulativeCohort) {
      const tableContent = download_detailsTableWeeklyNonCumulative("cohort");
      const tableSheet = XLSX.utils.aoa_to_sheet(
        tableContent,
        DEFAULT_DATE_CONFIG
      );
      if (tableContent.length > 0) {
        const sheetCols = tableContent[0].map((value, index) =>
          index === 0 ? { width: 15 } : { width: 5 }
        );
        tableSheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        tableSheet,
        "Details Weekly(Non-Cum)-Cohort"
      );
    }

    if (isChecked.combinedReport) {
      const studyAndEnrollment_content = download_studyAndEnrollment();
      const summaryTable_content = download_summaryTable();
      const startupTable_content = download_startupTableCombo();
      const combinedOne = combineHorizontal(
        summaryTable_content,
        startupTable_content
      );

      const combinedReport_content = combineVertical(
        studyAndEnrollment_content,
        combinedOne
      );

      const combinedReport_sheet = XLSX.utils.aoa_to_sheet(
        combinedReport_content,
        DEFAULT_DATE_CONFIG
      );
      if (combinedReport_content.length > 0) {
        const sheetCols = combinedReport_content[0].map((value) => ({
          wch: 11,
        }));
        combinedReport_sheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(wb, combinedReport_sheet, "Combined Report");
    }

    if (isChecked.proposal) {
      const proposalTable_content = download_proposalTable();
      const proposalTable_sheet = XLSX.utils.aoa_to_sheet(
        proposalTable_content,
        DEFAULT_DATE_CONFIG
      );
      if (proposalTable_content.length > 0) {
        const sheetCols = proposalTable_content[1].map((value) => ({
          wch: 11,
        }));
        proposalTable_sheet["!cols"] = sheetCols;

        const mergeCells = [
          {
            s: { r: 0, c: 0 },
            e: { r: 0, c: proposalTable_content[1].length - 1 },
          },
          {
            s: { r: proposalTable_content.length - 1, c: 0 },
            e: { r: proposalTable_content.length - 1, c: 1 },
          },
        ];
        proposalTable_sheet["!merges"] = mergeCells;
      }
      XLSX.utils.book_append_sheet(wb, proposalTable_sheet, "Proposal Table");
    }
    // added cellDates option value to format date fields
    XLSX.writeFile(wb, `${filename_base}.xlsx`, XLSX.cellDates);
    setIsChecked({});
    onClose();
  };

  const download_durations = () => {
    const newDurations = durationTable.map((item) => {
      return [
        item.siteGroupName,
        item["Site ID Duration (start to last site ID)"],
        item["Submission to Approval"],
        item["Approval to First Site Initiated"],
        item["Site Initiation Duration"],
      ];
    });

    newDurations.unshift(["", ...Object.values(DURATION_HEADERS)]);

    return styleTableContentWithSubHeader(newDurations);
  };

  const download_timeline = () => {
    const newTimelineTable = timelineTable.map((item) => {
      return [item.DurationLabel, item.Duration];
    });

    newTimelineTable.unshift(["", TIMELINE_HEADERS["Duration"]]);

    return styleTableContent(newTimelineTable, 0);
  };

  const download_studyAndEnrollment = () => {
    const newEnrollment = enrollmentTable.map((item) => {
      return [
        item.SortName,
        getDateObject(item["Sites Initiated"]),
        getDateObject(item["Screened"]),
        getDateObject(item["Enrolled"]),
        getDateObject(item["Completed Treatment"]),
        getDateObject(item["Completed Follow-up"]),
      ];
    });

    newEnrollment.unshift(["", ...Object.values(ENROLLMENT_HEADERS)]);
    return styleTableContent(newEnrollment);
  };

  const download_summaryTable = (type = "") => {
    const tableData = type === "cohort" ? summaryTableCohort : summaryTable;
    const tableContent = tableData.map((item) => {
      item["Screen Fail Rate"] = calculateScreenFailRate(item);
      item["Drop Out Rate"] = calculateDropOutRate(item);

      return [
        type === "cohort" ? item.cohortName : item.siteGroupName,
        getDateObject(item["Enrollment Start"]),
        item["Number of Activated Sites"],
        item["Screen Rate"],
        item["Enrollment Rate"],
        item["Number of Patients Screened"],
        item["Number of Patients Enrolled"],
        item["Number of Patients Completing Treatment"],
        item["Enrollment Duration"],
        item["Screen Fail Rate"],
        item["Drop Out Rate"],
        item["Site Months"],
      ];
    });

    tableContent.unshift(["", ...Object.values(SUMMARY_HEADERS)]);

    return styleTableContentWithSubHeader(tableContent);
  };

  const download_proposalTable = () => {
    const newSummaryTable = summaryTable.map((item) => {
      item["Screen Fail Rate"] = calculateScreenFailRate(item);
      item["Drop Out Rate"] = calculateDropOutRate(item);

      return [
        item.siteGroupName,
        getDateObject(item["Enrollment Start"]),
        item["Number of Activated Sites"],
        item["Enrollment Duration"],
        item["Number of Patients Screened"],
        item["Number of Patients Enrolled"],
        item["Number of Patients Completing Treatment"],
      ];
    });

    return styleTableContentWithSubHeaders(
      [
        [`${summaryTable[0]["Enrollment Rate"] || ""} p/s/m Enrollment Rate`],
        [...Object.values(PROPOSAL_HEADERS)],
        ...newSummaryTable.slice(1),
        ["Totals", "", ...newSummaryTable[0].slice(2)],
      ],
      false,
      false,
      "proposal"
    );
  };

  const download_startupTable = () => {
    const newStartupTable = startupTable.map((item) => {
      return [
        item.siteGroupName,
        getDateObject(item["Project Start Date"]),
        getDateObject(item["Protocol Final Date"]),
        getDateObject(item["Site ID Finish"]),
        getDateObject(item["Regulatory Submission"]),
        getDateObject(item["First Regulatory Approval"]),
        getDateObject(item["First Site Initiated"]),
        getDateObject(item["Last Site Initiated"]),
        getDateObject(item["First Screened"]),
        getDateObject(item["First Enrolled"]),
        getDateObject(item["Last Enrolled"]),
      ];
    });

    

    newStartupTable.unshift(["", ...Object.values(START_UP_HEADERS)]);

    return styleTableContentWithSubHeader(newStartupTable);
  };

  const download_startupTableCombo = () => {
    const newStartupTableCombo = startupTable.map((item) => {
      return [
        item.siteGroupName,
        getDateObject(item["Project Start Date"]),
        getDateObject(item["Protocol Final Date"]),
        getDateObject(item["Site ID Finish"]),
        getDateObject(item["Regulatory Submission"]),
        getDateObject(item["First Regulatory Approval"]),
        getDateObject(item["First Site Initiated"]),
        getDateObject(item["Last Site Initiated"]),
        getDateObject(item["First Screened"]),
        getDateObject(item["First Enrolled"]),
        getDateObject(item["Last Enrolled"]),
        item["Project Start to Site ID Finish"],
        item["Site ID Finish to First Regulatory Submission"],
        item["First Submission to First Site Start-up Activities Complete"],
        item["Start-up Activities Complete to First Site Activated"],
        item["First Site Activated to Last Site Activated"],
        item["First Site Activated to First Screened"],
      ];
    });

    

    newStartupTableCombo.unshift([
      "",
      ...Object.values(START_UP_HEADERS),
      "Project Start to Site ID Finish",
      "Site ID Finish to First Regulatory Submission",
      "First Submission to First Site Start-up Activities Complete",
      "Start-up Activities Complete to First Site Activated", 
      "First Site Activated to Last Site Activated",
      "First Site Activated to First Screened",
    ]);

    return styleTableContentWithSubHeader(newStartupTableCombo);
  };

  const styleTableContentWithSubHeaders = (
    tableContent,
    verticalTopHeader = false,
    includeDay = false,
    name = ""
  ) => {
    const styledTableContent = [];
    for (let i = 0; i < tableContent.length; i++) {
      if (!styledTableContent[i]) styledTableContent.push([]);
      for (let j = 0; j < tableContent[i].length; j++) {
        const value = tableContent[i][j];
        const type = getCellType(value);
        styledTableContent[i].push({
          v: value,
          ...(type === "d"
            ? i === 0 && verticalTopHeader
              ? includeDay
                ? { z: "dd-mmm-yy" }
                : { z: "mmm-yy" }
              : { z: "dd mmm yyyy" }
            : {}),
          t: type,
          s:
            i === 0
              ? {
                  ...(name === "proposal"
                    ? ProposalHeader1Style
                    : HeaderStyle1),
                  ...(verticalTopHeader
                    ? {
                        alignment: {
                          vertical: "center",
                          horizontal: "center",
                          textRotation: 90,
                        },
                      }
                    : {}),
                }
              : i === 1 && name === "proposal"
              ? HeaderStyle1
              : [
                  "Sites Activated",
                  "Screened Patients",
                  "Enrolled Patients",
                  "Completed Patients",
                  "Totals",
                ].includes(tableContent[i][0])
              ? HeaderStyle2
              : BodyStyle,
        });
      }
    }

    return styledTableContent;
  };

  const styleTableContentWithSubHeader = (tableContent) => {
    const styledTableContent = [];
    for (let i = 0; i < tableContent.length; i++) {
      if (!styledTableContent[i]) styledTableContent.push([]);
      for (let j = 0; j < tableContent[i].length; j++) {
        const value = tableContent[i][j];
        const type = getCellType(value);
        styledTableContent[i].push({
          v: value,
          ...(type === "d" ? { z: "dd mmm yyyy" } : {}),
          t: type,
          s: i === 0 ? HeaderStyle1 : i === 1 ? HeaderStyle2 : BodyStyle,
        });
      }
    }
    return styledTableContent;
  };

  const styleTableContent = (tableContent, leftAlignIndex = -1) => {
    const styledTableContent = [];
    for (let i = 0; i < tableContent.length; i++) {
      if (!styledTableContent[i]) styledTableContent.push([]);
      for (let j = 0; j < tableContent[i].length; j++) {
        const value = tableContent[i][j];
        const type = getCellType(value);
        styledTableContent[i].push({
          v: value,
          ...(type === "d" ? { z: "dd mmm yyyy" } : {}),
          t: type,
          s:
            i === 0
              ? {
                  ...HeaderStyle1,
                  ...(leftAlignIndex === j
                    ? { alignment: { wrapText: true, horizontal: "left" } }
                    : {}),
                }
              : {
                  ...BodyStyle,
                  ...(leftAlignIndex === j
                    ? { alignment: { wrapText: true, horizontal: "left" } }
                    : {}),
                },
        });
      }
    }
    return styledTableContent;
  };

  const download_detailsTableMonthly = (type = "") => {
    let data = [];
    let headers = [];

    const tableData = type === "cohort" ? detailsByMonthCohort : detailsByMonth;

    headers = make_headers(tableData);
    data.push(
      ...make_data(tableData?.table_body?.active_sites, "Sites Activated")
    );
    data.push(
      ...make_data(tableData?.table_body?.screened, "Screened Patients")
    );
    data.push(
      ...make_data(tableData?.table_body?.enrolled, "Enrolled Patients")
    );
    data.push(
      ...make_data(tableData?.table_body?.completed, "Completed Patients")
    );

    return styleTableContentWithSubHeaders([headers, ...data], true);
  };

  const download_detailsTableMonthlyNonCumulative = (type = "") => {
    let data = [];
    let headers = [];
    const tableData =
      type === "cohort"
        ? detailsByMonthNonCumulativeCohort
        : detailsByMonthNonCumulative;

    headers = make_headers(tableData);
    data.push(
      ...make_data(tableData?.table_body?.active_sites, "Sites Activated")
    );
    data.push(
      ...make_data(tableData?.table_body?.screened, "Screened Patients")
    );
    data.push(
      ...make_data(tableData?.table_body?.enrolled, "Enrolled Patients")
    );
    data.push(
      ...make_data(tableData?.table_body?.completed, "Completed Patients")
    );

    return styleTableContentWithSubHeaders([headers, ...data], true);
  };

  const download_detailsTableWeekly = (type = "") => {
    let headers = [];
    let data = [];
    const tableData = type === "cohort" ? detailsByWeekCohort : detailsByWeek;

    headers = make_headers(tableData);
    data.push(
      ...make_data(tableData?.table_body?.active_sites, "Sites Activated")
    );
    data.push(
      ...make_data(tableData?.table_body?.screened, "Screened Patients")
    );
    data.push(
      ...make_data(tableData?.table_body?.enrolled, "Enrolled Patients")
    );
    data.push(
      ...make_data(tableData?.table_body?.completed, "Completed Patients")
    );

    return styleTableContentWithSubHeaders([headers, ...data], true, true);
  };

  const download_detailsTableWeeklyNonCumulative = (type = "") => {
    let headers = [];
    let data = [];

    const tableData =
      type === "cohort"
        ? detailsByWeekNonCumulativeCohort
        : detailsByWeekNonCumulative;

    headers = make_headers(tableData);
    data.push(
      ...make_data(tableData?.table_body?.active_sites, "Sites Activated")
    );
    data.push(
      ...make_data(tableData?.table_body?.screened, "Screened Patients")
    );
    data.push(
      ...make_data(tableData?.table_body?.enrolled, "Enrolled Patients")
    );
    data.push(
      ...make_data(tableData?.table_body?.completed, "Completed Patients")
    );

    return styleTableContentWithSubHeaders([headers, ...data], true, true);
  };

  const make_headers = (data) => {
    const headers = [...data.header_dates.map((item) => getDateObject(item))];
    headers.unshift("");
    return headers;
  };

  const make_data = (data, header) => {
    const data_array = data.header_totals.map((item) => Number(item));
    data_array.unshift(header);

    const sub_table = data.sub_table_body?.map((item) => {
      const data = item.data.map((item) => Number(item));
      data.unshift(item.country_name);
      return data;
    });

    return [data_array, ...sub_table];
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Export Tables"
      aria-describedby="Export Tables"
      title="Export Tables"
      sx={{ minWidth: "30rem", maxWidth: "100%", width: "70rem" }}
    >
      <FormGroup>
        <Container>
          <SubContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.detailsMonthly}
                  onChange={handleChange}
                  name="detailsMonthly"
                />
              }
              label={`Details Table Monthly (Cumulative)`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.detailsMonthlyNonCumulative}
                  onChange={handleChange}
                  name="detailsMonthlyNonCumulative"
                />
              }
              label={`Details Table Monthly (Non-Cumulative)`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.detailsWeekly}
                  onChange={handleChange}
                  name="detailsWeekly"
                />
              }
              label={`Details Table Weekly (Cumulative)`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.detailsWeeklyNonCumulative}
                  onChange={handleChange}
                  name="detailsWeeklyNonCumulative"
                />
              }
              label={`Details Table Weekly (Non-Cumulative)`}
            />
          </SubContainer>
          {type === "cohort" && (
            <SubContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked.detailsMonthlyCohort}
                    onChange={handleChange}
                    name="detailsMonthlyCohort"
                  />
                }
                label={`Details Table Monthly (Cumulative) - Cohort`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked.detailsMonthlyNonCumulativeCohort}
                    onChange={handleChange}
                    name="detailsMonthlyNonCumulativeCohort"
                  />
                }
                label={`Details Table Monthly (Non-Cumulative) - Cohort`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked.detailsWeeklyCohort}
                    onChange={handleChange}
                    name="detailsWeeklyCohort"
                  />
                }
                label={`Details Table Weekly (Cumulative) - Cohort`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked.detailsWeeklyNonCumulativeCohort}
                    onChange={handleChange}
                    name="detailsWeeklyNonCumulativeCohort"
                  />
                }
                label={`Details Table Weekly (Non-Cumulative) - Cohort`}
              />
            </SubContainer>
          )}
          <SubContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.summary}
                  onChange={handleChange}
                  name="summary"
                />
              }
              label="Summary Table"
            />
            {type === "cohort" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked.summaryCohort}
                    onChange={handleChange}
                    name="summaryCohort"
                  />
                }
                label="Summary Table - Cohort"
              />
            )}
          </SubContainer>
          <SubContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.countryMilestones}
                  onChange={handleChange}
                  name="countryMilestones"
                />
              }
              label="Country Start-up Milestones"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.studyAndEnrollment}
                  onChange={handleChange}
                  name="studyAndEnrollment"
                />
              }
              label="Enrollment Milestones"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.timeline}
                  onChange={handleChange}
                  name="timeline"
                />
              }
              label="Timeline Durations"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.countryDurations}
                  onChange={handleChange}
                  name="countryDurations"
                />
              }
              label="Country Start-up Durations"
            />
          </SubContainer>
          <SubContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.combinedReport}
                  onChange={handleChange}
                  name="combinedReport"
                />
              }
              label="Combined Report"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked.proposal}
                  onChange={handleChange}
                  name="proposal"
                />
              }
              label="Proposal Report"
            />
          </SubContainer>
        </Container>
        <ButtonContainer>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={exportAsCSV} color="primary" variant="contained">
            Export
          </Button>
        </ButtonContainer>
      </FormGroup>
    </Modal>
  );
};

export default ExportTablesModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SubContainer = styled.div`
  display: flex;
  gap: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
