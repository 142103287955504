import { Divider, Paper } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import StudyPlanSearch from "../../study.plan.search";
import Header from "../common/header";
import ScheduleEventsTable from "../schedule.events.table";
import StudyTable from "../study.table";

export default function TablesContainer() {
  return (
    <MainContainer>
      <StudyPlanSearch hasMargin={false} />
      <Paper>
        <Container>
          <Header />
          <StudyTable />
          <Divider style={{ margin: "3rem 0px" }} />
          <ScheduleEventsTable />
        </Container>
      </Paper>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 350px 1fr;
  width: 100%;
  max-width: 1600px;
`;

const Container = styled.div`
  margin-bottom: 2em;
  padding: 1em;
`;
