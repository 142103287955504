import React from "react";
import styled from "styled-components";
import { Title } from "../../components";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";

const ParameterBox = (props) => {
  const {
    parameterDescr = "Please Enter A Title",
    lowerBound = null,
    upperBound = null,
    levelOfConfidence = 0,
    comments = "",
    parameterName,
    onChange,
  } = props;

  const paperClasses = paperStyles();
  const inputClasses = inputStyles();

  const handleChange = (e, parameterName, property) => {
    if (e.target.value === "") {
      onChange(parameterName, { [property]: null });
    } else {
      onChange(parameterName, { [property]: parseFloat(e.target.value) });
    }
  };

  return (
    <Paper classes={paperClasses} elevation={0}>
      <div>
        <Title size="small">{parameterDescr.toLocaleUpperCase()}</Title>
        <Divider />
      </div>
      <TextFieldContainer>
        <TextField
          label="Lower Bound"
          type="number"
          value={lowerBound}
          onChange={(e) => handleChange(e, parameterName, "lowerBound")}
          inputProps={{
            type: "number",
          }}
          variant="outlined"
          classes={inputClasses}
        />
        <TextField
          label="Upper Bound"
          type="number"
          value={upperBound}
          onChange={(e) => handleChange(e, parameterName, "upperBound")}
          inputProps={{
            type: "number",
          }}
          variant="outlined"
          classes={inputClasses}
        />
        <TextField
          label="Level of Confidence"
          type="number"
          value={levelOfConfidence}
          onChange={(e) => handleChange(e, parameterName, "levelOfConfidence")}
          inputProps={{
            type: "number",
          }}
          variant="outlined"
          classes={inputClasses}
        />

        <TextField
          label="Comments"
          value={comments}
          onChange={(e) =>
            onChange(parameterName, { comments: e.target.value })
          }
          variant="outlined"
          classes={inputClasses}
        />
      </TextFieldContainer>
    </Paper>
  );
};

export default ParameterBox;

const TextFieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 3fr;
  grid-gap: 2em;
`;

const paperStyles = makeStyles((theme) => ({
  root: {
    background: "#f3f4f4",
    display: "grid",
    gridGap: "1em",
    padding: "1em",
    border: "1px solid #ccc",
    paddingBottom: "2em",
  },
}));

const inputStyles = makeStyles((theme) => ({
  root: {
    background: "white",
  },
}));
