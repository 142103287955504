import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import EditIcon from "@material-ui/icons/Edit";
import Label from "./label";
import moment from "moment";

const PrimaryDetails = () => {
  const history = useHistory();
  const { id, planId } = useParams();
  const { studyPlan, canEditPlan } = useStudyPlan();

  const {
    studyPlanName,
    studyPlanDescr,
    croProjectStartDateMillis,
    finalProtocolStartDateMillis,
    scenarioStatus,
  } = studyPlan;

  const startDate = moment(croProjectStartDateMillis)
    .utc()
    .format("DD/MMM/YYYY");
  const protocolDate = moment(finalProtocolStartDateMillis)
    .utc()
    .format("DD/MMM/YYYY");
  return (
    <Container>
      <Content>
        <Label label="Study Plan Name" value={studyPlanName} />
        <Label label="CRO Start Date" value={startDate} />
        <Label label="Description" value={studyPlanDescr} />
        <Label label="Protocol Date" value={protocolDate} />
        <Label label="Status" value={scenarioStatus} />
      </Content>
      {canEditPlan && (
        <div>
          <Button
            color="primary"
            onClick={() =>
              history.push(`/ctpo/study-plans/${id}/${planId}#details`)
            }
          >
            <EditIcon style={{ marginRight: ".3em" }} /> Edit
          </Button>
        </div>
      )}
    </Container>
  );
};

export default PrimaryDetails;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;
