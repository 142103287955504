import React from "react";
import { AccordionTable } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { COLUMN_HEADERS } from "./table.utils";

import TableCell from "@material-ui/core/TableCell";

const CountryStartUpMilestones = () => {
  const { simulationResults } = useStudyPlan();
  const { startupTable } = simulationResults;

  return (
    <AccordionTable
      width={"100%"}
      summary="Country Start-Up Milestones"
      columns={COLUMN_HEADERS}
      // style={{ width: "max-content" }}
      data={startupTable}
      renderRow={(item) => (
        <>
          <TableCell component="th" scope="row">
            <strong>{item.siteGroupName}</strong>
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["Project Start Date"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["Protocol Final Date"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["Site ID Finish"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["Regulatory Submission"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["First Regulatory Approval"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["First Site Initiated"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["Last Site Initiated"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["First Screened"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["First Enrolled"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
            {item["Last Enrolled"] ?? "DD/MMM/YYYY"}
          </TableCell>
        </>
      )}
    />
  );
};

export default CountryStartUpMilestones;
