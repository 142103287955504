import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Title } from "../../../../../app/components";

const ViewSelector = ({ onChange, value }) => (
    <div>
        <div style={{ marginBottom: "1em" }}>
        <Title>Chart Control</Title>
        </div>
        <FormControl variant="outlined" style={{ width: "220px" }}>
        <InputLabel id="view-simple-select-outlined-label">
            Select Chart View
        </InputLabel>
        <Select
            labelId="view-simple-select-outlined-label"
            id="view-simple-select-outlined"
            value={value}
            onChange={(event) => onChange(event.target.value)}
            label="Select Table View"
        >
            <MenuItem value="region">Region</MenuItem>
            <MenuItem value="site-group">Site-Group</MenuItem>
        </Select>
        </FormControl>
  </div>
);

export default ViewSelector;