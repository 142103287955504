import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { Title } from "../../../app/components";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useFavorite } from "../../../app/contexts/favorites.context";
import { useStudyPlan } from "../../../app/contexts/study.context";
import { useUser } from "../../../app/contexts/user.context";

const Header = () => {
  const { study } = useStudyPlan();
  const { isFavorite, removeFavorite, createFavorite } = useFavorite();
  const descClasses = useDescStyles();
  const iconClasses = useIconStyles();
  const disabledClasses = useDisabledStyles();
  const { user } = useUser();
  return (
    <Container>
      <SideBySide>
        <Title>
          {study?.sponsorName} - {study?.projectId}
        </Title>
        {isFavorite({ id: study?.studyId }) ? (
          <IconButton
            onClick={() =>
              removeFavorite({
                studyId: study?.studyId,
                userId: user.userId,
              })
            }
          >
            <StarIcon classes={iconClasses} />
          </IconButton>
        ) : (
          <IconButton
            onClick={() =>
              createFavorite({
                studyId: study?.studyId,
                projectId: study?.projectId,
                userId: user.userId,
                isFavorite: 1,
                studyName: study?.studyName,
              })
            }
          >
            <StarBorderIcon classes={disabledClasses} />
          </IconButton>
        )}
      </SideBySide>
      <Typography classes={descClasses}>{study?.studyName}</Typography>
      <Divider />
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
`;

const useDescStyles = makeStyles((theme) => ({
  root: {
    color: "#002e6e",
    marginBottom: "1em",
  },
}));

const useIconStyles = makeStyles((theme) => ({
  root: {
    fill: theme.palette.primary.main,
  },
}));

const useDisabledStyles = makeStyles((theme) => ({
  root: {
    fill: "#85878a",
  },
}));
