import {
  Button,
  CircularProgress,
  LinearProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { FIELD_TYPES, FIELD_TYPE_OPTIONS } from "./constants";
import React, { useEffect, useMemo, useState } from "react";

import API from "../../../app/utils/api";
import Content from "./country.tabs/content";
import Controls from "../schedule.common/controls";
import CountryTabs from "./country.tabs";
import ExcelExports from "./excel.exports";
import ImportCost from "./import.country.costs";
import Header from "./header";
import { Tooltip } from "../../../app/components";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useScheduleEvents } from "../../../app/contexts/schedule.events.context";
import { useSnackbar } from "../../../app/contexts/snackbar.context";
import { useUser } from "../../../app/contexts/user.context";

export default function SOE() {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);

  const [fieldType, setFieldType] = useState(
    queryParams.get("bf") || FIELD_TYPES["VISIT"]
  );
  const [siteId, setSiteId] = useState(
    parseInt(queryParams.get("siteId") || "1")
  );
  const [working, setWorking] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { loadingSOEStudy } = useScheduleEvents();
  const [value, setValue] = useState(0);
  const { SOECountries, loadingCountrySOE } = useScheduleEvents();
  const [renderKey] = useState("");
  const { showSnackbar } = useSnackbar();

  const { id, scheduleId } = useParams();
  const { setURLStudyId } = useUser();
  const {
    fetchAssessmentNames,
    fetchSOEVisits,
    fetchSOEAssessments,
    fetchASingleSOE,
    fetchSOEMapping,
    fetchSOECountries,
    fetchRiskAssessments,
    fetchVisitSampleStrategy,
  } = useScheduleEvents();

  const countryValues = useMemo(() => {
    return SOECountries.filter(
      (country) => country.siteId === 1 || country.siteGroupId === 0
    );
  }, [SOECountries]);

  useEffect(() => {
    setURLStudyId(id);
    (async () => {
      await fetchASingleSOE(scheduleId);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      fetchAssessmentNames().then(async () => {
        await fetchSOEData();
      });
    })();
    // eslint-disable-next-line
  }, []);

  const fetchSOEData = async () => {
    await fetchSOEVisits(scheduleId, id);
    await fetchSOEAssessments(scheduleId, id);
    await fetchSOEMapping(scheduleId);
    await fetchSOECountries(scheduleId);
    await fetchRiskAssessments(scheduleId);
    await fetchVisitSampleStrategy(scheduleId);
  };

  const updateUrlWithNewQueryParams = (updates) => {
    if (!Object.keys(updates)) return;
    const existingParams = {};
    for (let [key, value] of queryParams.entries()) {
      existingParams[key] = value;
    }
    const totalParams = { ...existingParams, ...updates };
    history.push({
      path: history.location.pathname,
      search: `?${Object.keys(totalParams)
        .map((key) => `${key}=${totalParams[key]}`)
        .join("&")}`,
    });
  };

  const refreshSiteList = () => {
    setWorking(true);
    API.refreshSiteSelectedList(id, scheduleId)
      .then(() => {
        setWorking(false);
        fetchSOEData();
        showSnackbar("Site list refreshed", "success");
      })
      .catch(() => {
        showSnackbar("Failed to refresh site list", "error");
        setWorking(false);
      });
  };

  useEffect(() => {
    if (SOECountries.length && queryParams.get("sg")) {
      setValue(parseInt(queryParams.get("sg")));
    }
    // eslint-disable-next-line
  }, [SOECountries]);

  return (
    <Container>
      {loadingSOEStudy ? (
        <LinearProgress />
      ) : (
        <>
          <Controls editOpen={editOpen} setEditOpen={setEditOpen} />
          <Header />
          <CountryTabs
            tabs={countryValues}
            value={value}
            onChange={(event, tab) => {
              updateUrlWithNewQueryParams({ sg: tab, siteId: 1 });
              setValue(tab);
              setSiteId(1);
            }}
          />
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "1em" }}
          >
            <FieldOptionsContainer>
              <TextField
                style={{ width: "220px" }}
                select
                label="Business Function"
                id="field-type"
                value={fieldType}
                onChange={(event) => {
                  setFieldType(event.target.value);
                  updateUrlWithNewQueryParams({ bf: event.target.value });
                }}
                size="small"
                variant="outlined"
              >
                {FIELD_TYPE_OPTIONS.map((fto) => (
                  <MenuItem key={fto.value} value={fto.value}>
                    {fto.label}
                  </MenuItem>
                ))}
              </TextField>
            </FieldOptionsContainer>
            {countryValues[value]?.siteId === 1 && (
              <ImportCost studyId = {id} scheduleId={scheduleId} siteGroupId={countryValues[value]?.siteGroupId} />
            )}
            <Button
              disabled={working}
              style={{ width: "13rem" }}
              variant="outlined"
              onClick={refreshSiteList}
            >
              {!working ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>REFRESH SITE LIST</span>{" "}
                  <Tooltip info="The list of sites will be refreshed for all countries.  Any site that has a Fully Executed contract listed in the Remarque Activity module will become available to select so data entry for that Site can begin." />
                </div>
              ) : (
                <CircularProgress size={"1.5rem"} />
              )}
            </Button>
            <ExcelExports />
          </div>

          {loadingCountrySOE && <LinearProgress />}
          <Content
            siteId={siteId}
            setSiteId={setSiteId}
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            key={renderKey}
            tabs={countryValues}
            value={value}
            setValue={setValue}
            fieldType={fieldType}
            setFieldType={setFieldType}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;

const FieldOptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
