import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { ASSESSMENT_REQUIRED_FIELDS, FIELD_TYPES } from "../constants";
import { Tooltip } from "../../../../app/components";

/*
  This component is responsible for rendering the table header of the assessments table.
  It uses the material ui table cell component.
  It uses the material ui table row component.
  The parent component must pass the remove column width of the table.
  The parent component must pass the type column width of the table.
  The parent component must pass the value of the table.
  The parent component must pass the column count of the table.
  The parent component must pass the field type of the table.
*/
export default function TableHeader({
  removeColWidth,
  typeColumnWidth = 20,
  value,
  columnCount,
  fieldType,
}) {
  const commentType = useMemo(() => {
    if (fieldType === FIELD_TYPES.VISIT) return "Assessment Comments";
    if (fieldType === FIELD_TYPES.COST) return "Cost Comments";
    if (fieldType === FIELD_TYPES.MEDICAL) return "Clinical Strategy Comments";
    if (fieldType === FIELD_TYPES.DATA_MANAGEMENT)
      return "Data Management Comments";
    return "";
  }, [fieldType]);
  return (
    <Row>
      <Cell width={`${typeColumnWidth}%`} variant="header">
        <Typography variant="body2">
          <strong>
            Assessment Type{" "}
            {ASSESSMENT_REQUIRED_FIELDS["assessmentType"] && (
              <RequiredContainer>*</RequiredContainer>
            )}
          </strong>
        </Typography>
      </Cell>
      <Cell
        width={`${
          (3.5 / columnCount) * (100 - removeColWidth - typeColumnWidth)
        }%`}
        variant="header"
      >
        <Typography variant="body2">
          <strong>
            Assessment Name{" "}
            {ASSESSMENT_REQUIRED_FIELDS["assessmentName"] && (
              <RequiredContainer>*</RequiredContainer>
            )}
          </strong>
        </Typography>
      </Cell>
      {value === 0 && (
        <Cell
          width={`${
            (2.5 / columnCount) * (100 - removeColWidth - typeColumnWidth)
          }%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>
              Assessment Name Mapping{" "}
              {ASSESSMENT_REQUIRED_FIELDS["assessmentNameMapping"] && (
                <RequiredContainer>*</RequiredContainer>
              )}
            </strong>
          </Typography>
        </Cell>
      )}
      <Cell
        width={`${
          (1.5 / columnCount) * (100 - removeColWidth - typeColumnWidth)
        }%`}
        variant="header"
      >
        <Typography variant="body2">
          <strong>
            Assessment Cost{" "}
            {ASSESSMENT_REQUIRED_FIELDS["assessmentCost"] && (
              <RequiredContainer>*</RequiredContainer>
            )}
          </strong>
        </Typography>
      </Cell>
      {value === 0 && (
        <>
          <Cell
            width={`${
              (1.5 / columnCount) * (100 - removeColWidth - typeColumnWidth)
            }%`}
            variant="header"
          >
            <Typography variant="body2">
              <strong>
                Overhead{" "}
                {ASSESSMENT_REQUIRED_FIELDS["overhead"] && (
                  <RequiredContainer>*</RequiredContainer>
                )}
              </strong>
            </Typography>
          </Cell>
          <Cell
            width={`${
              (1.5 / columnCount) * (100 - removeColWidth - typeColumnWidth)
            }%`}
            variant="header"
          >
            <Typography variant="body2">
              <strong>
                Exclude
                <Tooltip info="Mark activities and other direct costs as Excluded if you want to budget costs specifically. These costs will be excluded from the assessment cost Total Column" />{" "}
                {ASSESSMENT_REQUIRED_FIELDS["exclude"] && (
                  <RequiredContainer>*</RequiredContainer>
                )}
              </strong>
            </Typography>
          </Cell>
          <Cell
            width={`${
              (1.5 / columnCount) * (100 - removeColWidth - typeColumnWidth)
            }%`}
            variant="header"
          >
            <Typography variant="body2">
              <strong>
                IP Administered{" "}
                {ASSESSMENT_REQUIRED_FIELDS["ipAdministered"] && (
                  <RequiredContainer>*</RequiredContainer>
                )}
              </strong>
            </Typography>
          </Cell>
          <Cell
            width={`${
              (2.5 / columnCount) * (100 - removeColWidth - typeColumnWidth)
            }%`}
            variant="header"
          >
            <Typography variant="body2">
              <strong>{commentType}</strong>
            </Typography>
          </Cell>
          <Cell width={`${removeColWidth}%`} variant="header"></Cell>
        </>
      )}
    </Row>
  );
}

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid #ccc" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid #ccc" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid #ccc" : "")};
`;

const RequiredContainer = styled.span`
  color: red;
`;
