import {
  BodyStyle,
  DEFAULT_DATE_CONFIG,
  HeaderStyle1,
  getCellType,
  getDateObject,
} from "../../app/contexts/helpers";
import { LinearProgress, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";

import API from "../../app/utils/api";
import Button from "@material-ui/core/Button";
import { Modal } from "../../app/components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import XLSX from "xlsx-js-style";
import styled from "styled-components";
import { useParams } from "react-router-dom";

const dataColumns = {
  Country: "Country",
  "Central / Local": "Central / Local",
  "Site Number - PI": "Site Number - PI",
  "Site Selected Date": "Site Selected Date",
  "Site Activated - Actual": "Site Activated - Actual",
  "Site Activated - Forecast": "Site Activated - Forecast",
  "Site Status": "Site Status",
};

const SITES_INCLUDED_HEADERS = [
  "Project ID",
  "Country",
  "Central / Local",
  "Site Number - PI",
  "Site Selected Date",
  "Site Activated - Actual",
  "Site Activated - Forecast",
  "Site Status",
];

/*
  This component is a modal that displays the sites included and not included in a remodel.
  It uses the API to fetch the sites included and not included in a remodel.
  The parent component must pass a boolean value to control the modal.
  The parent component must pass a callback function to control the modal.
*/
export default function ViewSiteListModal({
  remodelViewOpen,
  setRemodelViewOpen,
}) {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [remodelSitesIncluded, setRemodelSitesIncluded] = useState([]);
  const [remodelSitesNotIncluded, setRemodelSitesNotIncluded] = useState([]);
  const [working, setWorking] = useState(false);

  const { id } = useParams();

  const filename_base = useMemo(
    () =>
      `${"sites_included_and_not_included_in_remodel"}_${new Date().toString()}_${id}`,
    [id]
  );

  useEffect(() => {
    if (
      remodelViewOpen &&
      (remodelSitesIncluded.length === 0 ||
        remodelSitesNotIncluded.length === 0)
    ) {
      (async () => {
        setLoading(true);
        try {
          const { data: sitesIncluded } = await API.sitesIncludedInRemodel(id);
          const { data: sitesNotIncluded } =
            await API.sitesNotIncludedInRemodel(id);
          setRemodelSitesIncluded(sitesIncluded);
          setRemodelSitesNotIncluded(sitesNotIncluded);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, remodelViewOpen]);

  const download_sites_included = () => {
    return styleTableContent([
      SITES_INCLUDED_HEADERS,
      ...remodelSitesIncluded.slice(1).map((si, idx) => {
        return [
          si["Project ID"] ?? "",
          si.Country ?? "",
          si["Central / Local"] ?? "",
          si["Site Number - PI"] ?? "",
          getDateObject(si["Site Selected Date"]) ?? "",
          getDateObject(si["Site Activated - Actual"]) ?? "",
          getDateObject(si["Site Activated - Forecast"]) ?? "",
          si["Site Status"] ?? "",
          // idx === 0 ? si.Total : "",
        ];
      }),
    ]);
  };

  const styleTableContent = (tableContent) => {
    const styledTableContent = [];
    for (let i = 0; i < tableContent.length; i++) {
      if (!styledTableContent[i]) styledTableContent.push([]);
      for (let j = 0; j < tableContent[i].length; j++) {
        const value = tableContent[i][j];
        const type = getCellType(value);
        styledTableContent[i].push({
          v: value ?? "",
          ...(type === "d" ? { z: "dd mmm yyyy" } : {}),
          t: type,
          s: i === 0 ? HeaderStyle1 : BodyStyle,
        });
      }
    }
    return styledTableContent;
  };

  const download_sites_not_included = () => {
    return styleTableContent([
      SITES_INCLUDED_HEADERS,
      ...remodelSitesNotIncluded.slice(1).map((si, idx) => {
        return [
          si["Project ID"] ?? "",
          si.Country ?? "",
          si["Central / Local"] ?? "",
          si["Site Number - PI"] ?? "",
          getDateObject(si["Site Selected Date"]) ?? "",
          getDateObject(si["Site Activated - Actual"]) ?? "",
          getDateObject(si["Site Activated - Forecast"]) ?? "",
          si["Site Status"] ?? "",
          // idx === 0 ? si.Total : "",
        ];
      }),
    ]);
  };

  const exportTables = () => {
    setWorking(true);
    try {
      const wb = XLSX.utils.book_new();

      const sitesIncludedContent = download_sites_included();
      const sites_included_sheet = XLSX.utils.aoa_to_sheet(
        sitesIncludedContent,
        DEFAULT_DATE_CONFIG
      );
      if (sitesIncludedContent.length > 0) {
        const sheetCols = sitesIncludedContent[0].map((_, idx) =>
          idx === 3 ? { width: 40 } : idx === 7 ? { width: 30 } : { width: 20 }
        );
        sites_included_sheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        sites_included_sheet,
        "Sites Included in Remodel"
      );

      const sitesNotIncludedContent = download_sites_not_included();
      const sites_not_included_sheet = XLSX.utils.aoa_to_sheet(
        sitesNotIncludedContent,
        DEFAULT_DATE_CONFIG
      );
      if (sitesNotIncludedContent.length > 0) {
        const sheetCols = sitesNotIncludedContent[0].map((_, idx) =>
          idx === 3 ? { width: 40 } : idx === 7 ? { width: 30 } : { width: 20 }
        );
        sites_not_included_sheet["!cols"] = sheetCols;
      }
      XLSX.utils.book_append_sheet(
        wb,
        sites_not_included_sheet,
        "Sites Not Included in Remodel"
      );

      XLSX.writeFile(wb, `${filename_base}.xlsx`, XLSX.cellDates);
    } catch (err) {
      console.log(err);
    }
    setWorking(false);
  };

  const renderRow = (row) => {
    // make table row height smaller
    return (
      <TableRow
        style={
          row.dataSet === "Total"
            ? { backgroundColor: "#002E6E", height: "auto" }
            : { height: "auto" }
        }
      >
        {/* <TableCell
          style={{
            color: row.dataSet === "Total" ? "white" : "",
            padding: "4px 8px",
          }}
          align="center"
        >
          <p>{row["Project ID"]}</p>
        </TableCell> */}
        <TableCell
          style={{
            color: row.dataSet === "Total" ? "white" : "",
            padding: "4px 8px",
          }}
          align="center"
        >
          <p>{row.dataSet === "Total" ? `Total: ${row.Total}` : row.Country}</p>
        </TableCell>
        <TableCell
          style={{
            color: row.dataSet === "Total" ? "white" : "",
            padding: "4px 8px",
          }}
          align="center"
        >
          <p>{row["Central / Local"]}</p>
        </TableCell>
        <TableCell
          style={{
            color: row.dataSet === "Total" ? "white" : "",
            padding: "4px 8px",
          }}
          align="left"
        >
          <p>{row["Site Number - PI"]}</p>
        </TableCell>
        <TableCell
          style={{
            color: row.dataSet === "Total" ? "white" : "",
            padding: "4px 8px",
          }}
          align="center"
        >
          <p>{row["Site Selected Date"]}</p>
        </TableCell>
        <TableCell
          style={{
            color: row.dataSet === "Total" ? "white" : "",
            padding: "4px 8px",
          }}
          align="center"
        >
          <p>{row["Site Activated - Actual"]}</p>
        </TableCell>
        <TableCell
          style={{
            color: row.dataSet === "Total" ? "white" : "",
            padding: "4px 8px",
          }}
          align="center"
        >
          <p>{row["Site Activated - Forecast"]}</p>
        </TableCell>
        <TableCell
          style={{
            color: row.dataSet === "Total" ? "white" : "",
            padding: "4px 8px",
          }}
          align="center"
        >
          <p>{row["Site Status"]}</p>
        </TableCell>
        {/* <TableCell
          style={{
            color: row.dataSet === "Total" ? "white" : "",
            padding: "4px 8px",
          }}
          align="center"
        >
          <p>{row.dataSet === "Total" && row.Total}</p>
        </TableCell> */}
      </TableRow>
    );
  };
  return (
    <Modal
      open={remodelViewOpen}
      onClose={() => setRemodelViewOpen(false)}
      aria-labelledby="Remodel View Site List"
      aria-describedby="Remodel View Site List"
      title="Remodel View Site List"
      root={{ padding: "0.5em 1em" }}
      sx={{
        width: loading ? "fit-content" : "90vw",
        height: "fit-content",
        top: loading ? "45%" : "50%",
      }}
    >
      <div style={{ width: "100%" }}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LinearProgress style={{ width: "350px" }} />
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tabs
                value={tabValue}
                onChange={(_, value) => setTabValue(value)}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                indicatorColor="primary"
              >
                <Tab label="Sites Included" id="sites_included_in_remodel" />
                <Tab
                  label="Sites Not Included"
                  id="sites_not_included_in_remodel"
                />
              </Tabs>
            </div>
            <TablesContainer>
              {tabValue === 0 && (
                <TableContainer
                  style={{
                    maxHeight: "68vh",
                    overflow: "auto",
                    marginBottom: "2rem",
                  }}
                >
                  <Table
                    aria-label="customized table"
                    className="table-container"
                  >
                    <TableHead
                      style={{
                        position: "sticky",
                        top: "-1rem",
                        background: "white",
                      }}
                    >
                      <TableRow variant="head">
                        {Object.keys(dataColumns).map((col, index) => (
                          <TableCell key={col} align="center">
                            <p>{col}</p>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {remodelSitesIncluded.map((row, index) => renderRow(row))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {tabValue === 1 && (
                <TableContainer
                  style={{
                    maxHeight: "68vh",
                    overflow: "auto",
                    marginBottom: "2rem",
                  }}
                >
                  <Table
                    aria-label="customized table"
                    className="table-container"
                  >
                    <TableHead
                      style={{
                        position: "sticky",
                        top: "-1rem",
                        background: "white",
                      }}
                    >
                      <TableRow variant="head">
                        {Object.keys(dataColumns).map((col, index) => (
                          <TableCell key={col} align="center">
                            <p>{col}</p>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {remodelSitesNotIncluded.map((row, index) =>
                        renderRow(row)
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <Button
                  onClick={() => setRemodelViewOpen(false)}
                  variant="contained"
                  color="primary"
                  style={{ width: "fit-content", padding: "5px" }}
                >
                  Close
                </Button>
                <Button
                  disabled={working}
                  onClick={() => exportTables()}
                  variant="contained"
                  color="primary"
                  style={{ width: "fit-content" }}
                >
                  Export Tables
                </Button>
              </div>
            </TablesContainer>
          </div>
        )}
      </div>
    </Modal>
  );
}

const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
