import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "../../../../app/components";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

const VISIT_INTERVAL_TYPE_OPTIONS = ["Hour", "Day", "Week", "Month", "Year"];

/*
  This component is a modal that allows the user to create a new schedule of events.
  It uses the schedule events context to handle the creation of the schedule of events.
  The parent component must pass a callback function to handle the schedule of events parameters.
*/
const NewScheduleEventModal = (props) => {
  const classes = useStyles();
  const { scheduleOfEvents, saveLoading } = useScheduleEvents();
  const [formValid, setFormValid] = useState(false);
  const [SOEEventParameters, setSOEEventParameters] = useState({
    scheduleOfEventsName: "",
    scheduleOfEventsDescr: "",
  });
  const [errors, setErrors] = useState({
    scheduleOfEventsName: "",
    scheduleOfEventsDescr: "",
    visitIntervalType: "",
    visitWindowIntervalType: "",
  });

  useEffect(() => {
    if (
      SOEEventParameters["scheduleOfEventsDescr"] &&
      SOEEventParameters["scheduleOfEventsName"] &&
      SOEEventParameters["visitIntervalType"] &&
      SOEEventParameters["visitWindowIntervalType"] &&
      !errors["scheduleOfEventsDescr"] &&
      !errors["scheduleOfEventsName"] &&
      !errors["visitIntervalType"] &&
      !errors["visitWindowIntervalType"]
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [SOEEventParameters, errors]);

  const handleChange = (key, value) => {
    setSOEEventParameters({ ...SOEEventParameters, [key]: value });
    if (!value) {
      setErrors({ ...errors, [key]: "This field is required" });
    } else {
      setErrors({ ...errors, [key]: "" });
    }
    if (key === "scheduleOfEventsName") {
      scheduleOfEvents.forEach((sp) => {
        if (
          sp?.scheduleOfEventsName.toLowerCase().trim() ===
          value.toLowerCase().trim()
        ) {
          setErrors({
            ...errors,
            [key]: "Schedule of Events name must be unique",
          });
        }
        if (!value.replace(/\s/g, "").length) {
          setErrors({
            ...errors,
            [key]: "SOE name must contain at least 1 character",
          });
        }
      });
    }
    if (key === "scheduleOfEventsDescr") {
      if (!value.replace(/\s/g, "").length) {
        setErrors({
          ...errors,
          [key]: "SOE description must contain at least 1 character",
        });
      }
    }
  };

  return (
    <Modal
      size={700}
      {...props}
      aria-labelledby="New Schedule of Events"
      aria-describedby="Create a new schedule of events"
      title="New Schedule of Events"
    >
      <Container>
        <SubContainer>
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.scheduleOfEventsName}
            onChange={(e) =>
              handleChange("scheduleOfEventsName", e.target.value)
            }
            variant="outlined"
            label="SOE Name"
            InputLabelProps={{ classes }}
            required
            error={!!errors.scheduleOfEventsName}
            inputProps={{
              maxLength: 50,
            }}
            helperText={
              <AlignBetween>
                <span>{errors.scheduleOfEventsName}</span>
                <span>{`${
                  (SOEEventParameters.scheduleOfEventsName || "").length
                } / ${50}`}</span>
              </AlignBetween>
            }
          />
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.scheduleOfEventsDescr}
            error={errors.scheduleOfEventsDescr}
            onChange={(e) =>
              handleChange("scheduleOfEventsDescr", e.target.value)
            }
            variant="outlined"
            label="SOE Description"
            InputLabelProps={{ classes }}
            required
            multiline
            inputProps={{
              maxLength: 2000,
            }}
            rows={4}
            helperText={
              <AlignBetween>
                <span>{errors.scheduleOfEventsDescr}</span>
                <span>{`${
                  (SOEEventParameters.scheduleOfEventsDescr || "").length
                } / ${2000}`}</span>
              </AlignBetween>
            }
          />
          <FormControl
            error={errors.visitIntervalType}
            required
            style={{ width: "100%" }}
            variant="outlined"
          >
            <InputLabel id="visit-interval-type-label-id">
              Visit Interval Type
            </InputLabel>
            <Select
              labelId="visit-interval-type-label-id"
              id="visit-interval-type-id"
              value={SOEEventParameters.visitIntervalType}
              onChange={(event) =>
                handleChange("visitIntervalType", event.target.value)
              }
              label="Visit Interval Type"
            >
              {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            error={errors.visitWindowIntervalType}
            required
            style={{ width: "100%" }}
            variant="outlined"
          >
            <InputLabel id="visit-window-interval-type-label-id">
              Visit Window Interval Type
            </InputLabel>
            <Select
              labelId="visit-window-interval-type-label-id"
              id="visit-window-interval-type-id"
              value={SOEEventParameters.visitWindowIntervalType}
              onChange={(event) =>
                handleChange("visitWindowIntervalType", event.target.value)
              }
              label="Visit Window Interval Type"
            >
              {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </SubContainer>
        <ButtonContainer>
          <Button variant="outlined" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            disabled={saveLoading || !formValid}
            color="primary"
            variant="contained"
            onClick={() => props.onContinue(SOEEventParameters)}
          >
            {saveLoading && <CircularProgress size="1em" />}
            {!saveLoading && "Continue"}
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default NewScheduleEventModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  max-height: 75vh;
  overflow-y: auto;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const useStyles = makeStyles({
  asterisk: {
    color: "red",
  },
});

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
