import React, { useMemo } from "react";
import { Chart } from "react-google-charts";
import { timelineColors } from "../colors";
import moment from "moment";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { makeStyles, Typography } from "@material-ui/core";

// const MILESTONE_MAPPINGS = {
//   NUM_SITES_ACTIVATED: "Activated",
//   NUM_SUBJECTS_SCREENED: "Screened",
//   NUM_SUBJECTS_ENROLLED: "Enrolled",
//   NUM_SUBJECTS_TREATED: "Treated",
//   NUM_SUBJECTS_COMPLETED: "Completed",
// };

const CohortTimelineChart = () => {
  const classes = useStyles();
  const { simulationResults } = useStudyPlan();
  const { cohortTimelineChart: data } = simulationResults;
  const colors = timelineColors;

  const formattedChartData = useMemo(() => {
    let columns = [
      { type: "string", id: "Study Plan Name" },
      { type: "string", id: "Milestone" },
      { type: "string", id: "style", role: "style" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ];
    if (!data) return [columns];

    let rows = data.map((obj) => {
      return [
        obj.cohortName,
        obj["milestoneName"],
        new Date(
          moment(obj["Project Start Date"])
            .add("days", obj["studyDayStart"])
            .format("YYYY-MM-DD")
        ),
        new Date(
          moment(obj["Project Start Date"])
            .add("days", obj["studyDayStart"] + obj["studyDayDuration"])
            .format("YYYY-MM-DD")
        ),
      ];
    });
    const firstCohortKey = rows[0][0];
    const cohortColTypes = rows
      .filter((c) => c[0] === firstCohortKey)
      .sort((a, b) => a[2] > b[2])
      .map((c) => c[1]);
    rows = rows.map((r) => {
      let index = cohortColTypes.indexOf(r[1]);

      return [r[0], r[1], colors[index], r[2], r[3]];
    });
    return [columns, ...rows];
  }, [colors, data]);

  if (!data || data.length === 0) return null;

  const options = {
    timeline: { colorByRowLabel: false },
    alternatingRowStyle: false,
    height: 1000,
    colors: colors,
  };

  return (
    <>
      <Typography className={classes.heading}>Timeline Chart</Typography>
      <Chart chartType="Timeline" data={formattedChartData} options={options} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#002e6e",
    fontSize: "1.2rem",
    fontWeight: "600",
    textAlign: "center",
  },
}));

export default CohortTimelineChart;
