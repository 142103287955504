import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import XLSX from "xlsx-js-style";

import { Modal } from "../../../../app/components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import { HEADERS as SUMMARY_HEADERS } from "../summary.table/table.utils";
import {
  BodyStyle,
  DEFAULT_DATE_CONFIG,
  HeaderStyle1,
  HeaderStyle2,
  getCellType,
} from "../../../../app/contexts/helpers";

export default function ExcelExportsModal({ open, handleClose }) {
  const [isChecked, setIsChecked] = useState({});
  const [exportState, setExportState] = useState("idle");
  const {
    formattedForecastSummary,
    formattedForecastDetail,
    formattedForecastRFPDetail,
    formattedForecastRFPSummary,
    currentSOE,
  } = useScheduleEvents();

  const hasSummary = useMemo(() => {
    return (
      formattedForecastSummary?.t?.length > 0 &&
      formattedForecastSummary?.cSummaries
    );
  }, [formattedForecastSummary]);

  const hasDetail = useMemo(() => {
    return (
      formattedForecastDetail?.t?.length > 0 &&
      formattedForecastDetail?.cDetails
    );
  }, [formattedForecastDetail]);

  const hasSummaryRFP = useMemo(() => {
    return (
      formattedForecastRFPSummary?.data && formattedForecastRFPSummary?.columns
    );
  }, [formattedForecastRFPSummary]);

  const hasDetailRFP = useMemo(() => {
    return (
      formattedForecastRFPDetail?.data?.length > 0 &&
      formattedForecastRFPDetail?.columns
    );
  }, [formattedForecastRFPDetail]);

  const filename_base = useMemo(
    () =>
      `${currentSOE.scheduleOfEventsName}_${new Date().toString()}_${
        currentSOE.studyId
      }`,
    [currentSOE]
  );

  const handleChange = (e, value) => {
    const { name } = e.target;
    setIsChecked((prev) => ({ ...prev, [name]: value }));
  };

  const resetModal = () => {
    setIsChecked({});
    handleClose();
  };

  const styleTableContentWithSubHeader = (tableContent, subHeaders = []) => {
    const styledTableContent = [];
    for (let i = 0; i < tableContent.length; i++) {
      if (!styledTableContent[i]) styledTableContent.push([]);
      for (let j = 0; j < tableContent[i].length; j++) {
        const value = tableContent[i][j];
        const type = getCellType(value);
        styledTableContent[i].push({
          v: value,
          ...(type === "d" ? { z: "dd mmm yyyy" } : {}),
          t: type,
          s:
            i === 0
              ? HeaderStyle1
              : subHeaders.includes(tableContent[i][0])
              ? HeaderStyle2
              : BodyStyle,
        });
      }
    }
    return styledTableContent;
  };

  const download_summary = () => {
    const { cSummaries, t: totals } = formattedForecastSummary;
    const summaryContent = [];

    summaryContent.push(["", ...SUMMARY_HEADERS]);

    Object.keys(cSummaries).forEach((country) => {
      summaryContent.push([
        country,
        ...cSummaries[country].subTotal.map((value) =>
          value === null ? "" : value
        ),
      ]);
      cSummaries[country].details.forEach((detail) => {
        summaryContent.push([
          detail.country_name,
          ...detail.data.map((dd) => (dd === null ? "" : dd)),
        ]);
      });
      summaryContent.push(["", ...SUMMARY_HEADERS.map((value) => "")]);
    });

    summaryContent.push(["Total", ...totals.map((t) => (t === null ? "" : t))]);

    return styleTableContentWithSubHeader(summaryContent, [
      ...Object.keys(cSummaries),
      "Total",
    ]);
  };

  const download_details = () => {
    const { cDetails, ch: headers, t: totals } = formattedForecastDetail;
    const detailContent = [];

    detailContent.push(["", ...headers]);

    Object.keys(cDetails).forEach((country) => {
      detailContent.push([
        country,
        ...cDetails[country].subTotal.map((value) =>
          value === null ? "" : value
        ),
      ]);
      cDetails[country].details.forEach((detail) => {
        detailContent.push([
          detail.country_name,
          ...detail.data.map((value) => (value === null ? "" : value)),
        ]);
      });
      detailContent.push(["", ...headers.map((value) => "")]);
    });

    detailContent.push([
      "Total",
      ...totals.map((total) => (total === null ? "" : total)),
    ]);

    return styleTableContentWithSubHeader(detailContent, [
      ...Object.keys(cDetails),
      "Total",
    ]);
  };

  const download_rfp_details = () => {
    const { columns, data } = formattedForecastRFPDetail;
    const detailContent = [];
    const columnValues = Object.values(columns);
    const columnKeys = Object.keys(columns);
    detailContent.push(columnValues);

    data.forEach((row) => {
      detailContent.push(columnKeys.map((column) => row[column]));
    });
    return styleTableContentWithSubHeader(detailContent, ["Total"]);
  };

  const download_rfp_summary = () => {
    const { columns, data } = formattedForecastRFPSummary;
    const summaryContent = [];

    const columnValues = Object.values(columns);
    const columnKeys = Object.keys(columns);
    summaryContent.push(columnKeys);
    const FIELDS = [
      "100% SDR/SDV Time (minutes)",
      "CRA Sampled Visit",
      "Onsite SDR/SDV (minutes)",
      "CDA Review (minutes)",
    ];

    Object.keys(data).forEach((d) => {
      if (!FIELDS.includes(d)) return;
      summaryContent.push(
        columnValues.map((index) =>
          data[d][index] !== "" && !isNaN(data[d][index])
            ? parseFloat(data[d][index])
            : data[d][index]
        )
      );
    });

    return styleTableContentWithSubHeader(summaryContent);
  };

  const download_rfp_summary_data = () => {
    const { columns, data } = formattedForecastRFPSummary;
    const summaryContent = [];
    const FIELDS = [
      "100% SDR/SDV Time (minutes)",
      "CRA Sampled Visit",
      "Onsite SDR/SDV (minutes)",
      "CDA Review (minutes)",
    ];
    const columnValues = Object.values(columns);
    const columnKeys = Object.keys(columns);
    summaryContent.push(columnKeys);

    Object.keys(data).forEach((d) => {
      if (FIELDS.includes(d)) return;
      summaryContent.push(
        columnValues.map((index) =>
          data[d][index] !== "" && !isNaN(data[d][index])
            ? parseFloat(data[d][index])
            : data[d][index]
        )
      );
    });

    return styleTableContentWithSubHeader(summaryContent);
  };

  const exportAsCSV = async () => {
    try {
      setExportState("exporting");
      const wb = XLSX.utils.book_new();

      if (isChecked.summary) {
        const summary_content = await download_summary();
        const summary_sheet = XLSX.utils.aoa_to_sheet(
          summary_content,
          DEFAULT_DATE_CONFIG
        );
        XLSX.utils.book_append_sheet(wb, summary_sheet, "Cost Summary");

        if (summary_content.length > 0) {
          const sheetCols = summary_content[0].map((value) => ({
            wch: 15,
          }));
          summary_sheet["!cols"] = sheetCols;
        }
      }
      if (isChecked.detail) {
        const detail_content = download_details();
        const detail_sheet = XLSX.utils.aoa_to_sheet(detail_content, {});
        XLSX.utils.book_append_sheet(wb, detail_sheet, "Cost Detail");

        if (detail_content.length > 0) {
          const sheetCols = detail_content[0].map((value) => ({
            wch: 15,
          }));
          detail_sheet["!cols"] = sheetCols;
        }
      }
      if (isChecked.rfpDetail) {
        const detail_content = download_rfp_details();
        const detail_sheet = XLSX.utils.aoa_to_sheet(detail_content, {});
        XLSX.utils.book_append_sheet(wb, detail_sheet, "Investigator Grants");

        if (detail_content.length > 0) {
          const sheetCols = detail_content[0].map((value) => ({
            wch: 15,
          }));
          detail_sheet["!cols"] = sheetCols;
        }
      }
      if (isChecked.rfpSummary) {
        const summary_content = download_rfp_summary();
        const summary_sheet = XLSX.utils.aoa_to_sheet(summary_content, {});
        XLSX.utils.book_append_sheet(wb, summary_sheet, "Clinical Strategy");

        if (summary_content.length > 0) {
          const sheetCols = summary_content[0].map((value) => ({
            wch: 15,
          }));
          summary_sheet["!cols"] = sheetCols;
        }
      }

      if (isChecked.rfpSummaryData) {
        const summary_content = download_rfp_summary_data();
        const summary_sheet = XLSX.utils.aoa_to_sheet(summary_content, {});
        XLSX.utils.book_append_sheet(wb, summary_sheet, "Data Management");

        if (summary_content.length > 0) {
          const sheetCols = summary_content[0].map((value) => ({
            wch: 15,
          }));
          summary_sheet["!cols"] = sheetCols;
        }
      }

      XLSX.writeFile(wb, `${filename_base}.xlsx`, XLSX.cellDates);

      setIsChecked({});
      setExportState("exported");
      handleClose();
    } catch (err) {
      console.log(err);
      setExportState("idle");
    }
  };

  const validExportValues = () => {
    const values = Object.values(isChecked);
    if (values.length === 0) return false;
    if (values.find((v) => v === true)) return true;
    return false;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        if (exportState !== "exporting") resetModal();
      }}
      aria-labelledby="Export Tables"
      aria-describedby="Export Tables"
      title="Export Tables"
      sx={{ minWidth: "30rem", maxWidth: "100%", width: "50rem" }}
    >
      <FormGroup>
        <Container>
          <SubContainer>
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasSummary}
                  checked={!!isChecked.summary}
                  onChange={handleChange}
                  name="summary"
                />
              }
              label={`Cost Summary`}
            />
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasDetail}
                  checked={!!isChecked.detail}
                  onChange={handleChange}
                  name="detail"
                />
              }
              label={`Cost Detail`}
            />
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasSummaryRFP}
                  checked={!!isChecked.rfpSummary}
                  onChange={handleChange}
                  name="rfpSummary"
                />
              }
              label={`Clinical Strategy`}
            />
          </SubContainer>
          <SubContainer>
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasSummaryRFP}
                  checked={!!isChecked.rfpSummaryData}
                  onChange={handleChange}
                  name="rfpSummaryData"
                />
              }
              label={`Data Management`}
            />
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasDetailRFP}
                  checked={!!isChecked.rfpDetail}
                  onChange={handleChange}
                  name="rfpDetail"
                />
              }
              label={`Investigator Grants`}
            />
          </SubContainer>
        </Container>
        <ButtonContainer>
          <Button onClick={resetModal} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={!validExportValues() || exportState === "exporting"}
            onClick={exportAsCSV}
            color="primary"
            variant="contained"
          >
            Export
          </Button>
        </ButtonContainer>
      </FormGroup>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SubContainer = styled.div`
  display: flex;
  gap: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
