import { DragDropContext, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";
import {
  hasDuplicates,
  isIncreasing,
  nonNegative,
} from "../../../../../app/utils/array.functions";

import Alert from "@material-ui/lab/Alert";
import Controls from "./controls";
import DataCard from "./data.card";
import { SectionHeader } from "../../../../../app/components";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { uniqueId } from "lodash";
import { useSnackbar } from "../../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const grid = 8;

const SiteGroupActivationProfiles = ({ country, siteGroup }) => {
  const { activationProfiles, countryId, siteGroupId, studyPlanId } = siteGroup;
  const {
    saChangeLog,
    setSAChangeLog,
    saItems,
    setSAItems,
    saDeleteIds,
    setSADeleteIds,
    saLoading,
    setSALoading,
    saErrors,
    setSAErrors,

    saSaveActivationProfiles,
  } = useStudyPlan();
  useEffect(() => {
    setSAItems({ ...saItems, [siteGroupId]: activationProfiles });
    setSAChangeLog({ ...saChangeLog, [siteGroupId]: {} });
    setSADeleteIds({ ...saDeleteIds, [siteGroupId]: [] });
    setSAErrors({ ...saErrors, [siteGroupId]: [] });
    setSALoading({ ...saLoading, [siteGroupId]: false });
    // eslint-disable-next-line
  }, []);
  const { updateCountries } = useStudyPlan();
  const [isEdit, setIsEdit] = useState(false);
  // const [changeLog, setChangeLog] = useState({});
  // const [loading, setLoading] = useState(false);
  // const [errors, setErrors] = useState([]);

  // const [items, setItems] = useState(activationProfiles);
  // const [deleteIds, setDeleteIds] = useState([]);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (saErrors[siteGroupId]?.length > 0) {
      showSnackbar("Save was not successful.", "error");
    }
    // eslint-disable-next-line
  }, [saErrors[siteGroupId]]);

  const handleAddNew = () => {
    const newItem = {
      id: uniqueId(),
      siteActivationProfilePct: 0.01,
      siteActivationProfileDays: 1,
      countryId: countryId,
      studyPlanId: studyPlanId,
      siteGroupId: siteGroupId,
    };
    // setItems((prev) => [...prev, newItem]);
    setSAItems({
      ...saItems,
      [siteGroupId]: [...saItems[siteGroupId], newItem],
    });
  };

  const handleDelete = (siteActivationProfileId, id) => {
    let index = -1;
    let newItems = [...saItems[siteGroupId]];

    if (siteActivationProfileId) {
      const newDeleteIds = [...saDeleteIds[siteGroupId]];

      newDeleteIds.push(siteActivationProfileId);
      index = newItems
        ?.map((item) => item.siteActivationProfileId)
        .indexOf(siteActivationProfileId);

      setSADeleteIds({ ...saDeleteIds, [siteGroupId]: newDeleteIds });
    } else {
      index = newItems?.map((item) => item.id).indexOf(id);
    }

    newItems.splice(index, 1);
    setSAItems({ ...saItems, [siteGroupId]: newItems });
  };

  const handleChange = (change, siteActivationProfileId, id) => {
    let index = -1;
    if (siteActivationProfileId) {
      index = saItems[siteGroupId]
        ?.map((item) => item.siteActivationProfileId)
        .indexOf(siteActivationProfileId);

      setSAChangeLog((prev) => ({
        ...prev,
        [siteGroupId]: {
          ...prev[siteGroupId],
          [siteActivationProfileId]: siteActivationProfileId,
        },
      }));
    } else {
      index = saItems[siteGroupId]?.map((item) => item.id).indexOf(id);
    }

    const newItems = [...saItems[siteGroupId]];

    newItems[index] = { ...newItems[index], ...change };
    setSAItems({ ...saItems, [siteGroupId]: newItems });
  };

  const handleCancel = () => {
    setSAItems({ ...saItems, [siteGroupId]: activationProfiles });
    setSAChangeLog({ ...saChangeLog, [siteGroupId]: {} });
    setSADeleteIds({ ...saDeleteIds, [siteGroupId]: [] });
    setSAErrors({ ...saErrors, [siteGroupId]: [] });
  };

  const handleSave = () => {
    setSAErrors({ ...saErrors, [siteGroupId]: [] });
    setSALoading({ ...saLoading, [siteGroupId]: true });

    saSaveActivationProfiles(true)
      .then(() => {
        setSALoading({ ...saLoading, [siteGroupId]: false });
        setIsEdit(false);
        setSAChangeLog({ ...saChangeLog, [siteGroupId]: {} });
        setSADeleteIds({ ...saDeleteIds, [siteGroupId]: [] });
        updateCountries(countryId, siteGroupId, {
          activationProfiles: saItems[siteGroupId],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const new_items = reorder(
      saItems[siteGroupId],
      result.source.index,
      result.destination.index
    );

    // setItems(new_items);
    setSAItems({ ...saItems, [siteGroupId]: new_items });
  };

  const percentagesArr =
    saItems[siteGroupId]?.map((item) => item.siteActivationProfilePct) || [];
  const daysArr =
    saItems[siteGroupId]?.map((item) => item.siteActivationProfileDays) || [];
  const isPercentageIncreasing = isIncreasing(percentagesArr);
  const percentagesHasDuplicates = hasDuplicates(percentagesArr);
  const isPercentageNonNegative = nonNegative(percentagesArr);
  const isPercentageIncreasingToOneHundred =
    percentagesArr[percentagesArr?.length - 1] === 1;
  const isDaysIncreasing = isIncreasing(daysArr);
  const isDaysNonNegative = nonNegative(daysArr);
  const daysHasDuplicates = hasDuplicates(daysArr);

  const isSaveDisabled =
    !isPercentageIncreasing ||
    percentagesHasDuplicates ||
    !isPercentageNonNegative ||
    !isPercentageIncreasingToOneHundred ||
    !isDaysIncreasing ||
    !isDaysNonNegative ||
    daysHasDuplicates;

  return (
    <Container id="activation-profiles">
      <SectionHeader>Activation Profiles</SectionHeader>
      <Typography>
        Site ramp up is modeled using the below data cards. Both the percentage
        and days can be updated to match the site ramp up that is expected in
        this country for this specific study. The percentage is the cumulative
        percentage of sites activated. The days is the days since the First Site
        Activation. Ensure that the numbers (both percentage and days) increase
        from left to right with the rightmost values indicating when all sites
        are intitiated (percentage equal to 1). The data cards are draggable
        when editing the values and can be reordered as needed.
      </Typography>
      <div style={{ display: "grid", gridGap: "1em" }}>
        {!isPercentageIncreasing && (
          <Alert severity="error">Percentages should be increasing.</Alert>
        )}
        {!isPercentageNonNegative && (
          <Alert severity="error">Percentages should be non-negative.</Alert>
        )}
        {percentagesHasDuplicates && (
          <Alert severity="error">
            Percentages should not have duplicates.
          </Alert>
        )}
        {!isPercentageIncreasingToOneHundred && (
          <Alert severity="error">Percentages should be approaching 1.</Alert>
        )}
        {!isDaysIncreasing && (
          <Alert severity="error">Days should be increasing.</Alert>
        )}
        {!isDaysNonNegative && (
          <Alert severity="error">Days should be non-negative.</Alert>
        )}
        {daysHasDuplicates && (
          <Alert severity="error">Days should not have duplicates.</Alert>
        )}
      </div>
      <Controls
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        onAddNew={handleAddNew}
        onSave={handleSave}
        onCancel={handleCancel}
        isLoading={saLoading[siteGroupId]}
        isSaveDisabled={isSaveDisabled}
      />
      <Content>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {saItems[siteGroupId]?.map((item, index) => (
                  <DataCard
                    key={item.siteActivationProfileId || item.id}
                    {...item}
                    index={index}
                    isEdit={isEdit}
                    onChange={handleChange}
                    onDelete={handleDelete}
                  />
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Content>
    </Container>
  );
};

export default SiteGroupActivationProfiles;

const Container = styled.div``;

const Content = styled.div`
  overflow-y: auto;
  position: relative;
  width: 1218px;
  height: auto;
`;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#f1f1f1" : "white",
  display: "flex",
  padding: grid,
  overflow: "auto",
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
