import React, { useEffect } from "react";
import Paper from "../study.plans/common/paper";
import Header from "../study.plans/common/header";
import StudyPlanSearch from "../study.plan.search";
import { useStudyPlan } from "../app/contexts/study.context";
import { useParams, useLocation, Route } from "react-router-dom";
import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";
import StudyActualsTable from "./study.actuals.table";
import StudyActualsRemodel from "./study.actuals.remodel";
import { useUser } from "../app/contexts/user.context";

const StudyPlanActuals = () => {
  const { id, planId } = useParams();
  const location = useLocation();
  const { fetchStudy, loadingStudy } = useStudyPlan();
  const { setURLStudyId } = useUser();

  useEffect(() => {
    setURLStudyId(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    /**
     * This "shouldFetch" is the only way we can detect that the user landed
     * on the table without fetching on every page navigation nested under
     * /ctpo/study-plans/:id/:planId
     *
     * This allows the page to refetch in the list, but also allows for a
     * smooth experience when navigating once we have a planId.
     *
     * This would have been done by checking when planId is null, but useParam
     * sets planId to "undefined" on every history.push.
     */
    const shouldRefetch = (location.pathname.match(/\//g) || []).length === 3;
    if (shouldRefetch) {
      fetchStudy(id);
    }
    // eslint-disable-next-line
  }, [id, location, planId]);

  //Fetch on mount
  useEffect(() => {
    fetchStudy(id);
    // eslint-disable-next-line
  }, []);

  if (loadingStudy) {
    return <LinearProgress />;
  }
  return (
    <Container>
      <StudyPlanSearch hasMargin={false} />
      <Paper>
        <Header />
        <Route
          exact
          path="/ctpo/study-actuals/:id"
          component={StudyActualsTable}
        />
        <Route
          exact
          path="/ctpo/study-actuals/:id/remodel"
          component={StudyActualsRemodel}
        />
      </Paper>
    </Container>
  );
};

export default StudyPlanActuals;

const Container = styled.div`
  padding: 1em;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 350px 1fr;
  width: 100%;
  max-width: 1600px;
`;
