import React from "react";
import styled from "styled-components";
import { Modal } from "../../../../../../app/components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";
const SaveAsModal = ({ open = false, onClose = (noop) => noop, plan }) => {
  const { quickSave, studyPlan } = useStudyPlan();

  const handleSave = () => {
    quickSave({
      studyPlanVersion: studyPlan.studyPlanVersion + 1,
      scenarioStatus: "Draft",
    });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={`Save as a Draft`}
      aria-describedby={`Save as a Draft`}
      title={`Save As Draft`}
    >
      <Container>
        <Typography>This study plan draft will be saved as: </Typography>
        <Typography>
          <strong>{`${plan.studyPlanName} - V${
            plan.studyPlanVersion + 1
          }`}</strong>
        </Typography>
        <SideBySide>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save Draft
          </Button>
        </SideBySide>
      </Container>
    </Modal>
  );
};

export default SaveAsModal;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
const SideBySide = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
