import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../../app/components";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/styles";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const CountryEnrollmentSeasonalVariations = ({ country, siteGroup, tab }) => {
  const { canEditPlan } = useStudyPlan();
  const inputClasses = useInputStyles();
  const history = useHistory();
  const { id, planId } = useParams();
  const { enrollmentVariations } = siteGroup;

  return (
    <Container>
      <SideBySide>
        <SectionHeader>Country Enrollment Seasonal Variations</SectionHeader>
        {canEditPlan && (
          <Button
            color="primary"
            onClick={() =>
              history.push(
                `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups#seasonal-variation`,
                { tabIndex: tab }
              )
            }
          >
            <EditIcon style={{ marginRight: ".3em" }} /> Edit
          </Button>
        )}
      </SideBySide>
      <Table>
        <Row>
          <Cell variant="header">
            <Typography>
              <strong>Jan</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Feb</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Mar</strong>
            </Typography>{" "}
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Apr</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>May</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Jun</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Jul</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Aug</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Sep</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Oct</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Nov</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Dec</strong>
            </Typography>
          </Cell>
        </Row>
        <Row>
          {enrollmentVariations.map((item, index) => (
            <Cell key={index}>
              <InputBase
                type="number"
                classes={inputClasses}
                value={item.enrollmentSeasonalVariation}
                disabled
              />
            </Cell>
          ))}
        </Row>
      </Table>
    </Container>
  );
};

export default CountryEnrollmentSeasonalVariations;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 50px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 1px solid #ccc;
  &:last-child {
    border: none;
  }
`;

const Table = styled.div`
  display: grid;
  gridtemplaterows: auto auto;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const useInputStyles = makeStyles(() => ({
  input: {
    textAlign: "center",
  },
}));

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
`;
