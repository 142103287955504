import React, { useState } from "react";
import MonthlyChart from "./monthly.chart";
import WeeklyChart from "./weekly.chart";
import CohortChartMonthly from "./cohort.chart.monthly";
import CohortTimelineChart from "./cohort.timeline.chart";
import ViewSelector from "./view.selector";
import ChartMetrics from "./chart.metrics";
const Charts = () => {
  const [view, setView] = useState("region");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5em",
        minHeight: "20rem",
      }}
    >
      <ViewSelector value={view} onChange={(newValue) => setView(newValue)} />
      <ChartMetrics />
      <MonthlyChart view={view} />
      <ChartMetrics />
      <WeeklyChart view={view} />
      <CohortChartMonthly />
      <CohortTimelineChart />
    </div>
  );
};

export default Charts;
