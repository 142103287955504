import React, { useMemo } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import "./chart.css";
import { siteGrpColors } from "../colors";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { nanoid } from "nanoid";
import { makeStyles, Typography } from "@material-ui/core";

const CohortChartMonthly = () => {
  const classes = useStyles();
  const { simulationResults } = useStudyPlan();
  const { cohortChartMonthly: data } = simulationResults;
  const colors = siteGrpColors;

  const [groups, barKeys, rerenderKey] = useMemo(() => {
    let groups = [...(data?.Group || [])];
    let bars = [...(data?.Bars || [])];

    groups = groups.map((group) => {
      let g = { ...group };
      bars.forEach((barKey) => {
        let newBarKey = barKey.replaceAll("_", " ");
        if (newBarKey !== barKey) {
          g[newBarKey] = g[barKey];
          delete g[barKey];
        }
      });

      return g;
    });

    bars = bars.map((barKey) => barKey.replaceAll("_", " "));
    return [groups, bars, nanoid()];
  }, [data]);

  if (!data) return null;

  return (
    <>
      <Typography className={classes.heading}>Patients By Group</Typography>
      <ComposedChart
        key={rerenderKey}
        width={1000}
        height={500}
        margin={{ top: 5, right: 15, bottom: 55, left: 15 }}
        data={groups}
        style={{ alignSelf: "center" }}
      >
        <Tooltip wrapperStyle={{ fontFamily: "sans-serif" }} />
        <Legend layout="horizontal" verticalAlign="top" align="center" />
        <XAxis
          dataKey="date"
          fontFamily="sans-serif"
          scale="band"
          angle={-90}
          dy={35}
          dx={-5}
          interval={0}
        />
        <YAxis
          interval={0}
          label={{
            fontFamily: "sans-serif",
            value: "Patients",
            angle: -90,
            position: "insideLeft",
            fill: "#666666",
          }}
          domain={["dataMin", "dataMax + 5"]}
          allowDataOverflow
          fontFamily="Arial"
          yAxisId="1"
          type="number"
        />
        {barKeys.map((key, index) => (
          <Bar
            yAxisId="1"
            stackId="a"
            dataKey={key}
            key={key}
            barSize={20}
            fill={colors[index]}
          />
        ))}
        <Line
          yAxisId="1"
          type="monotone"
          dataKey="Enrolled"
          stroke="#CBDB2A"
          strokeWidth={3}
        />
      </ComposedChart>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#002e6e",
    fontSize: "1.2rem",
    fontWeight: "600",
    textAlign: "center",
  },
}));

export default CohortChartMonthly;
