import { IconButton, MenuItem, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import ClearIcon from "@material-ui/icons/Clear";
import CustomDatePicker from "../../app/components/custom.date.picker";
import MuiTableHead from "@material-ui/core/TableHead";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { nanoid } from "nanoid";

const opportunityStageOptions = [
  "Closed Won",
  "Closed Cancelled",
  "Written Confirmation of Award",
  "Proposal Sent",
  "Rebid Sent",
  "Closed Not Bidding",
  "On Hold by Client",
  "RFP In House",
  "RFI Sent",
  "WCA Rebid Sent",
  "Closed Lost",
  "RFI Requested",
  "Rebid In House",
  "WCA Cancelled",
  "Verbal Award",
  "WCA Rebid in House",
  "Verbal Award Rebid Sent",
  "Drafting Proposal",
  "Developing Project Scope",
];

// create a styled table cell with no horizontal padding
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);

const TableHead = ({ filters, setFilters, orderBy, order }) => {
  const classes = useStyles();
  const headCells = [
    {
      id: "projectId",
      type: "text",
      disablePadding: false,
      label: "Project ID",
    },
    {
      id: "studyName",
      type: "text",
      disablePadding: false,
      label: "Study Name",
    },
    {
      id: "sponsorName",
      type: "text",
      disablePadding: false,
      label: "Sponsor",
    },
    {
      id: "opportunityStage",
      type: "select",
      disablePadding: false,
      label: "Opportunity Stage",
      options: opportunityStageOptions,
    },
    {
      id: "enrollmentPlanExists",
      type: "y_n",
      disablePadding: false,
      label: "Enrollment Plan Exists",
    },
    {
      id: "soeExists",
      type: "y_n",
      disablePadding: false,
      label: "SOE Exists",
    },
    {
      id: "soeCosts",
      type: "y_n",
      disablePadding: false,
      label: "SOE Costs",
    },
    {
      id: "soeClinicalInformatics",
      type: "y_n",
      disablePadding: false,
      label: "SOE Clinical Informatics",
    },
    {
      id: "soeDataManagement",
      type: "y_n",
      disablePadding: false,
      label: "SOE Data Management",
    },
    {
      id: "enrollmentPlanLastUpdate",
      type: "date",
      disablePadding: false,
      label: "Enrollment Plan Last Update",
    },
    {
      id: "soeLastUpdate",
      type: "date",
      disablePadding: false,
      label: "SOE Last Update",
    },
  ];

  const updateFilters = (id, value, type, startEnd) => {
    // type == yes/no && value == "all" => remove filter
    if ([type === "y_n" || type === "select"] && value === "all") {
      const filtersCopy = { ...filters };
      delete filtersCopy[id];
      return setFilters(filtersCopy);
    }

    if (type === "date") {
      let prevValue = filters[id] || {
        id: id,
        type: "date",
      };
      if (startEnd === "start") {
        prevValue = {
          ...prevValue,
          start: value,
        };
      } else if (startEnd === "end") {
        prevValue = {
          ...prevValue,
          end: value,
        };
      }
      return setFilters({
        ...filters,
        [id]: prevValue,
      });
    }
    setFilters({
      ...filters,
      [id]: {
        ...filters[id],
        value: value,
        type: type,
      },
    });
  };

  const renderInputField = (id, type) => {
    if (["projectId", "studyName", "sponsorName"].includes(id)) return null;
    if (type === "text")
      return (
        <TextField
          value={filters[id]?.value || ""}
          onChange={(e) => updateFilters(id, e.target.value, type)}
          size="small"
          variant="outlined"
          InputLabelProps={{ classes: classes }}
          fullWidth
        />
      );

    if (type === "y_n")
      return (
        <TextField
          select
          size="small"
          id={`${id}-select`}
          value={filters[id]?.value ?? "all"}
          onChange={(e) => updateFilters(id, e.target.value, type)}
          variant="outlined"
          fullWidth
        >
          <MenuItem key="all" value="all">
            All
          </MenuItem>
          <MenuItem key="Y" value="Y">
            Yes
          </MenuItem>
          <MenuItem key="N" value="N">
            No
          </MenuItem>
        </TextField>
      );
    if (type === "select") {
      return (
        <TextField
          select
          size="small"
          id={`${id}-select`}
          value={filters[id]?.value ?? "all"}
          onChange={(e) => updateFilters(id, e.target.value, type)}
          variant="outlined"
          fullWidth
        >
          <MenuItem key="all" value="all">
            All
          </MenuItem>
          {opportunityStageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    if (type === "date")
      return (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <CustomDatePicker
            placeholderText="Start"
            key={filters[id]?.start || nanoid()}
            value={filters[id]?.start || undefined}
            onChange={(start) =>
              updateFilters(id, start.getTime(), type, "start")
            }
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            dateFormat={"dd/MMM/yyyy"}
            small={true}
          />

          <CustomDatePicker
            placeholderText="End"
            key={filters[id]?.end || nanoid()}
            value={filters[id]?.end || undefined}
            onChange={(end) => updateFilters(id, end.getTime(), type, "end")}
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            dateFormat={"dd/MMM/yyyy"}
            small={true}
          />
        </div>
      );
  };

  return (
    <MuiTableHead classes={classes}>
      <TableRow classes={classes}>
        <StyledTableCell>
          {Object.keys(filters).length > 0 && (
            <IconButton
              onClick={() => setFilters({})}
              variant="outlined"
              color="primary"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          )}
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            style={
              ["enrollmentPlanLastUpdate", "soeLastUpdate"].includes(
                headCell.id
              )
                ? { minWidth: "10rem" }
                : {}
            }
            key={headCell.id}
            align="center"
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {renderInputField(headCell.id, headCell.type)}
          </StyledTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e7e8",
    borderRadius: "10px",
  },
}));
