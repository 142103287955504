import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Title } from "../../../../../../app/components";

const ViewSelector = ({ onChange, value, type }) => (
  <div>
    <div style={{ marginBottom: "1em" }}>
      <Title>
        {type === "cohort" ? "Details Table - Cohort" : "Details Table"}
      </Title>
    </div>
    {!(type === "cohort") && (
      <FormControl variant="outlined" style={{ width: "220px" }}>
        <InputLabel id="view-simple-select-outlined-label">
          Select Table View
        </InputLabel>
        <Select
          labelId="view-simple-select-outlined-label"
          id="view-simple-select-outlined"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          label="Select Table View"
        >
          <MenuItem value="monthly">Monthly (Cumulative)</MenuItem>
          <MenuItem value="monthlyNonCumulative">
            Monthly (Non-Cumulative)
          </MenuItem>
          <MenuItem value="weekly">Weekly (Cumulative)</MenuItem>
          <MenuItem value="weeklyNonCumulative">
            Weekly (Non-Cumulative)
          </MenuItem>
        </Select>
      </FormControl>
    )}
  </div>
);

export default ViewSelector;
