import { useStudyPlan } from "../../../../../../app/contexts/study.context";
import { useSnackbar } from "../../../../../../app/contexts/snackbar.context";
import ExcelJS from 'exceljs';
import excelFile from './plan_template.xlsx';
const formatDate = (milliseconds) => {
    if (milliseconds) {
      const date = new Date(milliseconds);
      const day = date.getDate().toString().padStart(2, '0');
      const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return null;
  };
export const useExportPlan = () => {
  const { studyPlan } = useStudyPlan();
  const { showSnackbar } = useSnackbar();
  const exportPlan = async () => {
    try {
      showSnackbar(`Loading data...`, "success");
      // Call the API to retrieve the study plan based on the studyId
      const countries = studyPlan['countries'];
      const countryNames = [];
      let studyPlanTypeDetail;
      if (studyPlan['studyPlanType'] == 1) {
        studyPlanTypeDetail = 'Rates';
      } else {
        studyPlanTypeDetail = 'Duration';
      }
      // Extract data from siteGroups for each country
      const siteIdEfforts = [];
      const countryStartUpTimeFrames = [];
      const enrollmentRates = [];
      const numActivatedSitesLowerBounds = [];
      const numActivatedSitesUpperBounds = [];
      const numPatientsScreenedLowerBounds = [];
      const numPatientsScreenedUpperBounds = [];
      const screenFailRates = [];
      const numPatientsEnrolled = [];
      const dropOutRates = [];
      const numPatientsCompletingTreatment = [];
      const numPatientsCompletingFollowUp = [];
      const siteIdFinishDates = [];
      const siteIdFinishComments = [];
      const regulatorySubmissionDates = [];
      const regulatorySubmissionComments = [];
      const firstSiteStartUpDates = [];
      const firstSiteStartUpComments = [];
      const firstSiteActivationDates = [];
      const firstSiteActivationComments = [];
      const firstPatientScreenedDates = [];
      const firstPatientScreenedComments = [];
      const firstPatientEnrolledDates = [];
      const firstPatientEnrolledComments = [];
      const lastPatientEnrolledDates = [];
      const lastPatientEnrolledComments = [];
      countries.forEach(country => {
        const siteGroups = country.siteGroups;
        siteGroups.forEach(siteGroup => {
          siteIdEfforts.push(siteGroup.timeSiteIdSize);
          countryStartUpTimeFrames.push(siteGroup.timeCountryStartUpSize);
          if (studyPlanTypeDetail === "Duration") {
            enrollmentRates.push("");
          } else {
            enrollmentRates.push(siteGroup.siteEnrollmentRateAtSIV);
          }
          countryNames.push(siteGroup.siteGroupName);
          numActivatedSitesLowerBounds.push(siteGroup.parameters.find(param => param.parameterName === 'SITES_TO_ACTIVATE')?.lowerBound || null);
          numActivatedSitesUpperBounds.push(siteGroup.parameters.find(param => param.parameterName === 'SITES_TO_ACTIVATE')?.upperBound || null);
          numPatientsScreenedLowerBounds.push(siteGroup.parameters.find(param => param.parameterName === 'SUBJECTS_TO_SCREEN')?.lowerBound || null);
          numPatientsScreenedUpperBounds.push(siteGroup.parameters.find(param => param.parameterName === 'SUBJECTS_TO_SCREEN')?.upperBound || null);
          screenFailRates.push(siteGroup.expectedScreenFailureRate);
          numPatientsEnrolled.push(siteGroup.milestones.find(milestone => milestone.milestoneName === 'NUM_SUBJECTS_ENROLLED')?.lowerBound || null);
          dropOutRates.push(siteGroup.expectedTreatmentDropoutRate);
          numPatientsCompletingTreatment.push(siteGroup.milestones.find(milestone => milestone.milestoneName === 'NUM_SUBJECTS_TREATED')?.lowerBound || null);
          numPatientsCompletingFollowUp.push(siteGroup.milestones.find(milestone => milestone.milestoneName === 'NUM_SUBJECTS_COMPLETED')?.lowerBound || null);
          const siteIdFinishMilestone = siteGroup.milestones.find(milestone => milestone.milestoneName === 'DURATION_TO_SITE_ID_FINISH');
          siteIdFinishDates.push(formatDate(siteIdFinishMilestone?.milestoneDateMillis));
          siteIdFinishComments.push(siteIdFinishMilestone?.comments || null);
          const regulatorySubmissionMilestone = siteGroup.milestones.find(milestone => milestone.milestoneName === 'DURATION_TO_SUBMISSION');
          regulatorySubmissionDates.push(formatDate(regulatorySubmissionMilestone?.milestoneDateMillis));
          regulatorySubmissionComments.push(regulatorySubmissionMilestone?.comments || null);
          const firstSiteStartUpMilestone = siteGroup.milestones.find(milestone => milestone.milestoneName === 'DURATION_TO_FIRST_REGULATORY_APPROVAL');
          firstSiteStartUpDates.push(formatDate(firstSiteStartUpMilestone?.milestoneDateMillis));
          firstSiteStartUpComments.push(firstSiteStartUpMilestone?.comments || null);
          const firstSiteActivationMilestone = siteGroup.milestones.find(milestone => milestone.milestoneName === 'DURATION_TO_FSIV');
          firstSiteActivationDates.push(formatDate(firstSiteActivationMilestone?.milestoneDateMillis));
          firstSiteActivationComments.push(firstSiteActivationMilestone?.comments || null);
          const firstPatientScreenedMilestone = siteGroup.milestones.find(milestone => milestone.milestoneName === 'DURATION_TO_FPS');
          firstPatientScreenedDates.push(formatDate(firstPatientScreenedMilestone?.milestoneDateMillis));
          firstPatientScreenedComments.push(firstPatientScreenedMilestone?.comments || null);
          const firstPatientEnrolledMilestone = siteGroup.milestones.find(milestone => milestone.milestoneName === 'DURATION_TO_FPI');
          firstPatientEnrolledDates.push(formatDate(firstPatientEnrolledMilestone?.milestoneDateMillis));
          firstPatientEnrolledComments.push(firstPatientEnrolledMilestone?.comments || null);
          const lastPatientEnrolledMilestone = siteGroup.milestones.find(milestone => milestone.milestoneName === 'DURATION_TO_LPI');
          lastPatientEnrolledDates.push(formatDate(lastPatientEnrolledMilestone?.milestoneDateMillis));
          lastPatientEnrolledComments.push(lastPatientEnrolledMilestone?.comments || null);
        });
      });
      // Load the template Excel file
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(await fetch(excelFile).then(res => res.arrayBuffer()));
      const worksheet = workbook.getWorksheet(1);
      // Set the value of cell C1
      worksheet.getCell('C1').value = studyPlanTypeDetail;
      // Set the country names in cells from B7 to B36
      const startRow = 7;
      countryNames.forEach((countryName, index) => {
        const cellAddress = `B${startRow + index}`;
        worksheet.getCell(cellAddress).value = countryName;
      });
      // Set the data in cells from C7 to C36, D7 to D36, and so on
      const columns = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];
      const data = [
        siteIdEfforts,
        countryStartUpTimeFrames,
        enrollmentRates,
        numActivatedSitesLowerBounds,
        numActivatedSitesUpperBounds,
        numPatientsScreenedLowerBounds,
        numPatientsScreenedUpperBounds,
        screenFailRates,
        numPatientsEnrolled,
        dropOutRates,
        numPatientsCompletingTreatment,
        numPatientsCompletingFollowUp
      ];
      data.forEach((dataArray, dataIndex) => {
        dataArray.forEach((value, index) => {
          const cellAddress = `${columns[dataIndex]}${startRow + index}`;
          worksheet.getCell(cellAddress).value = value;
        });
      });
      const milestoneStartRow = 42;
      // Set the milestone dates and comments in the next columns
      const milestoneColumns = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];
      const milestoneData = [
        siteIdFinishDates,
        siteIdFinishComments,
        regulatorySubmissionDates,
        regulatorySubmissionComments,
        firstSiteStartUpDates,
        firstSiteStartUpComments,
        firstSiteActivationDates,
        firstSiteActivationComments,
        firstPatientScreenedDates,
        firstPatientScreenedComments,
        firstPatientEnrolledDates,
        firstPatientEnrolledComments,
        lastPatientEnrolledDates
      ];
      milestoneData.forEach((milestoneArray, milestoneIndex) => {
        milestoneArray.forEach((value, index) => {
          const cellAddress = `${milestoneColumns[milestoneIndex]}${milestoneStartRow + index}`;
          worksheet.getCell(cellAddress).value = value;
        });
      });

        // ... (previous code remains the same)

        // Extract data from activationProfiles for each country
        const card1Date = [];
        const card1Percent = [];
        const card2Date = [];
        const card2Percent = [];
        const card3Date = [];
        const card3Percent = [];
        const card4Date = [];
        const card4Percent = [];
        const card5Date = [];
        const card5Percent = [];
        const card6Date = [];
        const card6Percent = [];
        const card7Date = [];
        const card7Percent = [];
        const card8Date = [];
        const card8Percent = [];

        countries.forEach(country => {
          const siteGroups = country.siteGroups;
          siteGroups.forEach(siteGroup => {
            const activationProfiles = siteGroup.activationProfiles;
            for (let i = 0; i < 8; i++) {
              const profile = activationProfiles[i];
              if (profile) {
                switch (i) {
                  case 0:
                    card1Date.push(formatDate(profile.siteActivationProfileDateMillis));
                    card1Percent.push(profile.siteActivationProfilePct);
                    break;
                  case 1:
                    card2Date.push(formatDate(profile.siteActivationProfileDateMillis));
                    card2Percent.push(profile.siteActivationProfilePct);
                    break;
                  case 2:
                    card3Date.push(formatDate(profile.siteActivationProfileDateMillis));
                    card3Percent.push(profile.siteActivationProfilePct);
                    break;
                  case 3:
                    card4Date.push(formatDate(profile.siteActivationProfileDateMillis));
                    card4Percent.push(profile.siteActivationProfilePct);
                    break;
                  case 4:
                    card5Date.push(formatDate(profile.siteActivationProfileDateMillis));
                    card5Percent.push(profile.siteActivationProfilePct);
                    break;
                  case 5:
                    card6Date.push(formatDate(profile.siteActivationProfileDateMillis));
                    card6Percent.push(profile.siteActivationProfilePct);
                    break;
                  case 6:
                    card7Date.push(formatDate(profile.siteActivationProfileDateMillis));
                    card7Percent.push(profile.siteActivationProfilePct);
                    break;
                  case 7:
                    card8Date.push(formatDate(profile.siteActivationProfileDateMillis));
                    card8Percent.push(profile.siteActivationProfilePct);
                    break;
                }
              } else {
                switch (i) {
                  case 0:
                    card1Date.push(null);
                    card1Percent.push(null);
                    break;
                  case 1:
                    card2Date.push(null);
                    card2Percent.push(null);
                    break;
                  case 2:
                    card3Date.push(null);
                    card3Percent.push(null);
                    break;
                  case 3:
                    card4Date.push(null);
                    card4Percent.push(null);
                    break;
                  case 4:
                    card5Date.push(null);
                    card5Percent.push(null);
                    break;
                  case 5:
                    card6Date.push(null);
                    card6Percent.push(null);
                    break;
                  case 6:
                    card7Date.push(null);
                    card7Percent.push(null);
                    break;
                  case 7:
                    card8Date.push(null);
                    card8Percent.push(null);
                    break;
                }
              }
            }
          });
        });
        const cardsStartRow = 77;
        // Set the milestone dates and comments in the next columns
        const cardColumns = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'];
        const cardData = [
          card1Percent,
          card1Date,
          card2Percent,
          card2Date,
          card3Percent, 
          card3Date,
          card4Percent, 
          card4Date,
          card5Percent, 
          card5Date,
          card6Percent, 
          card6Date,
          card7Percent,
          card7Date,
          card8Percent,
          card8Date 
        ];
        cardData.forEach((cardArray, cardIndex) => {
          cardArray.forEach((value, index) => {
            const cellAddress = `${cardColumns[cardIndex]}${cardsStartRow + index}`;
            worksheet.getCell(cellAddress).value = value;
          });
        });

      // Extract data from enrollmentVariations for each country
      const enrollmentJan = [];
      const enrollmentFeb = [];
      const enrollmentMar = [];
      const enrollmentApr = [];
      const enrollmentMay = [];
      const enrollmentJun = [];
      const enrollmentJul = [];
      const enrollmentAug = [];
      const enrollmentSep = [];
      const enrollmentOct = [];
      const enrollmentNov = [];
      const enrollmentDec = [];

      countries.forEach(country => {
        const siteGroups = country.siteGroups;
        siteGroups.forEach(siteGroup => {
          const enrollmentVariations = siteGroup.enrollmentVariations;
          enrollmentVariations.forEach(variation => {
            const month = variation.enrollmentSeasonalMonth;
            const value = variation.enrollmentSeasonalVariation;
            
            switch (month) {
              case 0:
                enrollmentJan.push(value);
                break;
              case 1:
                enrollmentFeb.push(value);
                break;
              case 2:
                enrollmentMar.push(value);
                break;
              case 3:
                enrollmentApr.push(value);
                break;
              case 4:
                enrollmentMay.push(value);
                break;
              case 5:
                enrollmentJun.push(value);
                break;
              case 6:
                enrollmentJul.push(value);
                break;
              case 7:
                enrollmentAug.push(value);
                break;
              case 8:
                enrollmentSep.push(value);
                break;
              case 9:
                enrollmentOct.push(value);
                break;
              case 10:
                enrollmentNov.push(value);
                break;
              case 11:
                enrollmentDec.push(value);
                break;
            }
          });
        });
      });
      
      const enrollmentStartRow = 110;
      // Set the milestone dates and comments in the next columns
      const enrollmentColumns = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];
      const enrollmentData = [
        enrollmentJan,
        enrollmentFeb,   
        enrollmentMar,      
        enrollmentApr,    
        enrollmentMay,   
        enrollmentJun, 
        enrollmentJul,    
        enrollmentAug,
        enrollmentSep,  
        enrollmentOct,
        enrollmentNov,
        enrollmentDec,
      ];
      enrollmentData.forEach((enrollmentArray, enrollmentIndex) => {
        enrollmentArray.forEach((value, index) => {
          const cellAddress = `${enrollmentColumns[enrollmentIndex]}${enrollmentStartRow + index}`;
          worksheet.getCell(cellAddress).value = value;
        });
      });

      const eventualSiteEnrollmentDaysList = [];
      const eventualSiteEnrollmentRateList = [];

      countries.forEach(country => {
        const siteGroups = country.siteGroups;
        siteGroups.forEach(siteGroup => {
          const eventualSiteEnrollmentDays = siteGroup.eventualSiteEnrollmentDays;
          const eventualSiteEnrollmentRate = siteGroup.eventualSiteEnrollmentRate;
          
          eventualSiteEnrollmentDaysList.push(eventualSiteEnrollmentDays);
          eventualSiteEnrollmentRateList.push(eventualSiteEnrollmentRate);
        });
      });

      const fatigueStartRow = 143;
      // Set the milestone dates and comments in the next columns
      const fatigueColumns = ['C', 'D'];
      const fatigueData = [
        eventualSiteEnrollmentDaysList,
        eventualSiteEnrollmentRateList
      ];
      fatigueData.forEach((fatigueArray, fatigueIndex) => {
        fatigueArray.forEach((value, index) => {
          const cellAddress = `${fatigueColumns[fatigueIndex]}${fatigueStartRow + index}`;
          worksheet.getCell(cellAddress).value = value;
        });
      });
      // Generate the updated Excel file
      const excelBuffer = await workbook.xlsx.writeBuffer();
      // Create a Blob from the Excel buffer
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${studyPlan.studyName}_study_plan.xlsx`;
      link.click();
      showSnackbar(`Data has been loaded and saved into the excel template.`, "success");
    } catch (error) {
      console.error('Error fetching study plan:', error);
      showSnackbar(`Error fetching study plan: ${error}`, "error");
    }
  };
  return exportPlan;
};

