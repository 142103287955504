import React, { Fragment, useEffect, useMemo, useState } from "react";

import API from "../app/utils/api";
import { CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "./table.head";
import TableHeadFilter from "./table.head/filter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "./table.row";
import { debounce } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

// Component for displaying a searchable table of study plans
const SearchTable = ({ filter = "" }) => {
  // State variables for managing projects, filtered projects, loading state, sorting, pagination, filter activation, and filters
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [filterActive, setFilterActive] = useState(false);
  const [filters, setFilters] = useState({
    // projectId: {
    //   value: "Project ID",
    // }
  });

  // add a debounce function to be called of filter change to apply the filter
  const debounceFilter = useMemo(
    () =>
      debounce((lFilters) => {
        // filter the table here
        const filterKeys = Object.keys(lFilters);
        if (!filterKeys.length) return setFilteredProjects(projects);
        // for each filter, filter projects using the id and value and set the filtered projects

        const filtered = projects.filter((project) => {
          return filterKeys.every((filterId) => {
            const { value, type, start, end } = lFilters[filterId];
            if (type === "date") {
              if (!start || !end) return true;
              const startDate = moment(start).valueOf();
              const endDate = moment(end).valueOf();
              if (!project[filterId]) return false;

              const projectDate = moment(project[filterId]).valueOf();
              if (projectDate >= startDate && projectDate <= endDate)
                return true;
              else return false;
            }

            if (type === "select") {
              if (!project[filterId]) return false;
            }
            return project[filterId]
              .toLowerCase()
              .includes(value.toLowerCase());
          });
        });
        setFilteredProjects(filtered);
        // set page to 0
        setPage(0);
      }, 500),
    // eslint-disable-next-line
    [projects]
  );

  useEffect(() => {
    // console.log(filters);
    debounceFilter(filters);
  }, [debounceFilter, filters]);

  // Fetch data from API based on filter
  useEffect(() => {
    setLoading(true);
    API.getStudies(`${filter || "*"}`)
      .then((res) => {
        setLoading(false);
        setFilteredProjects(res.data);
        // loop through the projects and get the unique keys from opportunityStage
        //const uniqueOpportunityStages = [
        //  ...new Set(res.data.map((project) => project.opportunityStage)),
        //];
        //console.log(uniqueOpportunityStages);
        return setProjects(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [filter]);

  const classes = useStyles();

  // /**
  //  * Searching
  //  */
  // const plans = useMemo(() => {
  //   setLoading(true);
  //   const filteredValues = projects.filter((plan) =>
  //     plan.studyName.toLowerCase().includes(filter.toLocaleLowerCase())
  //   );
  //   setLoading(false);
  //   return filteredValues;
  // }, [projects, filter]);

  /**
   *  Sorting Fuctions
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    const valueA = (a = a[orderBy] ?? "");
    const valueB = (b = b[orderBy] ?? "");
    // make sure this function handles integers, strings, and booleans
    if (orderBy === "isFavorite") {
      return valueB - valueA;
    }
    return valueB.localeCompare(valueA);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  /**
   * Pagination
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    const rows = parseInt(value);

    setRowsPerPage(rows);
    setPage(0);
  };

  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          width: "100%",
          minHeight: "40rem",
          maxWidth: "calc(1600px - 1em - 250px",
          overflowX: "auto",
        }}
      >
        {!loading && (
          <Paper style={{ minHeight: "100%" }}>
            <TableContainer classes={classes}>
              <Table size="medium" aria-label="study table">
                <TableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  filterActive={filterActive}
                  setFilterActive={setFilterActive}
                />
                {filterActive && (
                  <TableHeadFilter
                    filters={filters}
                    setFilters={setFilters}
                    filterActive={filterActive}
                    setFilterActive={setFilterActive}
                  />
                )}
                <TableBody>
                  {stableSort(filteredProjects, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row.studyPlanId}
                        {...row}
                        projects={filteredProjects || []}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              rowsPerPage={rowsPerPage}
              page={page}
              count={filteredProjects.length || 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        )}
        {loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#eeeeee55",
              zIndex: 1,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SearchTable;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
  },
}));
