import React from "react";
import styled from "styled-components";
import { AppBar, Divider, makeStyles, Tab, Tabs } from "@material-ui/core";
import { makeTabLabels } from "./utils";

export default function CountryTabs({ tabs, value, onChange }) {
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const tabsClasses = useTabsStyles();
  return (
    <Content>
      <AppBar position="static" color="transparent" classes={classes}>
        <Tabs
          value={value}
          onChange={onChange}
          classes={tabsClasses}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          indicatorColor="primary"
        >
          {tabs?.map((tab, index) => (
            <Tab
              classes={tabClasses}
              label={makeTabLabels(tab)}
              key={tab?.siteGroupId}
              id={`scrollable-auto-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </AppBar>
      <Divider />
    </Content>
  );
}

const Content = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
    width: "900px",
  },
}));

const useTabStyles = makeStyles(() => ({
  root: {
    width: "78px",
    minWidth: "78px",
  },
}));

const useTabsStyles = makeStyles(() => ({
  indicator: {
    width: "78px",
    minWidth: "78px",
  },
}));
