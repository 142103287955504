export const COLUMN_HEADERS = {
  "Enrollment Start": "Enrollment Start",
  "Number of Activated Sites": "# Sites",
  "Screen Rate": "Average Screen Rate",
  "Enrollment Rate": "Average Enrollment Rate",
  "Number of Patients Screened": "# Patients Screened",
  "Number of Patients Enrolled": "# Patients Enrolled",
  "Number of Patients Completing Treatment": "# Patients Completed",
  "Enrollment Duration": "Enrollment Duration (months)",
  "Screen Fail Rate": "Screen Fail Rate",
  "Drop Out Rate": "Drop Out Rate",
  "Site Months": "Screening Site Months",
};
