import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

import NewScheduleEventModal from "./new.schedule.event.modal";
import { useScheduleEvents } from "../../../app/contexts/schedule.events.context";

const Controls = ({ search = "", onSearch, clearSearch }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openNewSOEModal, setOpenNewSOEModal] = useState(false);
  const { id } = useParams();
  const { createNewSOEPlan, fetchSOEStudy } = useScheduleEvents();

  const redirect = async () => {
    await fetchSOEStudy(id);
  };

  const handleSearch = (e) => {
    onSearch(e.target.value);
  };

  const handleClear = () => {
    setOpen(false);
    clearSearch();
  };

  const handleClick = async (soe) => {
    const soeEventData = {
      studyId: id ? parseInt(id) : id,

      scheduleOfEventsName: soe.scheduleOfEventsName,

      scheduleOfEventsDescr: soe.scheduleOfEventsDescr,

      scheduleOfEventsStatus: "Draft",
      visitIntervalType: soe.visitIntervalType,
      visitWindowIntervalType: soe.visitWindowIntervalType,
    };
    await createNewSOEPlan(soeEventData, redirect);
  };

  return (
    <Container>
      <SearchContainer>
        <IconButton onClick={() => setOpen((prev) => !prev)}>
          <SearchIcon className={classes.search} />
        </IconButton>
        {open && (
          <TextField
            value={search}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClear}>
                  <ClearIcon className={classes.clear} />
                </IconButton>
              ),
            }}
          />
        )}
      </SearchContainer>

      <Button
        onClick={() => setOpenNewSOEModal(true)}
        variant="contained"
        color="primary"
      >
        Add New SOE
      </Button>

      {openNewSOEModal && (
        <NewScheduleEventModal
          onContinue={handleClick}
          open={openNewSOEModal}
          onClose={() => setOpenNewSOEModal(false)}
        />
      )}
    </Container>
  );
};
export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
  gap: 1em;
  align-content: center;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  search: {
    color: "#797979",
  },
  clear: {
    color: "#b2b2b2",
  },
}));
