import { SectionHeader, Tooltip } from "../../../../../app/components";

import React from "react";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { checkNumberLength } from "../../../utils/helpers";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const SiteGroupActivationProfiles = ({ country, siteGroup }) => {
  const inputClasses = useInputStyles();
  const { updateCountries } = useStudyPlan();
  const { siteGroupId, enrollmentVariations } = siteGroup;
  const { countryId } = country;

  const handleChange = (value, index) => {
    const newEnrollmentVariations = enrollmentVariations;
    newEnrollmentVariations[index].enrollmentSeasonalVariation = value;

    const update = { enrollmentVariations: newEnrollmentVariations };
    updateCountries(countryId, siteGroupId, update);
  };

  return (
    <Container>
      <SectionHeader
        endAdornment={
          <Tooltip info="Seasonal enrollment is modeled using the below table that contains a percentage for each month of the year. This allows the model to factor in when screening may be slower (e.g., August in Europe) as well as when an indication has a specific seasonal activity profile (e.g., incidence of the flu). The regular screening rate would correspond to a 100% or ‘1’ entry in the table for that month, and slower screening would then be modeled with a lower value (e.g., 0.85 or 85% of the regular screening rate)." />
        }
      >
        Country Enrollment Seasonal Variations
      </SectionHeader>
      <Table>
        <Row>
          <Cell variant="header">
            <Typography>
              <strong>Jan</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Feb</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Mar</strong>
            </Typography>{" "}
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Apr</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>May</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Jun</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Jul</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Aug</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Sep</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Oct</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Nov</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Dec</strong>
            </Typography>
          </Cell>
        </Row>
        <Row>
          {enrollmentVariations.map((item, index) => (
            <Cell key={item.seasonalEnrollmentVariationId}>
              <TextField
                type="number"
                classes={inputClasses}
                value={item.enrollmentSeasonalVariation ?? ""}
                onChange={(event) => {
                  let value = event.target.value;
                  if (value.includes("-")) {
                    handleChange("0", index);
                    return;
                  }
                  if (!checkNumberLength(value, 4, 2, 1, true, true)) return;
                  handleChange(value, index);
                }}
                variant="outlined"
                size="small"
                required
              />
            </Cell>
          ))}
        </Row>
      </Table>
    </Container>
  );
};

export default SiteGroupActivationProfiles;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 50px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 1px solid #ccc;
  &:last-child {
    border: none;
  }
  padding: 5px;
`;

const Table = styled.div`
  display: grid;
  gridtemplaterows: auto auto;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const useInputStyles = makeStyles(() => ({
  input: {
    textAlign: "center",
  },
}));
