import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import moment from "moment";
import DateEditor from "./date.editor";
const DateLabel = (props) => {
  const [open, setOpen] = useState(false);
  const { label = "None", value, hasEdit = false } = props;
  return (
    <div style={{ marginBottom: "1em" }}>
      {!open && (
        <span style={{ display: "flex", alignItems: "center", height: "36px" }}>
          <div>
            <Typography>
              <strong>
                {value === null
                  ? "DD/MM/YYYY"
                  : moment(value).utc().format("DD/MMM/YYYY")}
              </strong>{" "}
              - {label}
              {"  "}
            </Typography>
            {props.comments && (
              <Typography style={{ marginTop: "-.5rem" }}>
                <strong>Comments: </strong>
                {props.comments}
              </Typography>
            )}
          </div>
          {hasEdit && (
            <span style={{ marginLeft: "1em" }}>
              |
              <Button color="primary" onClick={() => setOpen(true)}>
                <strong>EDIT</strong>
              </Button>
            </span>
          )}
        </span>
      )}
      {open && <DateEditor {...props} onExit={() => setOpen(false)} />}
    </div>
  );
};

export default DateLabel;
