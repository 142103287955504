import React, { useMemo } from "react";
import { Accordion } from "../../../../app/components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import DisplayField from "./display.field";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

const labels = {
  projectId: "PCN",
  sponsorName: "Sponsor",
  proposalVersion: "Proposal Version",
  proposalDueDate: "Proposal Due Date",
  studyName: "Study Title",
  therapeuticArea: "Therapeutic Area",
  indication: "Indication",
  investigationalProductName: "Product Name",
  phase: "Study Phase",
  protocolId: "Protocol Number",
  therapeuticFranchise: "Therapeutic Franchise",
  proposalBudgeterName: "Proposal Budgeter ",
  proposalStrategyName: "Strategy Lead",
  scheduleOfEventsName: "SOE Name",
  scheduleOfEventsDescr: "SOE Description",
  studyPlanName: "Linked Enrollment Plan (Study Plan)",
};

export default function Header() {
  const { currentSOE } = useScheduleEvents();
  const { study } = useStudyPlan();
  const {
    studyPlans,
    projectId,
    sponsorName,
    proposalVersion,
    proposalDueDate,
    studyName,
    therapeuticArea,
    indication,
    investigationalProductName,
    phase,
    protocolId,
    therapeuticFranchise,
    proposalBudgeterName,
    proposalStrategyName,
  } = study || {};

  const { scheduleOfEventsName, scheduleOfEventsDescr, studyPlanId } =
    currentSOE || {};

  const studyPlanName = useMemo(
    () =>
      studyPlans.find((sp) => sp.studyPlanId === studyPlanId)?.studyPlanName,
    [studyPlans, studyPlanId]
  );
  return (
    <Accordion width={"100%"} summary={"Study and Enrollment Plan Parameters"}>
      <MainContainer>
        <VerticalContainer>
          <HorizontalContainer>
            <DisplayField value={studyName} label={labels["studyName"]} />
          </HorizontalContainer>
          <HorizontalContainer>
            <DisplayField
              value={studyPlanName}
              label={labels["studyPlanName"]}
            />
          </HorizontalContainer>
          <VerticalContainer>
            <DisplayField
              value={scheduleOfEventsName}
              label={labels["scheduleOfEventsName"]}
            />
            <DisplayField
              value={scheduleOfEventsDescr}
              label={labels["scheduleOfEventsDescr"]}
            />
          </VerticalContainer>
          <HorizontalContainer>
            <VerticalContainer>
              <DisplayField
                value={proposalDueDate}
                label={labels["proposalDueDate"]}
              />
              <DisplayField
                value={investigationalProductName}
                label={labels["investigationalProductName"]}
              />
            </VerticalContainer>
            <VerticalContainer>
              <DisplayField
                value={therapeuticArea}
                label={labels["therapeuticArea"]}
              />
              <DisplayField value={indication} label={labels["indication"]} />
            </VerticalContainer>
            <VerticalContainer>
              <DisplayField value={protocolId} label={labels["protocolId"]} />
              <DisplayField
                value={therapeuticFranchise}
                label={labels["therapeuticFranchise"]}
              />
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer>
            <VerticalContainer>
              <DisplayField value={sponsorName} label={labels["sponsorName"]} />
              <DisplayField value={projectId} label={labels["projectId"]} />
            </VerticalContainer>
            <VerticalContainer>
              <DisplayField
                value={proposalVersion}
                label={labels["proposalVersion"]}
              />

              <DisplayField
                value={proposalBudgeterName}
                label={labels["proposalBudgeterName"]}
              />
            </VerticalContainer>
            <VerticalContainer>
              <DisplayField
                value={proposalStrategyName}
                label={labels["proposalStrategyName"]}
              />
              <DisplayField value={phase} label={labels["phase"]} />
            </VerticalContainer>
          </HorizontalContainer>
        </VerticalContainer>
      </MainContainer>
    </Accordion>
  );
}

const MainContainer = styled.div`
  padding: 0em 1em;
  padding-bottom: 1em;
  flex: 1;
`;

const HorizontalContainer = styled.div`
  display: flex;
  gap: 5em;
  flex: 1;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  flex: 1;
`;
