import {
  CircularProgress,
  FormControl,
  LinearProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import { Modal } from "../../../../app/components";
import { makeStyles } from "@material-ui/styles";
import { nanoid } from "nanoid";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import { useStudyPlan } from "../../../../app/contexts/study.context";

const VISIT_INTERVAL_TYPE_OPTIONS = ["Hour", "Day", "Week", "Month", "Year"];
const CDR_REVIEW_OPTIONS = [
  "0%",
  "10%",
  "20%",
  "30%",
  "40%",
  "50%",
  "60%",
  "70%",
  "80%",
  "90%",
  "100%",
];

const ADDITIONAL_TIME_ON_SITE_FACTOR = ["5%", "10%", "15%"];

const ESTIMATED_ACTIVATED_NON_ENROLLING_SITES = ["5%", "10%", "15%", "20%"];

const REQUIRED_ADMINISTRATIVE_IMV_NON_ENROLLING_SITES = [
  "Semi Annually (q6 months post Site Activation)",
  "Annually (q12 months post Site Activation)",
  "<blank value>",
];

const SUBJECT_REPORTING_AVERAGE_FREQUENCY = [
  "Monthly",
  "q 6 Weeks",
  "q 2 Months",
  "Quarterly",
  "q 4 Months",
  "q 6 Months",
  "q 9 Months",
  "Annual",
];

const SUBJECT_REPORTING_HOURS_TO_GENERATE = [
  "1",
  "1.5",
  "2",
  "2.5",
  "3",
  "3.5",
  "4",
  "4.5",
  "5",
  "8",
];

const STUDY_REPORTING_HOURS_TO_GENERATE = [
  "7.5",
  "15",
  "20",
  "25",
  "30",
  "40",
  "45",
];

/*
  This component is a modal that allows the user to edit a schedule of events.
  It uses the schedule events context to handle the editing of the schedule of events.
  The parent component must pass a callback function to handle the schedule of events parameters.
*/
const EditScheduleOfEventModal = ({ soe, ...props }) => {
  const classes = useStyles();
  const { study } = useStudyPlan();

  const [reRenderForm, setReRenderForm] = useState(nanoid());

  const { scheduleOfEvents, fetchASingleSOE, savingSOEStudy } =
    useScheduleEvents();
  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [SOEEventParameters, setSOEEventParameters] = useState({
    scheduleOfEventsName: soe?.scheduleOfEventsName,
    scheduleOfEventsDescr: soe?.scheduleOfEventsDescr,
    scheduleOfEventsStatus: soe?.scheduleOfEventsStatus,
    studyPlanId: soe?.studyPlanId,
    visitIntervalType: soe?.visitIntervalType,
    visitWindowIntervalType: soe?.visitWindowIntervalType,
    CDAReviewPctTime: soe?.CDAReviewPctTime,
    assessorName: soe?.assessorName,
    AddlTimeOnSitePct: soe?.AddlTimeOnSitePct,
    EstActivatedNonEnrollingSitesPct: soe.EstActivatedNonEnrollingSitesPct,
    ReqAdminIMVNonEnrollingSites: soe.ReqAdminIMVNonEnrollingSites,
    SubjectReportingAvgFrequency: soe.SubjectReportingAvgFrequency,
    SubjectReportingHoursToGenerate: soe.SubjectReportingHoursToGenerate,
    StudyReportingAvgFrequency: soe.StudyReportingAvgFrequency,
    StudyReportingHoursToGenerate: soe.StudyReportingHoursToGenerate,
    StudyAdhocReview: soe.StudyAdhocReview,
  });
  const [errors, setErrors] = useState({
    scheduleOfEventsName: "",
    scheduleOfEventsDescr: "",
    visitIntervalType: "",
    visitWindowIntervalType: "",
    CDAReviewPctTime: "",
  });

  useEffect(() => {
    const { scheduleOfEventsId } = soe;
    if (scheduleOfEventsId) {
      (async () => {
        setIsLoading(true);
        const res = await fetchASingleSOE(scheduleOfEventsId);
        if (res.data && res.data.length) {
          setSOEEventParameters(res.data[0]);
          setTimeout(() => {
            setReRenderForm(nanoid());
            setIsLoading(false);
          }, 400);
        } else setIsLoading(true);
      })();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      SOEEventParameters["scheduleOfEventsDescr"] &&
      SOEEventParameters["scheduleOfEventsName"] &&
      SOEEventParameters["visitIntervalType"] &&
      SOEEventParameters["visitWindowIntervalType"] &&
      SOEEventParameters["CDAReviewPctTime"] &&
      !errors["scheduleOfEventsDescr"] &&
      !errors["scheduleOfEventsName"] &&
      !errors["visitIntervalType"] &&
      !errors["visitWindowIntervalType"] &&
      !errors["CDAReviewPctTime"]
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [SOEEventParameters, errors]);

  const handleChange = (key, value) => {
    setFormTouched(true);
    setSOEEventParameters({ ...SOEEventParameters, [key]: value });
    if (!value && key !== "StudyPlanId") {
      setErrors({ ...errors, [key]: "This field is required" });
    } else {
      setErrors({ ...errors, [key]: "" });
    }
    if (key === "scheduleOfEventsName") {
      scheduleOfEvents.forEach((sp) => {
        if (sp.scheduleOfEventsId === soe.scheduleOfEventsId) return;
        if (
          sp?.scheduleOfEventsName.toLowerCase().trim() ===
          value.toLowerCase().trim()
        ) {
          setErrors({
            ...errors,
            [key]: "Schedule of Events name must be unique",
          });
        }
        if (!value.replace(/\s/g, "").length) {
          setErrors({
            ...errors,
            [key]: "SOE name must contain at least 1 character",
          });
        }
      });
    }
    if (key === "scheduleOfEventsDescr") {
      if (!value.replace(/\s/g, "").length) {
        setErrors({
          ...errors,
          [key]: "SOE description must contain at least 1 character",
        });
      }
    }
  };

  return (
    <Modal
      size={"80%"}
      maxWidth={"1200px"}
      {...props}
      aria-labelledby="Edit Schedule of Events"
      aria-describedby="Create a new schedule of events"
      title="Edit Schedule of Events"
    >
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Container key={reRenderForm}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <VContainer>
              <h5>General</h5>
              <FormControl variant="outlined">
                <TextField
                  style={{ width: "100%" }}
                  value={SOEEventParameters.scheduleOfEventsName}
                  onChange={(e) =>
                    handleChange("scheduleOfEventsName", e.target.value)
                  }
                  variant="outlined"
                  label="SOE Name"
                  InputLabelProps={{ classes }}
                  required
                  error={!!errors.scheduleOfEventsName}
                  inputProps={{
                    maxLength: 50,
                  }}
                  helperText={
                    <AlignBetween>
                      <span>{errors.scheduleOfEventsName}</span>
                      <span>{`${
                        (SOEEventParameters.scheduleOfEventsName || "").length
                      } / ${50}`}</span>
                    </AlignBetween>
                  }
                  size="small"
                />
              </FormControl>
              <FormControl variant="outlined">
                <TextField
                  style={{ width: "100%" }}
                  value={SOEEventParameters.scheduleOfEventsDescr}
                  error={errors.scheduleOfEventsDescr}
                  onChange={(e) =>
                    handleChange("scheduleOfEventsDescr", e.target.value)
                  }
                  variant="outlined"
                  label="SOE Description"
                  InputLabelProps={{ classes }}
                  required
                  multiline
                  inputProps={{
                    maxLength: 2000,
                  }}
                  rows={4}
                  helperText={
                    <AlignBetween>
                      <span>{errors.scheduleOfEventsDescr}</span>
                      <span>{`${
                        (SOEEventParameters.scheduleOfEventsDescr || "").length
                      } / ${2000}`}</span>
                    </AlignBetween>
                  }
                  size="small"
                />
              </FormControl>
              <FormControl variant="outlined">
                <TextField
                  label="SOE Status"
                  id="soe-status"
                  variant="outlined"
                  fullWidth
                  select
                  value={SOEEventParameters.scheduleOfEventsStatus}
                  onChange={(event) => {
                    handleChange("scheduleOfEventsStatus", event.target.value);
                  }}
                  size="small"
                >
                  <MenuItem value="Draft">Draft</MenuItem>
                  <MenuItem value="Final">Final</MenuItem>
                </TextField>
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  error={errors.visitIntervalType}
                  required
                  id="visit-interval-type-id"
                  value={SOEEventParameters.visitIntervalType}
                  onChange={(event) =>
                    handleChange("visitIntervalType", event.target.value)
                  }
                  label="Visit Interval Type"
                >
                  {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  error={errors.visitWindowIntervalType}
                  required
                  id="visit-window-interval-type-id"
                  value={SOEEventParameters.visitWindowIntervalType}
                  onChange={(event) =>
                    handleChange("visitWindowIntervalType", event.target.value)
                  }
                  label="Visit Window Interval Type"
                >
                  {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  id="study-plan"
                  value={SOEEventParameters.studyPlanId}
                  onChange={(event) =>
                    handleChange("studyPlanId", event.target.value)
                  }
                  label="Study Plan"
                >
                  {study?.studyPlans?.map((sp) => (
                    <MenuItem value={sp.studyPlanId}>
                      {sp.studyPlanName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </VContainer>
            <VContainer>
              <h5>Clinical Strategy</h5>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  error={errors.CDAReviewPctTime}
                  required
                  id="cdareviewprctime"
                  value={SOEEventParameters.CDAReviewPctTime}
                  onChange={(event) =>
                    handleChange("CDAReviewPctTime", event.target.value)
                  }
                  label="CDA Review % Time"
                >
                  {CDR_REVIEW_OPTIONS?.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl variant="outlined">
                <TextField
                  style={{ width: "100%" }}
                  value={SOEEventParameters.assessorName}
                  onChange={(e) => handleChange("assessorName", e.target.value)}
                  variant="outlined"
                  label="Risk Strategy Assessor Name"
                  InputLabelProps={{ classes }}
                  multiline
                  inputProps={{
                    maxLength: 100,
                  }}
                  helperText={
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span>{`${
                        (SOEEventParameters.assessorName || "").length
                      } / ${100}`}</span>
                    </div>
                  }
                  size="small"
                />
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  id="additional-time-on-site-factor-id"
                  value={SOEEventParameters.AddlTimeOnSitePct}
                  onChange={(event) =>
                    handleChange("AddlTimeOnSitePct", event.target.value)
                  }
                  label="Additional Time on Site factor"
                >
                  {ADDITIONAL_TIME_ON_SITE_FACTOR?.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  id="estimated-activated-non-enrolling-id"
                  value={SOEEventParameters.EstActivatedNonEnrollingSitesPct}
                  onChange={(event) =>
                    handleChange(
                      "EstActivatedNonEnrollingSitesPct",
                      event.target.value
                    )
                  }
                  label="Estimated Activated Non-enrolling Sites"
                >
                  {ESTIMATED_ACTIVATED_NON_ENROLLING_SITES?.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  id="required-administrative-imv-id"
                  value={SOEEventParameters.ReqAdminIMVNonEnrollingSites}
                  onChange={(event) =>
                    handleChange(
                      "ReqAdminIMVNonEnrollingSites",
                      event.target.value
                    )
                  }
                  label='Required "Administrative" IMV for Non-Enrolling Sites '
                >
                  {REQUIRED_ADMINISTRATIVE_IMV_NON_ENROLLING_SITES?.map(
                    (type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    )
                  )}
                </TextField>
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  id="subject-reporting-average-frequency-id"
                  value={SOEEventParameters.SubjectReportingAvgFrequency}
                  onChange={(event) =>
                    handleChange(
                      "SubjectReportingAvgFrequency",
                      event.target.value
                    )
                  }
                  label="Subject Reporting, Average Frequency "
                >
                  {SUBJECT_REPORTING_AVERAGE_FREQUENCY?.map((type) => (
                    <MenuItem value={type === "<blank value>" ? "" : type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  id="subject-reporting-hours-to-generate-id"
                  value={SOEEventParameters.SubjectReportingHoursToGenerate}
                  onChange={(event) =>
                    handleChange(
                      "SubjectReportingHoursToGenerate",
                      event.target.value
                    )
                  }
                  label="Subject Reporting, Hours to Generate "
                >
                  {SUBJECT_REPORTING_HOURS_TO_GENERATE?.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  id="subject-reporting-average-frequency-id"
                  value={SOEEventParameters.StudyReportingAvgFrequency}
                  onChange={(event) =>
                    handleChange(
                      "StudyReportingAvgFrequency",
                      event.target.value
                    )
                  }
                  label="Study Reporting, Average Frequency "
                >
                  {SUBJECT_REPORTING_AVERAGE_FREQUENCY?.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  id="study-reporting-hours-to-generate-id"
                  value={SOEEventParameters.StudyReportingHoursToGenerate}
                  onChange={(event) =>
                    handleChange(
                      "StudyReportingHoursToGenerate",
                      event.target.value
                    )
                  }
                  label="Study Reporting, Hours to Generate "
                >
                  {STUDY_REPORTING_HOURS_TO_GENERATE?.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl variant="outlined">
                <TextField
                  style={{ width: "100%" }}
                  value={SOEEventParameters.StudyAdhocReview}
                  onChange={(e) => {
                    // only allow positive numeric values with two decimal places from 0.00 to 999.99
                    if (
                      e.target.value &&
                      (!/^\d{1,3}(\.\d{1,2})?$/.test(e.target.value) ||
                        e.target.value >= 1000)
                    ) {
                      return;
                    }

                    handleChange("StudyAdhocReview", e.target.value);
                  }}
                  variant="outlined"
                  label="Ad Hoc Review"
                  InputLabelProps={{ classes }}
                  type="number"
                  size="small"
                />
              </FormControl>
            </VContainer>
          </div>
          <ButtonContainer>
            <Button variant="outlined" onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              disabled={savingSOEStudy || !formValid || !formTouched}
              color="primary"
              variant="contained"
              onClick={() =>
                props.onContinue({ ...soe, ...SOEEventParameters })
              }
            >
              {savingSOEStudy && <CircularProgress size="1em" />}
              {!savingSOEStudy && "Save"}
            </Button>
          </ButtonContainer>
        </Container>
      )}
    </Modal>
  );
};

export default EditScheduleOfEventModal;

const VContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  flex: 1;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  max-height: 75vh;
  overflow-y: auto;

  // add a custom scrollbar style here

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  background-color: white;
  with: 100%;
  position: sticky;
  bottom: -13px;
  z-index: 10;
  padding: 1rem 0rem;
`;

const useStyles = makeStyles({
  asterisk: {
    color: "red",
  },
});

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
