import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

const SpecialTextField = ({
  value,
  onChange,
  label,
  inputProps = {},
  onBlur,
  endadornment = null,
  altlabel = null,
  ...props
}) => {
  const inputClasses = useInputStyles();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: altlabel ? "120px" : "80px",
    },
  }));
  const classes = useStyles();

  const handleChange = (e) => {
    if (e.target.value === "") {
      onChange(null);
    } else {
      onChange(parseFloat(e.target.value));
    }
  };

  const handleBlur = (e) => {
    onBlur(e.target.value);
  };

  return (
    <Container hasMargin={label !== undefined}>
      <TextField
        style={{ background: props.disabled ? "#eeeeee" : "#ffffff" }}
        type="number"
        value={value ?? ""}
        variant="outlined"
        classes={classes}
        InputProps={{
          classes: inputClasses,
        }}
        inputProps={{
          min: 1,
          type: "number",
          ...inputProps,
        }}
        disabled={props.disabled}
        {...props}
        onChange={onChange && handleChange}
        onBlur={onBlur && handleBlur}
        label={altlabel}
      />

      {label && (
        <Typography
          style={{
            marginLeft: "1em",
          }}
        >
          {label}
          {props.required && <span style={{ color: "red" }}>*</span>}
          {endadornment}
        </Typography>
      )}
    </Container>
  );
};

export default SpecialTextField;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  margin-right: ${(p) => (p.hasMargin ? "1em" : 0)};
`;

const useInputStyles = makeStyles(() => ({
  input: {
    textAlign: "center",
  },
}));
