import React from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { useQuery } from "../../../../app/hooks/useQuery";

import { SectionHeader } from "../../../../app/components";
import Typography from "@material-ui/core/Typography";
import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";

const OverallStudyInputs = ({ values, errors, touched, setValues }) => {
  const { studyPlan, STUDY_PLAN_TYPES } = useStudyPlan();
  const query = useQuery();
  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;

  let renderScenario;
  if (studyPlanType === SCENARIO_A || query.get("s") === "scenario-a") {
    renderScenario = <ScenarioA values={values} setValues={setValues} />;
  }

  if (studyPlanType === SCENARIO_B || query.get("s") === "scenario-b") {
    renderScenario = <ScenarioB values={values} setValues={setValues} />;
  }

  return (
    <div key={studyPlan.studyPlanId} id="overall-inputs">
      <SectionHeader>Overall Study Inputs</SectionHeader>
      <Typography style={{ marginBottom: "2em" }}>
        If certain parameters are not applicable to all countries, you can
        specify these parameters at the country level and skip them at this
        step. (<span style={{ color: "red" }}>*</span> = required field)
      </Typography>
      <Container>{renderScenario}</Container>
    </div>
  );
};

export default OverallStudyInputs;

const Container = styled.div``;
