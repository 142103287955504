import Label from "./label";
import React from "react";
import { Title } from "../../../../app/components";
import TitleLabel from "./title.label";
import moment from "moment";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";

const SummaryOfOverallParameters = () => {
  const { studyPlan } = useStudyPlan();
  const {
    croProjectStartDateMillis,
    finalProtocolStartDateMillis,
    screeningPeriodDuration,
    treatmentDuration,
    followUpDuration,
    studyPlanName,
    studyPlanDescr,
    scenarioStatus,
    actualAsOfDate,
    studyPlanTypeDetail,
  } = studyPlan;

  const formattedCroProjectStartDateMillis = moment(croProjectStartDateMillis)
    .utc()
    .format("DD/MMM/YYYY");
  const formattedFinalProtocolStartDateMillis = moment(
    finalProtocolStartDateMillis
  )
    .utc()
    .format("DD/MMM/YYYY");

  const renderStatusAndActualAsOfDate = () => {
    if (["Remodel (D)", "Remodel (R)"].includes(studyPlanTypeDetail)) {
      return (
        <TitleLabel
          label="Status"
          value={`${scenarioStatus} (Actuals as of: ${
            moment(actualAsOfDate).isValid()
              ? moment(actualAsOfDate).format("DD/MMM/YYYY")
              : ""
          })`}
        />
      );
    } else {
      return <TitleLabel label="Status" value={scenarioStatus} />;
    }
  };
  return (
    <Container>
      <Header>
        <TitleLabel label="Study Plan Name" value={studyPlanName} />
        <TitleLabel label="Description" value={studyPlanDescr} />
        {renderStatusAndActualAsOfDate()}
      </Header>
      <Title>Summary of the Overall Parameters</Title>
      <Content>
        <Label value={formattedCroProjectStartDateMillis}>CRO Start Date</Label>
        <Label value={screeningPeriodDuration}>
          Screening Period per Patient (days)
        </Label>
        <Label value={formattedFinalProtocolStartDateMillis}>
          Protocol Final Date
        </Label>
        <Label value={treatmentDuration}>
          Treatment Period per Patient (days)
        </Label>
        <div></div>
        <Label value={followUpDuration}>
          Follow-up Period per Patient (days)
        </Label>
      </Content>
    </Container>
  );
};

export default SummaryOfOverallParameters;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
`;

const Header = styled.div`
  display: grid;
  grid-gap: 0.5em;
  margin-bottom: 1em;
`;
