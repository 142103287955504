import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import BottomControls from "./bottom.controls";
import CountryPanel from "./country.panel";
import CountryTabs from "./country.tabs";
import Divider from "@material-ui/core/Divider";
import OverallStudyInputs from "./overall.study.inputs";
import PrimaryDetails from "./primary.details";
import SequentialCohortSetupTable from "./sequential.cohort.table";
import Stepper from "../../common/stepper";
import TopControls from "./top.controls";
import styled from "styled-components";
import { useStudyPlan } from "../../../app/contexts/study.context";
import { useUser } from "../../../app/contexts/user.context";

const Review = ({ values, errors, setValues }) => {
  const { id, planId } = useParams();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const { studyPlan } = useStudyPlan();
  const { countries, studyPlanId, cohortType, studyPlanTypeDetail } = studyPlan;
  const { setURLStudyId } = useUser();
  const sequentialCohort = useMemo(
    () => cohortType === 2 && studyPlanTypeDetail === "Cohort (D)",
    [cohortType, studyPlanTypeDetail]
  );

  useEffect(() => {
    if (studyPlan) setValues({ ...values, updated: new Date().getTime() });
    // eslint-disable-next-line
  }, [studyPlan]);

  useEffect(() => {
    setURLStudyId(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // trigger formic form update so that we can validate fields
    setValues({ ...values, updated: new Date().getTime() });
    if (!studyPlanId) {
      history.push(`/ctpo/study-plans/${id}/${planId}`);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (tab) => {
    setValue(tab);
  };
  const sorted_countries = countries?.sort((a, b) =>
    a.countryCodeISO3.localeCompare(b.countryCodeISO3)
  );

  return (
    <Container>
      <TopControls errors={errors} />
      <Stepper />
      <PrimaryDetails />
      <Divider />
      <OverallStudyInputs />
      <Divider id="country-tabs" />
      <CountryTabs value={value} onChange={(event, tab) => handleChange(tab)} />
      {sorted_countries?.map((country, index) => (
        <CountryPanel
          country={country}
          id={index}
          key={country.countryCodeISO3}
          value={value}
        />
      ))}
      {sequentialCohort && (
        <>
          <Divider /> <SequentialCohortSetupTable />
        </>
      )}
      <BottomControls errors={errors} />
    </Container>
  );
};

export default Review;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
