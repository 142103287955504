import React, { Fragment, useEffect, useState } from "react";

import Controls from "./controls";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "./table.head";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "./table.row";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useStudyPlan } from "../../app/contexts/study.context";
import { useUser } from "../../app/contexts/user.context";

const ROWS_PER_PAGE_KEY = "ctpo_study_plan_table_rows_per_page";
const defaultRowsPerPage = () =>
  localStorage.getItem(ROWS_PER_PAGE_KEY)?.toString() || 5;

const StudyTable = () => {
  const { study } = useStudyPlan();
  const [filter, setFilter] = useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [page, setPage] = React.useState(0);

  const classes = useStyles();
  const { setURLStudyId } = useUser();
  const { id } = useParams();

  useEffect(() => {
    setURLStudyId(id ?? 3712);
    // eslint-disable-next-line
  }, []);

  /**
   * Searching
   */
  const plans = study.studyPlans.filter((plan) =>
    plan.studyPlanName.toLowerCase().includes(filter.toLocaleLowerCase())
  );

  /**
   *  Sorting Fuctions
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  /**
   * Pagination
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    const rows = parseInt(value);

    setRowsPerPage(rows);
    setPage(0);

    localStorage.setItem(ROWS_PER_PAGE_KEY, rows);
  };

  return (
    <Fragment>
      <Controls
        search={filter}
        onSearch={(value) => setFilter(value)}
        clearSearch={() => setFilter("")}
        onNewPlan={(noop) => noop}
      />
      <Paper>
        <TableContainer classes={classes}>
          <Table size="medium" aria-label="study table">
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(plans, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.studyPlanId}
                    {...row}
                    studyPlans={study?.studyPlans || []}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          count={plans.length || 0}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  );
};

export default StudyTable;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
  },
}));
