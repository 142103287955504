import React from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

import { SubPanel } from "../../../../../app/components";
import Label from "../label";

const ScenarioA = () => {
  const { studyPlan } = useStudyPlan();

  const {
    enrollmentRate,
    screenFailRate,
    dropOutRate,
    screeningPeriodDuration,
    treatmentDuration,
    followUpDuration,
    parameters,
    milestones,
  } = studyPlan;
  const [sites_to_activate, subjects_to_screen] = parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150
  );

  return (
    <Container>
      <Content>
        <Column>
          <Label value={enrollmentRate ?? "- - "}>
            Enrollment Rate (p/s/m)
          </Label>
          <Label value={sites_to_activate.lowerBound}>
            {sites_to_activate.parameterDescr}
          </Label>
          <Label value={subjects_to_screen.lowerBound}>
            {subjects_to_screen.parameterDescr}
          </Label>
          <SubPanel>
            <Label value={screenFailRate ?? "- - "}>
              Screen Fail Rate (in decimals)
            </Label>
            <Label value={number_patients_enrolled.lowerBound ?? "- - "}>
              {number_patients_enrolled.milestoneDescr}
            </Label>
          </SubPanel>
          <Label value={number_patients_follow_up.lowerBound ?? "- - "}>
            {number_patients_follow_up.milestoneDescr}
          </Label>
        </Column>
        <Column>
          <Label value={screeningPeriodDuration ?? "- - "}>
            Screening Period Per Patient (days)
          </Label>
          <Label value={treatmentDuration ?? "- - "}>
            Treatment Period Per Patient (days)
          </Label>
          <Label value={followUpDuration ?? "- - "}>
            Follow-Up Period Per Patient (days)
          </Label>
          <SubPanel>
            <Label value={dropOutRate ?? "- - "}>
              Drop Out Rate (in decimals)
            </Label>
            <Label value={number_patients_treatment.lowerBound ?? "- - "}>
              {number_patients_treatment.milestoneDescr}
            </Label>
          </SubPanel>
        </Column>
      </Content>
    </Container>
  );
};

export default ScenarioA;

const Container = styled.div``;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const Column = styled.div`
  display: grid;
  grid-gap: 1em;
  height: min-content;
`;
