import React from "react";
import styled from "styled-components";
import {
  SpecialTextField,
  Tooltip,
  SubPanel,
} from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const ScenarioA = ({ values, errors, touched, setValues }) => {
  const { studyPlan, updatePlan } = useStudyPlan();

  const {
    enrollmentRate,
    screenFailRate,
    dropOutRate,
    screeningPeriodDuration,
    treatmentDuration,
    followUpDuration,
    parameters,
    milestones,
  } = studyPlan;
  const [sites_to_activate, subjects_to_screen] = studyPlan.parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150
  );

  const handleChangeParam = (param, key, value) => {
    const index = parameters.map((param) => param.parameterName).indexOf(key);
    const new_params = [...parameters];

    new_params[index][param] = value;
    updatePlan("parameters", new_params);
  };

  const handleChangeMilestones = (param, key, value) => {
    const index = milestones
      .map((milestone) => milestone.milestoneName)
      .indexOf(key);
    const new_milestones = [...milestones];
    new_milestones[index][param] = value;
    updatePlan("milestones", new_milestones);
  };

  return (
    <Container>
      <SideBySideCols>
        <SpecialTextField
          label="Enrollment Rate (p/s/m)"
          value={enrollmentRate}
          onChange={(value) => {
            updatePlan("enrollmentRate", value);
          }}
          inputProps={{ step: 0.01 }}
          min={0.01}
          endadornment={
            <Tooltip info="Only complete if you are expecting the same enrollment rate across all countries (if not, skip here and complete at the country level)." />
          }
        />
        <div style={{ display: "flex", gap: "1em" }}>
          <SpecialTextField
            label={sites_to_activate.parameterDescr}
            value={sites_to_activate.lowerBound}
            onChange={(value) => {
              handleChangeParam(
                "lowerBound",
                sites_to_activate.parameterName,
                value
              );
              // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }}
            required
            key={sites_to_activate.parameterName + 2}
            endadornment={
              <Tooltip info={PARAMETER_INFO[sites_to_activate.parameterName]} />
            }
          />
        </div>
        <div style={{ display: "flex", gap: "1em" }}>
          <SpecialTextField
            label={subjects_to_screen.parameterDescr}
            value={subjects_to_screen.lowerBound}
            onChange={(value) => {
              handleChangeParam(
                "lowerBound",
                subjects_to_screen.parameterName,
                value
              );
              // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }}
            required
            key={subjects_to_screen.parameterName + 2}
            endadornment={
              <Tooltip
                info={PARAMETER_INFO[subjects_to_screen.parameterName]}
              />
            }
          />
        </div>
        <SpecialTextField
          label="Screening Period Per Patient (days)"
          value={screeningPeriodDuration}
          onChange={(value) => updatePlan("screeningPeriodDuration", value)}
          required
        />
        <SpecialTextField
          label="Treatment Period Per Patient (days)"
          value={treatmentDuration}
          onChange={(value) => updatePlan("treatmentDuration", value)}
          required
        />
        <SpecialTextField
          label="Follow-Up Period Per Patient (days)"
          value={followUpDuration}
          onChange={(value) => updatePlan("followUpDuration", value)}
          endadornment={<Tooltip info="If applicable to the protocol." />}
        />
      </SideBySideCols>
      <SideBySideRows>
        <SubPanel>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SpecialTextField
              label="Screen Fail Rate (in decimals)"
              value={screenFailRate}
              disabled={typeof number_patients_enrolled.lowerBound === "number"}
              onChange={(value) => updatePlan("screenFailRate", value)}
              inputProps={{ step: 0.01 }}
              min={0.01}
            />
            <Tooltip info="Either the Screen Fail Rate OR the Number of Patients Enrolled must be completed here. If a certain number of enrolled patients is required in a specific country, also complete this at the country level." />
          </div>
          <SpecialTextField
            label="Number of Patients Enrolled"
            value={number_patients_enrolled.lowerBound}
            disabled={typeof screenFailRate === "number"}
            onChange={(value) => {
              handleChangeMilestones(
                "lowerBound",
                number_patients_enrolled.milestoneName,
                value
              ); // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }}
            key={number_patients_enrolled.milestoneName}
          />
        </SubPanel>
        <SubPanel>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SpecialTextField
              label="Drop Out Rate (in decimals)"
              value={dropOutRate}
              disabled={
                typeof number_patients_treatment.lowerBound === "number"
              }
              onChange={(value) => updatePlan("dropOutRate", value)}
              inputProps={{ step: 0.01 }}
              min={0.01}
            />
            <Tooltip info="Either the Drop Out Rate OR the Number of Patients Completing Treatment must be completed here. If a certain number of completed patients is required in a specific country, also complete this at the country level." />
          </div>
          <SpecialTextField
            key={number_patients_treatment.milestoneId}
            label={number_patients_treatment.milestoneLabel}
            value={number_patients_treatment.lowerBound}
            onChange={(value) => {
              handleChangeMilestones(
                "lowerBound",
                number_patients_treatment.milestoneName,
                value
              ); // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }}
            disabled={typeof dropOutRate === "number"}
          />
        </SubPanel>
      </SideBySideRows>
      <SideBySideRows>
        <SpecialTextField
          key={number_patients_follow_up.milestoneId}
          label={number_patients_follow_up.milestoneLabel}
          value={number_patients_follow_up.lowerBound}
          onChange={(value) => {
            handleChangeMilestones(
              "lowerBound",
              number_patients_follow_up.milestoneName,
              value
            );
            // trigger formic form update so that we can validate fields
            setValues({ ...values, updated: new Date().getTime() });
          }}
          endadornment={
            <Tooltip
              info={MILESTONE_INFO[number_patients_follow_up.milestoneName]}
            />
          }
        />
      </SideBySideRows>
    </Container>
  );
};

export default ScenarioA;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const PARAMETER_INFO = {
  SITES_TO_ACTIVATE:
    "Complete with the total number of sites across all countries.",
  SUBJECTS_TO_SCREEN:
    "Include the total number of subjects to screen [Formula = Number of Enrolled Patients/(1 ─ Screen Failure Rate in decimals)]. The simulation will optimize the distribution of patients across the countries. If a specific distribution is required in a country, enter that value at the country level as well.",
};
const MILESTONE_INFO = {
  NUM_SUBJECTS_ENROLLED:
    "If a certain number of enrolled patients is required in a specific country, also complete this at the country level. Either the Screen Fail Rate OR the Number of Patients Enrolled must be completed here or at the country level.",
  NUM_SUBJECTS_COMPLETED: "If applicable to the protocol.",
};

const SideBySideRows = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const SideBySideCols = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1em;
  grid-auto-flow: column;
`;
