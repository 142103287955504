import { InputAdornment, TextField } from "@material-ui/core";
import React, { useState } from "react";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";

export default function CustomDatePicker({
  error,
  value,
  onChange,
  style,
  label,
  required,
  small,
  placeholderText,
  ...props
}) {
  let [tempValue, setTempValue] = useState(value ? new Date(value) : null);
  const CustomDateInput = ({ value, onChange, onClick }) => (
    <TextField
      placeholder={placeholderText || "DD/MMM/YYYY"}
      style={{ width: "100%" }}
      value={value}
      // onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      // InputLabelProps={{ classes }}
      InputProps={{
        endAdornment: (
          <InputAdornment style={{ cursor: "pointer" }} position="start">
            {" "}
            <CalendarTodayIcon />
          </InputAdornment>
        ),
      }}
      error={error}
      onClick={onClick}
      label={label}
      required={required}
      size={small ? "small" : "medium"}
    />
  );

  return (
    <div
      style={{
        ...style,
        overflow: "inherit",
      }}
    >
      <DatePicker
        {...props}
        selected={tempValue}
        onChange={(value) => {
          setTempValue(value);
          onChange(new Date(value));
        }}
        customInput={<CustomDateInput />}
      />
    </div>
  );
}
