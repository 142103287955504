import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MuiTableHead from "@material-ui/core/TableHead";
import { makeStyles } from "@material-ui/core/styles";

const TableHead = ({ orderBy, order, onRequestSort }) => {
  const classes = useStyles();
  const otherClasses = useOtherStyles();
  const headCells = [
    {
      id: "studyPlanName",
      numeric: false,
      disablePadding: false,
      label: "Study Plan Name",
    },
    {
      id: "studyPlanDescr",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "scenarioStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "studyPlanType",
      numeric: true,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "insertBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "insertDate",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    { id: "controls", numeric: false, disablePadding: false, label: "" },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead classes={classes}>
      <TableRow classes={classes}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={otherClasses.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e7e8",
    borderRadius: "10px",
  },
}));

const useOtherStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
