import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import API from "../../../../../../app/utils/api";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Modal } from "../../../../../../app/components";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useSnackbar } from "../../../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";

const AddCountryModal = ({ open = false, onClose }) => {
  const { id, planId } = useParams();
  const history = useHistory();
  const [siteGroups, setSiteGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedEffort, setSelectedEffort] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState(null);
  const { showSnackbar } = useSnackbar();
  const { resetStudyPlanWithCountry, studyPlan } = useStudyPlan();
  useEffect(() => {
    API.getSiteGroups()
      .then((res) => {
        setSiteGroups(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleSave = () => {
    const canAdd = studyPlan.countries
      .map((country) => country.siteGroups[0].siteGroupId)
      .indexOf(selectedCountry.siteGroupId);

    if (canAdd === -1) {
      const body = {
        studyPlanId: studyPlan.studyPlanId,
        croProjectStartDateMillis: studyPlan.croProjectStartDateMillis,
        finalProtocolStartDateMillis: studyPlan.finalProtocolStartDateMillis,
        overallEnrollmentDuration: studyPlan.overallEnrollmentDuration,
        siteGroupId: selectedCountry.siteGroupId,
        timeSiteIdSize: selectedEffort,
        timeCountryStartUpSize: selectedTimeframe,
        screeningPeriodDuration: studyPlan.screeningPeriodDuration,
        treatmentDuration: studyPlan.treatmentDuration,
        followUpDuration: studyPlan.followUpDuration,
      };
      setLoading(true);
      API.postSiteGroup(body)
        .then((res) => {
          setLoading(false);
          // check the index of the country in the array res.data.countries
          resetStudyPlanWithCountry(res.data);
          const newCountryIndex = res.data.countries.findIndex(
            (country) =>
              country.siteGroups[0].siteGroupId === selectedCountry.siteGroupId
          );
          // update the query string on the route to the new country
          history.push(
            `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups?country=${newCountryIndex}`
          );
          onClose();
          setSelectedCountry(null);
          setSelectedEffort(null);
          setSelectedTimeframe(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      showSnackbar("Country already added.", "error");
    }
  };

  const handleSelectCountry = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedEffort(null);
    setSelectedTimeframe(null);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add country"
      aria-describedby="add a country"
      title="Add Country"
    >
      <Container>
        <FormControl variant="outlined">
          <InputLabel id="country-label">Select Country</InputLabel>
          <Select
            labelId="county-label"
            id="select-country"
            value={selectedCountry}
            onChange={(event) => handleSelectCountry(event)}
            label="Select Country"
          >
            {siteGroups.map((siteGroup) => (
              <MenuItem value={siteGroup} key={siteGroup.siteGroupId}>
                {siteGroup.siteGroupName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider />
        <Typography>
          The below should be confirmed by the appropriate functional area.
        </Typography>
        <FormControl variant="outlined">
          <InputLabel id="site-id-label">Select Site ID Effort</InputLabel>
          <Select
            labelId="site-id-label"
            id="select-site-id"
            value={selectedEffort}
            onChange={(event) => setSelectedEffort(event.target.value)}
            label="Select Site ID Effort"
          >
            <MenuItem value="Small">
              Small{" "}
              {selectedCountry &&
                `(${selectedCountry.timeSiteIdSmallDays}; 1-2 sites)`}
            </MenuItem>
            <MenuItem value="Medium">
              Medium{" "}
              {selectedCountry &&
                `(${selectedCountry.timeSiteIdMediumDays}; 3-10 sites)`}
            </MenuItem>
            <MenuItem value="Large">
              Large{" "}
              {selectedCountry &&
                `(${selectedCountry.timeSiteIdLargeDays}; 10+ sites)`}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="country-startup-label">
            Select Country Start-Up Time Frame
          </InputLabel>
          <Select
            labelId="country-startup-label"
            id="select-country-startup"
            value={selectedTimeframe}
            onChange={(event) => setSelectedTimeframe(event.target.value)}
            label="Select Country Start-Up Time Frame"
          >
            <MenuItem value="Super Short">
              Super Short{" "}
              {selectedCountry &&
                `(${selectedCountry.timeCountryStartUpSuperShortDays}; ${(
                  selectedCountry.timeCountryStartUpSuperShortDays / 30.42
                ).toFixed(2)} months)`}
            </MenuItem>
            <MenuItem value="Short">
              Short{" "}
              {selectedCountry &&
                `(${selectedCountry.timeCountryStartUpShortDays}; ${(
                  selectedCountry.timeCountryStartUpShortDays / 30.42
                ).toFixed(2)} months)`}
            </MenuItem>
            <MenuItem value="Medium">
              Medium{" "}
              {selectedCountry &&
                `(${selectedCountry.timeCountryStartUpMediumDays}; ${(
                  selectedCountry.timeCountryStartUpMediumDays / 30.42
                ).toFixed(2)} months)`}
            </MenuItem>
            <MenuItem value="Long">
              Long{" "}
              {selectedCountry &&
                `(${selectedCountry.timeCountryStartUpLongDays}; ${(
                  selectedCountry.timeCountryStartUpLongDays / 30.42
                ).toFixed(2)} months)`}
            </MenuItem>
          </Select>
        </FormControl>

        <ButtonContainer>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              !selectedCountry ||
              !selectedEffort ||
              !selectedTimeframe ||
              loading
            }
          >
            {loading ? <CircularProgress size="1em" /> : "Save"}
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default AddCountryModal;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
