import React from "react";
import styled from "styled-components";
import { SubPanel } from "../../../../../../app/components";
import Label from "../label";

const ScenarioA = ({ siteGroup }) => {
  const {
    expectedScreenFailureRate,
    expectedTreatmentDropoutRate,
    parameters,
    milestones,
    timeCountryStartUpSize,
    timeSiteIdSize,
  } = siteGroup;

  const [sites_to_activate, subjects_to_screen] = parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150
  );

  return (
    <Container>
      <Column>
        <Label value={timeSiteIdSize ?? "- - "} variant="secondary">
          Site ID Effort {timeSiteIdSize && " - "}
        </Label>
        <Label value={sites_to_activate.lowerBound ?? "- - "}>
          {sites_to_activate.parameterDescr}
        </Label>
        <SubPanel>
          <Label value={expectedScreenFailureRate ?? "- - "}>
            Screen Fail Rate (in decimals)
          </Label>
          <Label value={number_patients_enrolled.lowerBound ?? "- - "}>
            {number_patients_enrolled.milestoneDescr}
          </Label>
        </SubPanel>
        <Label value={number_patients_follow_up.lowerBound ?? "- - "}>
          {number_patients_follow_up.milestoneDescr}
        </Label>
      </Column>
      <Column>
        <Label value={timeCountryStartUpSize ?? "- - "} variant="secondary">
          Country Start-Up Time Frame {timeCountryStartUpSize && " - "}
        </Label>
        <Label value={subjects_to_screen.lowerBound ?? "- - "}>
          {subjects_to_screen.parameterDescr}
        </Label>
        <SubPanel>
          <Label value={expectedTreatmentDropoutRate ?? "- - "}>
            Drop Out Rate (in decimals)
          </Label>
          <Label value={number_patients_treatment.lowerBound ?? "- - "}>
            {number_patients_treatment.milestoneDescr}
          </Label>
        </SubPanel>
      </Column>
    </Container>
  );
};

export default ScenarioA;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const Column = styled.div`
  display: grid;
  grid-gap: 1em;
  height: min-content;
`;
