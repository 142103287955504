import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DragIndicator, RemoveCircle } from "@material-ui/icons";
import {
  checkNumberLength,
  isValidNumberStringValues,
} from "../../utils/helpers";

import { Draggable } from "react-beautiful-dnd";
import { FIELD_TYPES } from "../constants";
import React from "react";
import VisitInputField from "./visit.input.field";
import styled from "styled-components";

// import CustomInput from "../../../../app/components/custom.input";

const VISIT_VARIANCE_TYPE_OPTIONS = [
  { value: "", label: "None" },
  { value: "+", label: "+" },
  { value: "-", label: "-" },
  { value: "+/-", label: "+/-" },
];
const VISIT_CATEGORY_TYPES = [
  "Screening",
  "Treatment",
  "Follow Up",
  "Close Out",
  "Unscheduled",
];

const VISIT_TYPE_MAX_WIDTH = {
  [FIELD_TYPES.VISIT]: "100%",
  [FIELD_TYPES.COST]: "100%",
  [FIELD_TYPES.MEDICAL]: "9rem",
  [FIELD_TYPES.DATA_MANAGEMENT]: "8rem",
};

/*
  This component is responsible for rendering a row in the table of visits for a schedule of events.
  It uses the material ui table cell component.
  It is responsible for rendering the cells for each column in the table.
  It is also responsible for rendering the drag handle and remove button.
  It is also responsible for rendering the input fields for each cell.
  It is also responsible for handling the change events for each input field.
  It is also responsible for handling the remove row event.
  It is also responsible for handling the drag and drop events.
*/
export default function TableRow({
  sv,
  removeRow,
  onVisitsChange,
  removeColWidth,
  value,
  index,
  fieldType,
  columnCount,
}) {
  const commentTypeKey = React.useMemo(() => {
    if (fieldType === FIELD_TYPES.VISIT) return "commentsVisit";
    if (fieldType === FIELD_TYPES.COST) return "commentsCost";
    if (fieldType === FIELD_TYPES.MEDICAL) return "commentsClinicalStrategy";
    if (fieldType === FIELD_TYPES.DATA_MANAGEMENT)
      return "commentsDataManagement";
    return "";
  }, [fieldType]);

  const renderRow = (dndProps) => {
    const { dragHandleProps, ...remainingDnDProps } = dndProps;
    return (
      <Row {...remainingDnDProps}>
        <Cell
          style={{ padding: "0px" }}
          width={`${(3.5 / columnCount) * (100 - removeColWidth)}%`}
        >
          {value === 0 ? (
            <>
              <DragHandleContainer {...dragHandleProps}>
                <DragIndicator />
              </DragHandleContainer>
              <div
                style={{
                  padding: "5px",
                  maxWidth: VISIT_TYPE_MAX_WIDTH[fieldType],
                  flex: 1,
                }}
              >
                <FormControl
                  style={{ width: "100%" }}
                  size="small"
                  variant="outlined"
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    select
                    id="visit-category"
                    value={sv.visitCategory}
                    onChange={(e) => {
                      onVisitsChange(
                        "visitCategory",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key,
                        "string"
                      );
                    }}
                    size="small"
                  >
                    {VISIT_CATEGORY_TYPES.map((vc) => (
                      <MenuItem value={vc}>{vc}</MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            </>
          ) : (
            <Typography variant="body2">{sv.visitCategory}</Typography>
          )}
        </Cell>
        <Cell width={`${(6 / columnCount) * (100 - removeColWidth)}%`}>
          {value === 0 ? (
            <VisitInputField onVisitsChange={onVisitsChange} sv={sv} />
          ) : (
            <Typography variant="body2">{sv.visitName}</Typography>
          )}
        </Cell>
        <Cell
          width={`${
            ((fieldType === FIELD_TYPES.VISIT ? 3 : 2) / columnCount) *
            (100 - removeColWidth)
          }%`}
        >
          {value === 0 ? (
            <TextField
              style={{ width: "5em" }}
              value={sv.visitStudyDay}
              onChange={(e) => {
                if (
                  !isValidNumberStringValues(e.target.value) ||
                  !checkNumberLength(e.target.value, 4, 0, 9999)
                )
                  return;
                onVisitsChange(
                  "visitStudyDay",
                  e.target.value,
                  sv.scheduleOfEventsVisitId || sv.key
                );
              }}
              variant="outlined"
              size="small"
              disabled={sv.visitCategory === "Unscheduled"}
            />
          ) : (
            <Typography variant="body2">{sv.visitStudyDay}</Typography>
          )}
        </Cell>

        <Cell width={`${(1.5 / columnCount) * (100 - removeColWidth)}%`}>
          {value === 0 ? (
            <FormControl
              style={{ width: "100%" }}
              size="small"
              variant="outlined"
            >
              <Select
                id="visit-variance-type"
                value={sv.visitVarianceType}
                onChange={(e) => {
                  onVisitsChange(
                    "visitVarianceType",
                    e.target.value,
                    sv.scheduleOfEventsVisitId || sv.key,
                    "string"
                  );
                }}
              >
                {VISIT_VARIANCE_TYPE_OPTIONS.map((vvt) => (
                  <MenuItem value={vvt.value}>{vvt.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography variant="body2">{sv.visitVarianceType}</Typography>
          )}
        </Cell>
        <Cell
          width={`${
            ((fieldType === FIELD_TYPES.VISIT ? 2.5 : 1.5) / columnCount) *
            (100 - removeColWidth)
          }%`}
        >
          {value === 0 ? (
            <TextField
              style={{ width: "5em" }}
              value={sv.visitVariance}
              onChange={(e) => {
                if (
                  !isValidNumberStringValues(e.target.value) ||
                  !checkNumberLength(e.target.value, 5, 0, 9999)
                )
                  return;
                onVisitsChange(
                  "visitVariance",
                  e.target.value,
                  sv.scheduleOfEventsVisitId || sv.key
                );
              }}
              variant="outlined"
              size="small"
            />
          ) : (
            <Typography variant="body2">{sv.visitVariance}</Typography>
          )}
        </Cell>

        {(fieldType === FIELD_TYPES.COST || value !== 0) && (
          <>
            <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
              <TextField
                style={{ width: "5em" }}
                value={sv.visitOverheadPct ?? ""}
                onChange={(e) => {
                  if (
                    !isValidNumberStringValues(e.target.value) ||
                    !checkNumberLength(e.target.value, 3, 0, 100)
                  )
                    return;
                  onVisitsChange(
                    "visitOverheadPct",
                    e.target.value,
                    sv.scheduleOfEventsVisitId || sv.key
                  );
                }}
                variant="outlined"
                size="small"
              />
            </Cell>
            <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
              <Typography variant="body2">{sv.visitCost}</Typography>
            </Cell>
          </>
        )}
        {value === 0 && (
          <>
            {/* Medical Informatics Fields */}
            {fieldType === FIELD_TYPES.MEDICAL && (
              <>
                <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.miEstSDRSDV ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "miEstSDRSDV",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <Checkbox
                    checked={sv.miSampleVisit}
                    onChange={(e) => {
                      onVisitsChange(
                        "miSampleVisit",
                        e.target.checked,
                        sv.scheduleOfEventsVisitId || sv.key,
                        "checkbox"
                      );
                    }}
                    name="detailsMonthly"
                  />
                </Cell>
                <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <Typography variant="body2">
                    {sv.miSuggestedOnSiteSDRSDV}
                  </Typography>
                </Cell>
                <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <Typography variant="body2">
                    {sv.miSuggestedCDAReview}
                  </Typography>
                </Cell>
              </>
            )}

            {/* Data Management Fields */}
            {fieldType === FIELD_TYPES.DATA_MANAGEMENT && (
              <>
                <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmUniqueCRFs ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "dmUniqueCRFs",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmNonUniqueCRFs ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "dmNonUniqueCRFs",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                {/* <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmIntegratedEpro ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "dmIntegratedEpro",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmPaperEpro ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "dmPaperEpro",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmCentralLab ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "dmCentralLab",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                <Cell width={`${(2 / columnCount) * (100 - removeColWidth)}%`}>
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmLocalLab ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "dmLocalLab",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell> */}
              </>
            )}
          </>
        )}
        {value === 0 && (
          <Cell width={`${(2.5 / columnCount) * (100 - removeColWidth)}%`}>
            <TextField
              key={commentTypeKey}
              value={sv[commentTypeKey] || ""}
              onChange={(e) => {
                onVisitsChange(
                  commentTypeKey,
                  e.target.value,
                  sv.scheduleOfEventsVisitId || sv.key
                );
              }}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 250,
              }}
            />
          </Cell>
        )}
        {value === 0 ? (
          <Cell width={`${removeColWidth}%`}>
            <RemoveButtonContainer
              onClick={() => removeRow(sv.scheduleOfEventsVisitId || sv.key)}
            >
              <RemoveCircle style={{ fontSize: "1rem" }} />
            </RemoveButtonContainer>
          </Cell>
        ) : (
          ""
        )}
      </Row>
    );
  };

  return (
    <>
      {value === 0 ? (
        <Draggable
          draggableId={(sv.key || sv.scheduleOfEventsVisitId).toString()}
          index={index}
        >
          {(provided) =>
            renderRow({
              dragHandleProps: provided.dragHandleProps,
              ...provided.draggableProps,
              ref: provided.innerRef,
            })
          }
        </Draggable>
      ) : (
        renderRow({})
      )}
    </>
  );
}

const DragHandleContainer = styled.div`
  color: #555;
  width: 2.5rem;
`;

const RemoveButtonContainer = styled.div`
  color: red;
  cursor: pointer;
  background: white;
  padding: 1px;
  border-radius: 50%;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 50px;
  border: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid #ccc" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid #ccc" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid #ccc" : "")};
`;

