import React, { useEffect } from "react";
import styled from "styled-components";
import { AccordionTable } from "../../app/components";
import { useStudyPlan } from "../../app/contexts/study.context";
import { COLUMN_HEADERS } from "./table.utils";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ToolTip from "../../app/components/tooltip";
import Controls from "../controls";
import { useUser } from "../../app/contexts/user.context";
import { useParams } from "react-router-dom";

const StudyActualsTable = () => {
  const {
    fetchActualsTable,
    studyActualsData,
    actualsLoading,
    lActualsLoading,
  } = useStudyPlan();
  const { actualsData = [], loadActualsData = [] } = studyActualsData;
  const { id } = useParams();
  const { setURLStudyId } = useUser();

  useEffect(() => {
    setURLStudyId(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchActualsTable();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Controls />
      {lActualsLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="white" size="2em" />
        </div>
      )}
      {!lActualsLoading && actualsData.length > 0 && (
        <AccordionTable
          summary="Study Actuals"
          columns={COLUMN_HEADERS}
          defaultExpanded
          data={loadActualsData.length > 0 ? loadActualsData : actualsData}
          renderRow={(item) => (
            <>
              <TableCell component="th" scope="row">
                <strong>{item.rowtype.replace(/_/g, " ")}</strong>
              </TableCell>
              <TableCell align="center">
                {item.planned ?? "DD/MMM/YYYY"}
              </TableCell>
              <TableCell align="center">
                {item.actuals ?? "DD/MMM/YYYY"}
              </TableCell>
              <TableCell align="center">
                {item.planned_v_Actual_Indicators ?? "DD/MMM/YYYY"}
              </TableCell>
              <TableCell align="center">
                {item.toolTips && item.rowtype !== "Enrollment_Start" ? (
                  <ToolTip info={item.toolTips} />
                ) : (
                  <div></div>
                )}
              </TableCell>
            </>
          )}
        />
      )}
      {!(actualsData.length > 0) && !actualsLoading && (
        <Typography
          style={{ align: "center", margin: "5em", fontWeight: "600" }}
        >
          A Study Plan with a status of 'Baseline Plan' is not available.
          <br></br> Please create a Study Plan with a status of 'Baseline Plan'
          before trying to view the Study Actual data.
        </Typography>
      )}
    </Container>
  );
};
export default StudyActualsTable;

const Container = styled.div`
  margin-bottom: 2em;
`;
