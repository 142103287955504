import React, { useState } from "react";
import SummaryTable from "./summary.table";
import CountryStartUpMilestones from "./country.start.up.milestones";
import StudyAndEnrollmentMilestones from "./study.and.enrollment.milestones";
import TimelineSummaries from "./timeline.summaries";
import CountryStartUpDurations from "./country.start.up.durations";
import DetailsTable from "./details.table";
import TableControls from "./table.controls";
import { useStudyPlan } from "../../../../app/contexts/study.context";

const Tables = () => {
  const [view, setView] = useState("monthly");
  const { studyPlan } = useStudyPlan();
  const { cohortType } = studyPlan;
  const studyType = cohortType !== 0 ? "cohort" : "results";
  return (
    <>
      <DetailsTable
        view={view}
        setView={setView}
        extraControls={<TableControls type={studyType} />}
      />
      {studyType === "cohort" && (
        <DetailsTable view={view} setView={setView} type="cohort" />
      )}
      <SummaryTable />
      {studyType === "cohort" && <SummaryTable type="cohort" />}
      <CountryStartUpMilestones />
      <StudyAndEnrollmentMilestones />
      <TimelineSummaries />
      <CountryStartUpDurations />
    </>
  );
};

export default Tables;
