import React from "react";
import Typography from "@material-ui/core/Typography";

const Label = ({ children, value = "- -" }) => {
  return (
    <Typography>
      <strong>{children} - </strong>
      {value}
    </Typography>
  );
};

export default Label;
