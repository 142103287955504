import React, { useEffect } from "react";
import styled from "styled-components";
import Controls from "../common/controls";
import Stepper from "../../common/stepper";
import PrimaryDetails from "./primary.details";
import OverallStudyInputs from "./overall.study.inputs";
import BottomControls from "./bottom.controls";
import { useParams } from "react-router-dom";
import { useUser } from "../../../app/contexts/user.context";

const OverallParameters = ({ values, errors, setValues }) => {
  const { id } = useParams();
  const { setURLStudyId } = useUser();

  useEffect(() => {
    setURLStudyId(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // trigger formic form update so that we can validate fields
    setValues({ ...values, updated: new Date().getTime() });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Controls errors={errors} />
      <Stepper errors={errors} />
      <PrimaryDetails values={values} errors={errors} setValues={setValues} />
      <OverallStudyInputs
        values={values}
        errors={errors}
        setValues={setValues}
      />
      <BottomControls errors={errors} />
    </Container>
  );
};

export default OverallParameters;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
