import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { SectionHeader, Tooltip } from "../../../../app/components";
import SpecialRadioButton from "../../../../app/components/special.radiobutton";

export default function CountryEnrollRate({
  errors,
  touched,
  values,
  setValues,
}) {
  const renderCountryEnrollmentRate = () => {
    return values?.siteGroups?.map((siteGroup, index) => {
      return (
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="scenario"
            name="scenarios"
            value={siteGroup.enrollmentRateSelection}
            onChange={(e) => {
              if (!e.target.value) return;
              let siteGroupLocal = siteGroup;
              siteGroupLocal["enrollmentRateSelection"] = parseFloat(
                e.target.value
              );
              let siteGroups = values.siteGroups.map((sg, i) => {
                if (i === index) return siteGroupLocal;
                return sg;
              });
              setValues({ ...values, siteGroups });
            }}
          >
            <Row>
              <Cell flex={1}>
                <Typography style={{ textAlign: "left", width: "100%" }}>
                  {siteGroup.siteGroupName}
                </Typography>
              </Cell>
              <Cell flex={2}>
                {!(siteGroup?.enrollmentRatePlanned === null) && (
                  <FormControlLabel
                    style={{ minWidth: "5rem" }}
                    value={1}
                    control={<Radio color="primary" />}
                    label={siteGroup?.enrollmentRatePlanned}
                  />
                )}
              </Cell>
              <Cell flex={2}>
                {!(siteGroup?.enrollmentRateActual === null) && (
                  <FormControlLabel
                    style={{ minWidth: "5rem" }}
                    value={2}
                    control={<Radio color="primary" />}
                    label={siteGroup?.enrollmentRateActual}
                  />
                )}
              </Cell>
              <Cell flex={2}>
                <SpecialRadioButton
                  type="number"
                  currentRadioValue={3}
                  onChange={(value) => {
                    let siteGroupLocal = siteGroup;
                    siteGroupLocal["enrollmentRateOther"] = parseFloat(value);
                    let siteGroups = values.siteGroups.map((sg, i) => {
                      if (i === index) return siteGroupLocal;
                      return sg;
                    });
                    setValues({ ...values, siteGroups });
                  }}
                  value={siteGroup?.enrollmentRateOther}
                  error={
                    errors?.siteGroups?.length >= index &&
                    !!errors?.siteGroups[index]?.enrollmentRateOther
                  }
                />
              </Cell>
            </Row>
          </RadioGroup>
        </FormControl>
      );
    });
  };

  return (
    <Container>
      <SectionHeader
        hasStartAdornment={false}
        endAdornment={
          <Tooltip info="Select the enrollment rates you want to use in the re-model. You can chose the planned rate, the actual rate or input a new rate for each country." />
        }
      >
        Country - Enrollment Rate (p/s/m)
      </SectionHeader>

      <TableContainer>
        <Table>
          <Row>
            <Cell variant="header" flex={1}></Cell>
            <Cell variant="header" flex={2}>
              <Typography align="center">
                <strong>Planned</strong>
              </Typography>
            </Cell>
            <Cell variant="header" flex={2}>
              <Typography align="center">
                <strong>Actual</strong>
              </Typography>
            </Cell>
            <Cell variant="header" flex={2}>
              <Typography align="center">
                <strong>Other</strong>
              </Typography>
            </Cell>
          </Row>
          {renderCountryEnrollmentRate()}
        </Table>
      </TableContainer>
      {errors.countryEnrollment && (
        <ErrorMessageContainer className="MuiFormHelperText-root">
          {errors.countryEnrollment}
        </ErrorMessageContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-between;
  flex-grow: 1;
  border-bottom: 1px solid #ccc;
  height: 6em;
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  flex: ${(p) => p.flex || "1"};
  align-items: center;
  justify-content: ${(p) => p.alignText || "center"};
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 1em;
  padding-left: 3em;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ErrorMessageContainer = styled.div`
  color: #f44336 !important;
  margin: 3px 14px !important;
`;
