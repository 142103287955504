import { Button, LinearProgress, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import CloneStudyPlan from "./clone.study.plan";
import IconButton from "@material-ui/core/IconButton";
import { Modal } from "../../../app/components";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MuiTableRow from "@material-ui/core/TableRow";
import OptionsMenu from "./options.menu";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import styled from "styled-components";
import { useSnackbar } from "../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../app/contexts/study.context";

const TableRow = (props) => {
  const {
    studyPlanId,
    studyPlanName,
    insertBy,
    insertDate,
    scenarioStatus,
    simOptRunId,
    studyPlanDescr,
    studyPlans,
    studyPlanTypeDetail,
  } = props;
  const { fetchStudy, deleteStudy } = useStudyPlan();
  const classes = useStyles();
  const otherClasses = otherStyles();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [studyError, setStudyError] = useState(false);
  const [deletingStudy, setDeletingStudy] = useState(false);
  const { showSnackbar } = useSnackbar();

  const history = useHistory();
  const { id } = useParams();

  const deleteMessage = useMemo(() => {
    if (studyError)
      return "Enrollment Plan cannot be deleted. Please contact the Creator of the Enrollment Plan to delete and confirm the status is 'Draft'.";
    return `
    Are you sure you want to delete this Enrollment Plan?  This cannot be un-done and will remove all information related to this Enrollment Plan.
    `;
  }, [studyError]);

  const handleClick = (e) => {
    setAnchor(e.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickStudy = () => {
    history.push(`/ctpo/study-plans/${id}/${studyPlanId}/review`);
  };

  const redirect = async () => {
    await fetchStudy(id);
  };

  const deleteStudyHandler = async () => {
    try {
      setDeletingStudy(true);
      if (!["Draft", "Draft-Combo"].includes(scenarioStatus))
        throw new Error("Failed to Delete Enrollment Plan");
      const res = await deleteStudy(id, studyPlanId);
      if (res.data.length && res.data[0].returnCode === "200") {
        setDeletingStudy(false);
        setDeleteOpen(false);
        showSnackbar("Deleted successfully", "success");
        redirect();
      } else throw new Error("Failed to Delete Enrollment Plan");
    } catch (err) {
      console.log(err);
      setStudyError(true);
      setDeletingStudy(false);
      showSnackbar("Failed to Delete Enrollment Plan", "error");
    }
  };

  const closeDeleteModal = () => {
    setDeleteOpen(false);
    setStudyError(false);
  };

  return (
    <MuiTableRow>
      <TableCell
        align="left"
        className={otherClasses.link}
        onClick={handleClickStudy}
      >
        {studyPlanName}
      </TableCell>
      <TableCell align="left">
        <Typography>{studyPlanDescr}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>{scenarioStatus}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>{studyPlanTypeDetail}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>{insertBy}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography noWrap>
          {moment(insertDate).utc().format("DD/MMM/YYYY HH:mm A")}
        </Typography>
      </TableCell>
      <TableCell align="right" padding="checkbox">
        <IconButton onClick={handleClick}>
          <MoreVertIcon classes={classes} />
        </IconButton>
        <OptionsMenu
          open={open}
          anchor={anchor}
          studyPlanId={studyPlanId}
          simOptRunId={simOptRunId}
          scenarioStatus={scenarioStatus}
          studyPlanTypeDetail={studyPlanTypeDetail}
          onClone={() => setOpenModal(true)}
          onClose={() => setOpen(false)}
          onDelete={() => setDeleteOpen(true)}
        />
        {openModal && (
          <CloneStudyPlan
            key={`${studyPlanId}-${openModal}`}
            open={openModal}
            onClose={() => setOpenModal(false)}
            plan={{ studyPlanName, studyPlanId, studyPlans }}
          />
        )}
        {deleteOpen && (
          <Modal
            open={deleteOpen}
            onClose={closeDeleteModal}
            aria-labelledby="Delete Enrollment Plan"
            aria-describedby="Delete an Enrollment Plan"
            title="Delete Enrollment Plan"
          >
            <Container>
              {deletingStudy ? (
                <LinearProgress />
              ) : (
                <Message>{deleteMessage}</Message>
              )}
            </Container>
            <SideBySide>
              <Button
                variant="contained"
                autoFocus
                onClick={closeDeleteModal}
                disabled={deletingStudy}
              >
                {studyError ? "Close" : "Cancel"}
              </Button>
              {!studyError && (
                <Button
                  variant="contained"
                  disabled={deletingStudy}
                  onClick={deleteStudyHandler}
                  color="primary"
                >
                  DELETE
                </Button>
              )}
            </SideBySide>
          </Modal>
        )}
      </TableCell>
    </MuiTableRow>
  );
};

export default TableRow;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#757072",
  },
}));

const otherStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const Message = styled.p`
  text-align: center;
  padding-top: 0rem;
`;

const Container = styled.div`
  display: grid;
  padding: 1rem;
  grid-gap: 2em;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
