import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useMemo } from "react";

import AssessmentsTable from "../assessments.table";
import { FIELD_TYPES } from "../constants";
import InitialRiskAssessmentTable from "../initial.risk.assessment.table";
import VisitSampleStrategyTables from "../visit.sample.strategy.tables";
import VisitsInformationTable from "../soe.table";
import VisitsTable from "../visits.table";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

export default function Content({
  tabs,
  value,
  editOpen,
  setEditOpen,
  siteId,
  setSiteId,
  fieldType,
  setFieldType,
}) {
  const { SOECountries, loadingCountrySOE } = useScheduleEvents();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);

  // initial a boolean called showInitialRiskAssessment using the useMemo hook and the value of the fieldType
  const showInitialRiskAssessment = useMemo(() => {
    return fieldType === FIELD_TYPES.MEDICAL;
  }, [fieldType]);

  const showVisitSampleStrategy = useMemo(() => {
    return fieldType === FIELD_TYPES.MEDICAL;
  }, [fieldType]);

  const renderSitesDropdown = (tab) => {
    const sites = SOECountries.filter(
      (country) => country.siteGroupId === tab.siteGroupId
    ).sort((a, b) => a.siteId - b.siteId);
    if (value === 0) return null;
    return (
      <div>
        <FormControl variant="outlined" style={{ width: "220px" }}>
          <InputLabel id="siteId">Site List</InputLabel>
          <Select
            labelId="siteId"
            id="siteId"
            value={siteId}
            onChange={(e) => {
              setSiteId(parseInt(e.target.value));
              queryParams.set("siteId", e.target.value);
              history.push({
                search: queryParams.toString(),
              });
            }}
            label="Site List"
          >
            {sites.map((site) => (
              <MenuItem key={site.siteId} value={site.siteId}>
                {site.dropDownListLabel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return tabs?.map(
    (tab) =>
      tabs[value]?.siteGroupId === tab?.siteGroupId && (
        <TabContainer
          key={tab?.countryCodeISO3}
          value={value}
          tab={tab?.countryCodeISO3}
        >
          {tab.siteGroupId !== 0 &&
            !loadingCountrySOE &&
            renderSitesDropdown(tab)}
          <VisitsTable
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            value={value}
            country={tab}
            fieldType={fieldType}
            setFieldType={setFieldType}
            siteId={siteId}
          />
          <AssessmentsTable
            fieldType={fieldType}
            setFieldType={setFieldType}
            value={value}
            country={tab}
            siteId={siteId}
          />
          {value === 0 && (
            <VisitsInformationTable fieldType={fieldType} value={value} />
          )}

          {value === 0 && showInitialRiskAssessment && (
            <InitialRiskAssessmentTable />
          )}
          {value === 0 && showVisitSampleStrategy && (
            <VisitSampleStrategyTables
              editOpen={editOpen}
              setEditOpen={setEditOpen}
            />
          )}
        </TabContainer>
      )
  );
}

const TabContainer = styled.div`
  grid-gap: 2em;
  display: grid;
`;
