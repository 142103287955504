import "./app.css";

import { Canvas, TopBar } from "./components";
import {
  LoginCallback,
  SecureRoute,
  Security,
  useOktaAuth,
} from "@okta/okta-react";
import {
  Route as PublicRoute,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import React, { useEffect } from "react";
import { UserProvider, useUser } from "./contexts/user.context";

import API from "./utils/api";
import AdminData from "../admin.data";
import { FavoriteProvider } from "./contexts/favorites.context";
import { OktaAuth } from "@okta/okta-auth-js";
import { ScheduleEventsProvider } from "./contexts/schedule.events.context";
import { SnackbarProvider } from "./contexts/snackbar.context";
import StudyPlanActuals from "../study.plan.actuals";
import StudyPlanSearch from "../study.plan.search";
import StudyPlans from "../study.plans";
import { StudyProvider } from "./contexts/study.context";
import StudySearchHome from "../study.plan.search/home";
import Typography from "@material-ui/core/Typography";
import { nanoid } from "nanoid";

const CALLBACK_PATH = "/ctpo/study-plans/";

const {
  REACT_APP_OKTA_CLIENT_ID: CLIENT_ID,
  REACT_APP_OKTA_DOMAIN: OKTA_DOMAIN,
} = process.env;

const oktaAuth = new OktaAuth({
  issuer: `https://${OKTA_DOMAIN}`,
  clientId: CLIENT_ID,
  redirectUri: window.location.origin + CALLBACK_PATH,
});

function App() {
  return (
    <Router>
      <Security oktaAuth={oktaAuth}>
        <Canvas>
          <UserProvider oktaAuth={oktaAuth}>
            <SnackbarProvider>
              <TopBar />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Switch>
                  <SecureRoute
                    exact
                    path="/"
                    render={({ history }) => history.push("/ctpo/study-plans")}
                  />
                  <SecureRoute path="/ctpo" component={CTPO} />
                </Switch>
                <PublicRoute path={CALLBACK_PATH} component={LoginCallback} />
                <PublicRoute
                  exact
                  path="/goodbye"
                  render={() => <Typography vasriant="h2">Goodbye</Typography>}
                />
                <PublicRoute
                  exact
                  path="/health"
                  render={() => (
                    <Typography vasriant="h2">Network Issues</Typography>
                  )}
                />
              </div>
            </SnackbarProvider>
          </UserProvider>
        </Canvas>
      </Security>
    </Router>
  );
}

export default App;

const CTPO = () => {
  const { setUpdateNavigation, setURLStudyId } = useUser();
  const { authState } = useOktaAuth();
  const { accessToken } = authState.accessToken;
  const history = useHistory();

  useEffect(() => {
    setUpdateNavigation(nanoid());

    // get the study id from the url
    const studyId = history.location.pathname.split("/")[3];
    // path contains study-plan or study-actual
    const pathContains =
      history.location.pathname.includes("study-plans") ||
      history.location.pathname.includes("study-actuals");
    if (studyId && pathContains) setURLStudyId(studyId);
    else setURLStudyId(null);
    // eslint-disable-next-line
  }, [history.location.pathname]);

  useEffect(() => {
    API.health()
      .then((res) => {
        // console.log("HEALTH SUCCESS", res);
      })
      .catch((err) => {
        // console.log("HEALTH ERROR", err);
        history.push("/health");
      });
    // eslint-disable-next-line
  }, []);

  if (accessToken) {
    document.cookie = `access_token=[${accessToken}]`;
  }

  return (
    <FavoriteProvider>
      <StudyProvider>
        <ScheduleEventsProvider>
          <Switch>
            <SecureRoute path="/ctpo/study-plans/:id" component={StudyPlans} />
            <SecureRoute
              path="/ctpo/study-plans/"
              component={StudySearchHome}
            />
            <SecureRoute
              path="/ctpo/study-actuals/:id"
              component={StudyPlanActuals}
            />
            <SecureRoute
              path="/ctpo/study-actuals/"
              component={StudyPlanSearch}
            />
            <SecureRoute path="/ctpo/admin-data/:id" component={AdminData} />
            <SecureRoute path="/ctpo/admin-data/" component={AdminData} />
          </Switch>
        </ScheduleEventsProvider>
      </StudyProvider>
    </FavoriteProvider>
  );
};
