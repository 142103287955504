import React from "react";
import styled from "styled-components";
import Label from "../label";

const ScenarioA = ({ siteGroup }) => {
  const {
    expectedScreenFailureRate,
    expectedTreatmentDropoutRate,
    siteEnrollmentRateAtSIV,
    parameters,
    milestones,
    timeCountryStartUpSize,
    timeSiteIdSize,
  } = siteGroup;

  const [sites_to_activate, subjects_to_screen] = parameters;
  const [number_patients_follow_up] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150
  );

  const formatValue = (vala, valb) => {
    let value = "";
    if (!vala && !valb) {
      return "- - ";
    }

    if (!vala) {
      value = `/-`;
    } else {
      value = `${vala}-`;
    }

    if (!valb) {
      value += "/";
    } else {
      value += valb;
    }

    return value;
  };

  return (
    <Container>
      <Column>
        <Label value={timeSiteIdSize ?? "- - "} variant="secondary">
          Site ID Effort {timeSiteIdSize && " - "}
        </Label>
        <Label value={siteEnrollmentRateAtSIV ?? "- - "}>
          Enrollment Rate (p/s/m)
        </Label>
        <Label
          value={formatValue(
            sites_to_activate.lowerBound,
            sites_to_activate.upperBound
          )}
        >
          {sites_to_activate.parameterDescr}
        </Label>
        <Label value={expectedScreenFailureRate ?? "- - "}>
          Screen Fail Rate (in decimals)
        </Label>
        <Label value={number_patients_follow_up.lowerBound ?? "- - "}>
          {number_patients_follow_up.milestoneDescr}
        </Label>
      </Column>
      <Column>
        <Label value={timeCountryStartUpSize ?? "- - "} variant="secondary">
          Country Start-Up Time Frame {timeCountryStartUpSize && " - "}
        </Label>
        <Label value=""></Label>
        <Label
          value={formatValue(
            subjects_to_screen.lowerBound,
            subjects_to_screen.upperBound
          )}
        >
          {subjects_to_screen.parameterDescr}
        </Label>
        <Label value={expectedTreatmentDropoutRate ?? "- - "}>
          Drop Out Rate (in decimals)
        </Label>
      </Column>
    </Container>
  );
};

export default ScenarioA;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const Column = styled.div`
  display: grid;
  grid-gap: 1em;
  height: min-content;
`;
