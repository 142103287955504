import React from "react";
import { AccordionTable } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { COLUMN_HEADERS } from "./table.utils";

import TableCell from "@material-ui/core/TableCell";

const CountryStartUpDurations = () => {
  const { simulationResults } = useStudyPlan();
  const { durationTable = [] } = simulationResults;

  return (
    <AccordionTable
      summary="Country Start-Up Durations"
      columns={COLUMN_HEADERS}
      data={durationTable}
      renderRow={(item) => (
        <>
          <TableCell component="th" scope="row">
            <strong>{item.siteGroupName}</strong>
          </TableCell>
          <TableCell align="center">
            {item["Site ID Duration (start to last site ID)"] ?? "## weeks"}
          </TableCell>
          <TableCell align="center">
            {item["Submission to Approval"] ?? "## weeks"}
          </TableCell>
          <TableCell align="center">
            {item["Approval to First Site Initiated"] ?? "## weeks"}
          </TableCell>
          <TableCell align="center">
            {item["Site Initiation Duration"] ?? "## weeks"}
          </TableCell>
        </>
      )}
    />
  );
};

export default CountryStartUpDurations;
