import { Button, LinearProgress, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import CloneScheduleOfEvent from "./clone.schedule.event.modal";
import EditScheduleOfEventModal from "./edit.schedule.event.modal";
import IconButton from "@material-ui/core/IconButton";
import { Modal } from "../../../app/components";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MuiTableRow from "@material-ui/core/TableRow";
import OptionsMenu from "./options.menu";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import styled from "styled-components";
import { useScheduleEvents } from "../../../app/contexts/schedule.events.context";
import { useSnackbar } from "../../../app/contexts/snackbar.context";

const TableRow = (props) => {
  const {
    soe,
    studyPlans,
    scheduleOfEventsId,
    scheduleOfEventsName,
    insertBy,
    updateDate,
    scheduleOfEventsDescr,
    scheduleOfEventsStatus,
    studyPlanId,
    scheduleOfEvents,
    savingSOEStudy,
  } = props;
  const [editOpen, setEditOpen] = useState(false);
  const [cloneOpen, setCloneOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const otherClasses = otherStyles();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const { updateSOE, fetchSOEStudy, deleteSOE, SOEError, setSOEError } =
    useScheduleEvents();
  const { showSnackbar } = useSnackbar();
  const studyPlanName = useMemo(() => {
    if (!studyPlanId) return "";
    return studyPlans.filter((study) => study.studyPlanId === studyPlanId)[0]
      .studyPlanName;
  }, [studyPlans, studyPlanId]);

  const history = useHistory();
  const { id } = useParams();
  const deleteMessage = useMemo(() => {
    if (SOEError)
      return "SOE cannot be deleted. Please contact the Creator of the SOE to delete.";
    return `
    Are you sure you want to delete this Schedule of Events record?
    This cannot be un-done and will remove all Visits, Assessments &
    Mappings at both the Study and Country level including all
    related Cost, Clinical Strategy and Data Management details.
    `;
  }, [SOEError]);

  const redirect = async () => {
    await fetchSOEStudy(id);
  };

  const handleClick = (e) => {
    setAnchor(e.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickStudy = () => {
    history.push(`/ctpo/study-plans/${id}/soe/${scheduleOfEventsId}`);
  };

  const updateSOEHandler = async (soeValue) => {
    try {
      await updateSOE(soeValue, redirect);
      showSnackbar("Saved successfully", "success");
      setEditOpen((prev) => !prev);
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to save soe", "error");
    }
  };

  const deleteSOEHandler = async () => {
    try {
      const success = await deleteSOE(scheduleOfEventsId, id);
      if (success) {
        setDeleteOpen(false);
        showSnackbar("Deleted successfully", "success");
        redirect();
      }
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to delete soe", "error");
    }
  };

  const closeDeleteModal = () => {
    setDeleteOpen(false);
    setSOEError(false);
  };

  return (
    <MuiTableRow>
      <TableCell
        align="left"
        className={otherClasses.link}
        onClick={handleClickStudy}
      >
        {scheduleOfEventsName}
      </TableCell>
      <TableCell align="left">
        <Typography>{scheduleOfEventsDescr}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>{scheduleOfEventsStatus}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>{studyPlanName}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>{insertBy}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography noWrap>
          {moment(updateDate).isValid() &&
            moment(updateDate).utc().format("DD/MMM/YYYY HH:mm A")}
        </Typography>
      </TableCell>

      <TableCell align="right" padding="checkbox">
        <IconButton onClick={handleClick}>
          <MoreVertIcon classes={classes} />
        </IconButton>
        <OptionsMenu
          setEditOpen={setEditOpen}
          setCloneOpen={setCloneOpen}
          setDeleteOpen={setDeleteOpen}
          open={open}
          anchor={anchor}
          scheduleOfEventsId={scheduleOfEventsId}
          onClose={() => setOpen(false)}
        />
      </TableCell>

      {editOpen && (
        <EditScheduleOfEventModal
          soe={soe}
          open={editOpen}
          onClose={() => setEditOpen(false)}
          onContinue={updateSOEHandler}
        />
      )}
      {cloneOpen && (
        <CloneScheduleOfEvent
          open={cloneOpen}
          onClose={() => setCloneOpen(false)}
          soe={{ scheduleOfEventsName, scheduleOfEventsId, scheduleOfEvents }}
        />
      )}
      {deleteOpen && (
        <Modal
          open={deleteOpen}
          onClose={closeDeleteModal}
          aria-labelledby="delete SOE"
          aria-describedby="delete a SOE"
          title="Delete SOE"
        >
          <Container>
            {savingSOEStudy ? (
              <LinearProgress />
            ) : (
              <Message>{deleteMessage}</Message>
            )}
          </Container>
          <SideBySide>
            <Button
              variant="contained"
              autoFocus
              onClick={closeDeleteModal}
              disabled={savingSOEStudy}
            >
              {SOEError ? "Close" : "Cancel"}
            </Button>
            {!SOEError && (
              <Button
                variant="contained"
                disabled={savingSOEStudy}
                onClick={deleteSOEHandler}
                color="primary"
              >
                DELETE
              </Button>
            )}
          </SideBySide>
        </Modal>
      )}
    </MuiTableRow>
  );
};

export default TableRow;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#757072",
  },
}));

const otherStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const Message = styled.p`
  text-align: center;
  padding-top: 0rem;
`;

const Container = styled.div`
  display: grid;
  padding: 1rem;
  grid-gap: 2em;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
