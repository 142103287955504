import React, { useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import SaveAsModal from "./save.as.modal";
import { makeStyles } from "@material-ui/core/styles";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const OptionsMenu = ({ anchor, open, onClose }) => {
  const classes = useStyles();
  const { updateStudyPlan, studyPlan } = useStudyPlan();
  const { studyPlanTypeDetail } = studyPlan;
  const [openModal, setOpenModal] = useState(false);

  const handleUpdateStatus = (status) => {
    updateStudyPlan({ scenarioStatus: status });
    onClose();
  };

  const handleUpdateStatusAsDraft = (status) => {
    setOpenModal(true);
    onClose();
  };

  return (
    <div>
      <Popper open={open} anchorEl={anchor} placement="bottom-end">
        <Paper classes={classes}>
          {["Duration", "Rates", "Cohort (D)"].includes(
            studyPlanTypeDetail
          ) && (
            <>
              <MenuItem onClick={() => handleUpdateStatusAsDraft("Draft")}>
                Draft
              </MenuItem>
              <MenuItem onClick={() => handleUpdateStatus("Baseline Plan")}>
                Baseline Plan
              </MenuItem>
              <MenuItem onClick={() => handleUpdateStatus("Ongoing Study")}>
                Ongoing Study
              </MenuItem>
              <MenuItem onClick={() => handleUpdateStatus("Post-Proposal")}>
                Post-Proposal
              </MenuItem>
              <MenuItem onClick={() => handleUpdateStatus("Proposal")}>
                Proposal
              </MenuItem>
            </>
          )}
          {["Remodel (D)", "Remodel (R)"].includes(studyPlanTypeDetail) && (
            <>
              <MenuItem onClick={() => handleUpdateStatusAsDraft("Draft")}>
                Draft
              </MenuItem>
              <MenuItem onClick={() => handleUpdateStatus("Baseline Plan")}>
                Baseline Plan
              </MenuItem>
            </>
          )}
          {["Combination (D)", "Combination (R)"].includes(
            studyPlanTypeDetail
          ) && (
            <>
              <MenuItem onClick={() => handleUpdateStatus("Draft-Combo")}>
                Draft-Combo
              </MenuItem>
              <MenuItem onClick={() => handleUpdateStatus("Baseline Plan")}>
                Baseline Plan
              </MenuItem>
              <MenuItem onClick={() => handleUpdateStatus("Ongoing Study")}>
                Ongoing Study
              </MenuItem>
              <MenuItem onClick={() => handleUpdateStatus("Post-Proposal")}>
                Post-Proposal
              </MenuItem>
              <MenuItem onClick={() => handleUpdateStatus("Proposal")}>
                Proposal
              </MenuItem>
            </>
          )}
        </Paper>
      </Popper>
      <SaveAsModal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        plan={studyPlan}
      />
    </div>
  );
};

export default OptionsMenu;

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    padding: "1em 0",
    border: "1px solid #e6e7e8",
  },
}));
