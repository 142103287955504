import React from "react";
import styled from "styled-components";
import MuiPaper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const Paper = ({ children }) => {
  const classes = useStyles();

  return (
    <MuiPaper className={classes.root}>
      <Container>{children}</Container>
    </MuiPaper>
  );
};

export default Paper;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "500px",
    width: "100%",
    height: "fit-content",
    //backgroundImage: `url(${background})`,
    //backgroundRepeat: "no-repeat",
    //backgroundSize: "contain",
    overflow: "hidden",
    position: "relative",
  },
}));

const Container = styled.div`
  padding: 1em 1em;
`;
