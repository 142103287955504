import React from "react";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";

import CircularProgress from "@material-ui/core/CircularProgress";

const Controls = ({
  isEdit,
  setIsEdit,
  onSave,
  onAddNew,
  onCancel,
  isLoading,
  isSaveDisabled,
}) => {
  const handleAddNewCard = () => {
    onAddNew();
  };

  const handleSave = () => {
    onSave();
  };

  const handleCancel = () => {
    onCancel();
    setIsEdit(false);
  };

  return (
    <Container>
      {!isEdit && (
        <Button color="primary" onClick={() => setIsEdit(true)}>
          <EditIcon style={{ marginRight: ".3em" }} /> Edit Values
        </Button>
      )}
      {isEdit && (
        <>
          <PrimaryControls>
            <Button
              color="primary"
              onClick={handleSave}
              disabled={isLoading || isSaveDisabled}
            >
              {isLoading ? (
                <CircularProgress size="1em" />
              ) : (
                <>
                  <SaveIcon style={{ marginRight: ".3em" }} /> Save Changes
                </>
              )}
            </Button>
            <Button color="primary" onClick={handleAddNewCard}>
              <AddIcon style={{ marginRight: ".3em" }} /> Add Data Card
            </Button>
          </PrimaryControls>
          <Button onClick={handleCancel}>Cancel</Button>
        </>
      )}
    </Container>
  );
};

export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
`;

const PrimaryControls = styled.div`
  display: flex;
  gap: 1em;
`;
