import React from "react";
import { Switch } from "react-router-dom";
// import { useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../common/header";
import Paper from "../common/paper";
// import { useUser } from "../../app/contexts/user.context";
// import { useScheduleEvents } from "../../app/contexts/schedule.events.context";
import ScheduleEditRoute from "./common/edit.route/schedule";
import SOE from "./soe";
import SOEForecast from "./soe.forecast";

const ScheduleStudyPlan = () => {
  return (
    <Container>
      <Paper>
        <React.Fragment>
          <Header />
          <Switch>
            <ScheduleEditRoute
              exact
              path="/ctpo/study-plans/:id/soe/:scheduleId/forecast"
              component={SOEForecast}
            />
            <ScheduleEditRoute
              exact
              path="/ctpo/study-plans/:id/soe/:scheduleId"
              component={SOE}
            />
          </Switch>
        </React.Fragment>
      </Paper>
    </Container>
  );
};

export default ScheduleStudyPlan;

const Container = styled.div`
  min-width: 1300px;
  width: 100%;
`;
