import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { SectionHeader, Tooltip } from "../../../../app/components";

export default function SFDORates({ errors, touched, values, setValues }) {
  return (
    <Container>
      <SectionHeader
        hasStartAdornment={false}
        endAdornment={
          <Tooltip info="Using the information in the first table, decide how many patients you want to include in the remodel and complete the fields in the second table. Note: You will have the option to include a specific number of patients in a particular country before completing the remodel (if needed)." />
        }
      >
        SF/DO Rates & Patients
      </SectionHeader>
      <TableContainer>
        <Table>
          <Row>
            <Cell variant="header">
              <Typography align="center">
                <strong></strong>
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">Screen Fail Rate</Typography>
            </Cell>
            <Cell>
              <Typography align="center">Drop Out Rate</Typography>
            </Cell>
          </Row>
          <Row>
            <Cell variant="header">
              <Typography align="center">
                <strong>Planned</strong>
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.screenFailRatePlanned}
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.dropOutRatePlanned}
              </Typography>
            </Cell>
          </Row>
          <Row>
            <Cell variant="header">
              <Typography align="center">
                <strong>Actual</strong>
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.screenFailRateActual}
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.dropOutRateActual}
              </Typography>
            </Cell>
          </Row>
          <Row>
            <Cell variant="header">
              <Typography align="center">
                <strong>Impact</strong>
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.screenFailRateImpact}
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.dropOutRateImpact}
              </Typography>
            </Cell>
          </Row>
        </Table>
      </TableContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 19em;
  border-bottom: 1px solid #ccc;
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 8em;
  align-items: center;
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid #ccc;
  padding: 0.5em;
  padding-left: 3em;
`;

const Table = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
