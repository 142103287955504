import React from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ViewSelector from "./view.selector";
import Row from "./row";
import { makeStyles } from "@material-ui/core/styles";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

export default function DetailsTable({ view, setView, type, ...props }) {
  const rowClasses = useRowStyles();
  const headerClasses = useHeaderStyles();
  const cellHeaderClasses = useCellHeaderStyles();
  const tableClasses = useTableStyles();
  const { simulationResults } = useStudyPlan();
  const {
    detailsByWeek,
    detailsByWeekNonCumulative,
    detailsByMonth,
    detailsByMonthNonCumulative,
    detailsByWeekCohort,
    detailsByWeekNonCumulativeCohort,
    detailsByMonthCohort,
    detailsByMonthNonCumulativeCohort,
  } = simulationResults;

  let data = {
    monthly: type === "cohort" ? detailsByMonthCohort : detailsByMonth,
    weekly: type === "cohort" ? detailsByWeekCohort : detailsByWeek,
    monthlyNonCumulative:
      type === "cohort"
        ? detailsByMonthNonCumulativeCohort
        : detailsByMonthNonCumulative,
    weeklyNonCumulative:
      type === "cohort"
        ? detailsByWeekNonCumulativeCohort
        : detailsByWeekNonCumulative,
  };

  if (!data[view]) {
    return null;
  }

  const subTableLength = data[view].header_dates?.length + 1;

  return (
    <>
      {
        <ControlsContainer>
          <ViewSelector
            type={type}
            value={view}
            onChange={(view) => setView(view)}
          />
          {props.extraControls &&
            React.cloneElement(props.extraControls, { detailsView: view })}
        </ControlsContainer>
      }
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" classes={tableClasses}>
          <TableHead classes={rowClasses}>
            <TableRow style={{ height: "112px" }}>
              <TableCell classes={cellHeaderClasses}></TableCell>
              {data[view].header_dates?.map((date) => (
                <TableCell key={date} align="right" classes={headerClasses}>
                  {date}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <Row
              name="Active Sites"
              row={data[view]?.table_body?.active_sites}
              subTableLength={subTableLength}
            />
            <Row
              name="Screened"
              row={data[view]?.table_body?.screened}
              subTableLength={subTableLength}
            />
            <Row
              name="Enrolled"
              row={data[view]?.table_body?.enrolled}
              subTableLength={subTableLength}
            />
            <Row
              name="Completed"
              row={data[view]?.table_body?.completed}
              subTableLength={subTableLength}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const useRowStyles = makeStyles((theme) => ({
  root: {
    background: "#e6e7e8",
  },
}));

const useHeaderStyles = makeStyles(() => ({
  root: {
    fontWeight: 600,
    textAlign: "center",
    whiteSpace: "nowrap",
    transformOrigin: "50% 50%",
    transform: "rotate(270deg)",
    padding: "0px",
  },
}));

const useCellHeaderStyles = makeStyles(() => ({
  root: {
    width: "201px",
    position: "sticky",
    left: 0,
    background: "#e6e7e8",
    zIndex: 10,
  },
}));

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const useTableStyles = makeStyles(() => ({
  root: {
    width: "max-content",
  },
}));
