import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

const TitleLabel = ({ label = "No Label", value = "No Value" }) => {
  const boldClasses = useBoldStyles();
  const classes = useStyles();

  return (
    <span style={{ display: "flex" }}>
      <Typography classes={boldClasses}>{label}:</Typography>
      <Typography classes={classes}>{value}</Typography>
    </span>
  );
};

export default TitleLabel;

const useBoldStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "600",
    color: "#002e6e",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#002e6e",
    marginLeft: ".3em",
  },
}));
