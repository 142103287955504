import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { Title } from "../../../../../../../app/components";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStudyPlan } from "../../../../../../../app/contexts/study.context";
import CustomDatePicker from "../../../../../../../app/components/custom.date.picker";

const DateEditor = (props) => {
  const {
    onExit,
    label,
    value,
    isUserOverride,
    lowerBound,
    onChange,
    comments,
  } = props;
  const [initMilestoneDate, setInitMilestoneDate] = useState(value);
  const [initComments, setInitComments] = useState(comments);
  const [initLowerBound, setInitLowerBound] = useState(lowerBound);
  const [initUserOverride, setInitUserOverride] = useState(isUserOverride);

  useEffect(() => {
    setInitMilestoneDate(value);
    setInitComments(comments);
    setInitLowerBound(lowerBound);
    setInitUserOverride(isUserOverride);
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  const inputClasses = useTextFieldStyles();
  const { savePlan, saveLoading } = useStudyPlan();

  const handleCancel = () => {
    onChange("milestoneDateMillis", initMilestoneDate);
    onChange("comments", initComments);
    onChange("lowerBound", initLowerBound);
    onChange("isUserOverride", initUserOverride);
    onExit();
  };

  const handleChangeOverride = (e) => {
    if (e.target.checked === true) {
      onChange("isUserOverride", 1);
    } else {
      onChange("isUserOverride", 0);
    }
  };

  return (
    <Paper classes={classes} elevation={0}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title size="small" dense>
            {label}
          </Title>
          <IconButton onClick={onExit}>
            <ClearIcon />
          </IconButton>
        </div>
        <Divider />
      </div>
      <TextFieldContainer>
        <div style={{ width: "50%" }}>
          <CustomDatePicker
            label="Date"
            value={value}
            onChange={(value) =>
              onChange(
                "milestoneDateMillis",
                value.getTime(),
                initMilestoneDate
              )
            }
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            dateFormat={"dd/MMM/yyyy"}
          />
        </div>
        <TextField
          label="Comments"
          variant="outlined"
          value={comments}
          onChange={(e) => onChange("comments", e.target.value)}
          InputProps={{ classes: inputClasses }}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isUserOverride === 1 ? true : false}
              onChange={handleChangeOverride}
              name="isUserOverride"
              color="primary"
            />
          }
          label="Check to confirm that calculated date will be overwritten"
        />
      </TextFieldContainer>
      <ButtonContainer>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          color="primary"
          onClick={() => savePlan({ isNew: false, undefined, onClose: onExit })}
          disabled={!comments || !value || saveLoading}
        >
          {saveLoading ? <CircularProgress size="1em" /> : "update dates"}
        </Button>
      </ButtonContainer>
    </Paper>
  );
};

export default DateEditor;

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f3f4f4",
    display: "grid",
    gridGap: "1em",
    padding: "0 1em",
    border: "1px solid #ccc",
  },
}));

const useTextFieldStyles = makeStyles((theme) => ({
  input: {
    background: "white",
  },
}));

const TextFieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0.3em;
`;
