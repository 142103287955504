import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Modal from "../../modal";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStudyPlan } from "../../../contexts/study.context";

const DeleteCountryModal = ({
  countryId,
  siteGroupId,
  open = false,
  onClose,
}) => {
  const { removeCountry, deleteCountryLoading } = useStudyPlan();
  const handleDelete = () => {
    removeCountry(countryId, siteGroupId, onClose);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Delete country"
      aria-describedby="Delete a country"
      title="Delete Country"
    >
      <Container>
        <Typography>
          Are you sure you want to remove this country from your study plan?
          This action cannot be undone and any changed data will be lost.
        </Typography>

        <ButtonContainer>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={deleteCountryLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            disabled={deleteCountryLoading}
          >
            {deleteCountryLoading ? (
              <CircularProgress size="1em" />
            ) : (
              "Remove Country"
            )}
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default DeleteCountryModal;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
