import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import EditScheduleOfEventModal from "../../../schedule.events.table/table.row/edit.schedule.event.modal";
import UnsavedChangesModal from "../../soe/unsaved.changes.modal";
import UploadSOEProtocolModal from "./upload.soe.potocol.modal";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";

const Controls = ({ errors, editOpen, setEditOpen }) => {
  const history = useHistory();
  const { id, scheduleId } = useParams();
  // const [open, setOpen] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const {
    hasSOEToSave,
    handleSOESave,
    loadingSOEStudy,
    checkSOEValid,
    fetchSOEVisits,
    fetchSOEAssessments,
    fetchSOEMapping,
    setScheduleOfEventsVisitsChangeLog,
    setScheduleOfEventsAssessmentsChangeLog,
    setVisitsAndAssessmentsMappingChangeLog,
    updateSOE,
    fetchSOEStudy,
    fetchSOECountries,
    fetchRiskAssessments,
    fetchVisitSampleStrategy,
    currentSOE: soe,
  } = useScheduleEvents();
  const { showSnackbar } = useSnackbar();

  const resetChanges = () => {
    setScheduleOfEventsVisitsChangeLog({});
    setScheduleOfEventsAssessmentsChangeLog({});
    setVisitsAndAssessmentsMappingChangeLog({});
  };

  const handleBackToList = () => {
    resetChanges();
    history.push(`/ctpo/study-plans/${id}`);
  };

  const handleCancel = () => {
    setUnsavedModalOpen(true);
    // resetChanges();
    // history.push(`/ctpo/study-plans/${id}`);
  };

  const callback = async (withoutLoading = false) => {
    await setTimeout(async () => {
      await fetchSOEVisits(scheduleId, id, null, null, null, withoutLoading);
      await fetchSOEAssessments(
        scheduleId,
        id,
        null,
        null,
        null,
        withoutLoading
      );
      await fetchSOEMapping(scheduleId, withoutLoading);
      await fetchSOECountries(scheduleId);
      await fetchRiskAssessments(scheduleId);
      await fetchVisitSampleStrategy(scheduleId);
    }, 200);
  };

  const handleSave = async (validate = true) => {
    try {
      let [formsValid, lErrorMessages] = checkSOEValid();
      if (!formsValid && validate) {
        showSnackbar(
          <ul>
            {Object.keys(lErrorMessages).map((key) => {
              return (
                <li key={key}>
                  {lErrorMessages[key].message}{" "}
                  {lErrorMessages[key].count > 1 && (
                    <ErrorMessageCount>
                      {lErrorMessages[key].count}
                    </ErrorMessageCount>
                  )}
                </li>
              );
            })}
          </ul>,
          "error"
        );
      } else {
        await handleSOESave(id, scheduleId, callback);
        showSnackbar("Saved successfully", "success");
        return true;
      }
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to save soe", "error");
    }
    return false;
  };

  const handleForecastSOE = () => {
    history.push(`/ctpo/study-plans/${id}/soe/${scheduleId}/forecast`);
  };

  const redirect = async () => {
    await fetchSOEStudy(id);
  };

  const updateSOEHandler = async (soeValue) => {
    try {
      await updateSOE(soeValue, redirect);
      showSnackbar("Saved successfully", "success");
      setEditOpen((prev) => !prev);
      window.location.reload(false);
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to save soe", "error");
    }
  };

  return (
    <Container>
      <Button color="primary" onClick={handleBackToList}>
        <ChevronLeftIcon />
        Back to SOE List
      </Button>

      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={handleCancel}
          disabled={!hasSOEToSave || loadingSOEStudy}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setEditOpen(true)}
        >
          Edit
        </Button>
        <Button
          disabled={!hasSOEToSave || loadingSOEStudy}
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setFileUploadOpen(true)}
        >
          Upload
        </Button>
        <Button variant="contained" color="primary" onClick={handleForecastSOE}>
          REPORTS
        </Button>
      </ButtonContainer>
      {editOpen && (
        <EditScheduleOfEventModal
          soe={{ scheduleOfEventsId: scheduleId }}
          open={editOpen}
          onClose={() => setEditOpen(false)}
          onContinue={updateSOEHandler}
        />
      )}
      {fileUploadOpen && (
        <UploadSOEProtocolModal
          soe={soe}
          open={fileUploadOpen}
          onClose={() => setFileUploadOpen(false)}
          onContinue={updateSOEHandler}
          fetchSOEValues={callback}
        />
      )}

      {
        <UnsavedChangesModal
          open={unsavedModalOpen}
          onToggle={() => setUnsavedModalOpen(!unsavedModalOpen)}
          handleSave={handleSave}
          handleBackToList={handleBackToList}
        />
      }
    </Container>
  );
};

export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1em;
`;

const ErrorMessageCount = styled.span`
  display: inline-block;
  font-size: 12px;
  padding: 0em 0.6em;
  border: 1px solid red;
  border-radius: 0.8em;
  color: red;
`;
