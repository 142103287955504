import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { SpecialTextField } from "../../../../../../app/components";
import { makeStyles } from "@material-ui/styles";

const DataCard = ({
  siteActivationLabel,
  siteActivationProfileDateMillis,
  siteActivationProfileDays,
  siteActivationProfilePct,
  siteActivationProfileId,
  id,
  index,
  isEdit,
  onChange,
  onDelete,
}) => {
  const classes = useStyles();

  const handleDelete = () => {
    onDelete(siteActivationProfileId, id);
  };

  const handleChange = (property, value) => {
    onChange({ [property]: value }, siteActivationProfileId, id);
  };

  return (
    <Draggable
      draggableId={id || siteActivationProfileId.toString()}
      isDragDisabled={!isEdit}
      index={index}
    >
      {(provided, snapshot) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {!isEdit && (
            <>
              <Header>
                <Typography>Label</Typography>
              </Header>
              <Cell>
                <Typography>{siteActivationLabel}</Typography>
              </Cell>
            </>
          )}
          <Header style={!isEdit ? { borderTop: "1px solid lightgray" } : {}}>
            <Typography>Percentage</Typography>
          </Header>
          {isEdit ? (
            <Cell>
              <SpecialTextField
                value={siteActivationProfilePct}
                onChange={(value) =>
                  handleChange("siteActivationProfilePct", value)
                }
                inputProps={{ step: 0.01 }}
                min={0.01}
              />
            </Cell>
          ) : (
            <Cell>
              <Typography>{siteActivationProfilePct}</Typography>
            </Cell>
          )}
          <Header style={{ borderTop: "1px solid lightgray" }}>
            <Typography>Days</Typography>
          </Header>
          {isEdit ? (
            <Cell>
              <SpecialTextField
                value={siteActivationProfileDays}
                onChange={(value) =>
                  handleChange("siteActivationProfileDays", value)
                }
              />
            </Cell>
          ) : (
            <Cell>
              <Typography> {siteActivationProfileDays}</Typography>
            </Cell>
          )}
          {isEdit ? (
            // if we are editing I am currently hiding the date section, including header.
            // <Cell>
            //   <SpecialTextField
            //     value={siteActivationProfileDateMillis}
            //     // onChange={(value) =>
            //     //   handleChange("siteActivationProfileDateMillis", value)
            //     // }
            //   />
            // </Cell>
            <div></div>
          ) : (
            // adding encapsulating div here to support hiding of the header during edit.
            <div>
              <Header style={{ borderTop: "1px solid lightgray" }}>
                <Typography>Date</Typography>
              </Header>
              <Cell>
                <Typography>
                  {" "}
                  {moment(siteActivationProfileDateMillis)
                    .utc()
                    .format("DD/MMM/YYYY")}
                </Typography>
              </Cell>
            </div>
          )}
          {isEdit && (
            <Button fullWidth classes={classes} onClick={handleDelete}>
              Delete Card
            </Button>
          )}
        </Card>
      )}
    </Draggable>
  );
};

export default DataCard;

const Card = styled.div`
  border: 1px solid lightgray;
  border-radius: 4px;
  min-width: 177px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  background: #f3f4f4;
  padding: 1em 0;
  border-bottom: 1px solid lightgray;
`;

const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0 16px 0 0`,
  background: isDragging ? "#e6f4fc" : "white",

  ...draggableStyle,
});

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#ed1a3c",
    fontWeight: 600,
  },
}));
