import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useFavorite } from "../../app/contexts/favorites.context";
import { useUser } from "../../app/contexts/user.context";
import LinkIcon from "@material-ui/icons/Link";
const PRIMARY_COLOR = "#1a73e8";
const PRIMARY_COLOR_DARK = "#002E6E";

const FavoriteStudy = () => {
  const history = useHistory();
  const location = useLocation();
  const { fetchFavorites, favorites } = useFavorite();
  const { user } = useUser();

  useEffect(() => {
    fetchFavorites(user?.userId);
    // eslint-disable-next-line
  }, []);

  // paramaterizing 'study plans', 'study actuals' part of URL - TODO: useParams() to replace this?
  const currTab = location.pathname.split("/").slice(2, 3);
  const handleClick = (favorite) =>
    favorite && history.push(`/ctpo/${currTab}/${favorite.studyId}`);

  const renderFavorites = () => {
    if (favorites.length === 0) {
      return <Typography color="textSecondary">No favorities yet.</Typography>;
    } else {
      return (
        <List>
          {favorites.map((favorite) => (
            <Item key={favorite.studyId} onClick={() => handleClick(favorite)}>
              <Typography noWrap>
                {favorite.projectId} - {favorite.studyName}
              </Typography>
            </Item>
          ))}
        </List>
      );
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <h2
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: PRIMARY_COLOR_DARK,
          fontSize: "1.2rem",
          alignItems: "center",
        }}
      >
        <span>My Favorites</span>
        <span style={{ color: PRIMARY_COLOR }}>
        </span>
      </h2>
      <Divider dashed style={{ margin: "0.5rem 0rem" }} />
      <div style={{ overflowY: "auto" }}>{renderFavorites()}</div>
      <Divider dashed style={{ margin: "0.5rem 0rem" }} />
      <div style={{ display: "flex", gap: ".5rem", flexDirection: "column" }}>
        <h2
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: PRIMARY_COLOR_DARK,
            fontSize: "1.2rem",
            alignItems: "center",
          }}
        >
          <span>Quick Links</span>
        </h2>
        <QuickLink
          href="https://analytics.premier-research.com/okta/sense/app/a6d024f1-ed67-4094-8db2-6366dc8341ca/sheet/7cc51354-ef05-4036-b435-1027cb2367dd/state/analysis"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkIcon style={{ marginRight: "0.5rem" }} />
          <Typography>Project Health Dashboard</Typography>
        </QuickLink>
        <QuickLink
          href="https://premier-research.okta.com/home/premierresearch_remarquerbm_1/0oa6b79srhDfYejZF1t7/aln6b7hthwvh8dWSD1t7"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkIcon style={{ marginRight: "0.5rem" }} />
          <Typography>Remarque</Typography>
        </QuickLink>
        <QuickLink
          href="https://premierresearch.sharepoint.com/sites/PremierPredict"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkIcon style={{ marginRight: "0.5rem" }} />
          <Typography>Help / More Information</Typography>
        </QuickLink>
      </div>
    </div>
  );
};
export default FavoriteStudy;

const Item = styled.div`
  margin-bottom: 0.5em;
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: #4AC6E1;
  }
`;

const List = styled.div``;

const QuickLink = styled.a`
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #4AC6E1;
  }
`;
