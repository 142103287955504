import "./placeholder.css";

import { IconButton, Paper, TextField } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import FavoriteStudy from "./favorite.studies";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import SearchTable from "./search.table";
import { debounce } from "lodash";
import styled from "styled-components";

// Primary color constant
const PRIMARY_COLOR_DARK = "#002E6E";

// Component for searching and displaying study plans
const StudyPlanSearch = () => {
  // State variables for search input and filter
  const [search, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState("");

  // add a debounce to the search
  const debouncedSearch = React.useCallback(
    debounce((search) => {
      setFilter(search);
    }, 1000),
    []
  );

  return (
    <Container>
      <nav>
        <Paper>
          <PaperContent>
            <FavoriteStudy />
          </PaperContent>
        </Paper>
      </nav>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div style={{ position: "relative", width: "100%" }}>
          <TextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debouncedSearch(e.target.value);
            }}
            style={{ width: "100%", borderRadius: "2rem !important" }}
            variant="outlined"
            placeholder="Search..."
            helperText="Search Study List... You can search by PCN, Sponsor Name, Indication"
          />
          {search && (
            <IconButton
              onClick={() => {
                setSearch("");
                debouncedSearch("");
              }}
              style={{
                position: "absolute",
                right: "40px",
                top: "15px",
                color: "white",
                padding: "2px",
              }}
              aria-label="clear"
            >
              <CloseIcon fontSize=".5rem" style={{ color: "gray" }} />
            </IconButton>
          )}
          <IconButton
            type="submit"
            style={{
              position: "absolute",
              right: "0",
              top: "5px",
              color: "white",
            }}
            aria-label="search"
          >
            <SearchIcon style={{ color: PRIMARY_COLOR_DARK }} />
          </IconButton>
        </div>
        <div style={{ width: "100%" }}>
          <SearchTable filter={filter} />
        </div>
      </div>
    </Container>
  );
};

export default StudyPlanSearch;

// Styled component for Paper content
const PaperContent = styled.div`
  z-index: 1;
  display: grid;
  grid-gap: 1.5em;
  padding: 1rem;
  `;

// Styled component for container
const Container = styled.div`
  padding: 1em;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 350px 1fr;
  width: 100%;
  max-width: 1600px;
`;
