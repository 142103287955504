import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../contexts/study.context";
import API from "../../../utils/api";

import { Modal } from "../..";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";

const EditCountryModal = ({
  countryId,
  siteGroupId,
  currentEffort,
  currentTimeframe,
  open = false,
  onClose,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const {
    updateCountries,
    savePlan,
    saveLoading,
    setIsCountryUpdate,
    isCountryUpdate,
  } = useStudyPlan();
  useEffect(() => {
    API.getSiteGroups()
      .then((res) => {
        const index = res.data.map((site) => site.countryId).indexOf(countryId);
        setSelectedCountry(res.data[index]);
      })
      .catch((err) => console.error(err));

    let update = { timeSiteIdSize: currentEffort };
    updateCountries(countryId, siteGroupId, update);
    update = { timeCountryStartUpSize: currentTimeframe };
    updateCountries(countryId, siteGroupId, update);
    // eslint-disable-next-line
  }, []);

  const handleSave = () => {
    savePlan({ isNew: false, onClose, from: "country_modal" });
  };

  const handleChange = (property, value) => {
    const update = { [property]: value };
    updateCountries(countryId, siteGroupId, update);
    setIsCountryUpdate(true);
  };

  const handleCancel = () => {
    const update = {
      timeSiteIdSize: currentEffort,
      timeCountryStartUpSize: currentTimeframe,
    };
    updateCountries(countryId, siteGroupId, update);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit country"
      aria-describedby="edit a country"
      title="Edit Country"
    >
      <Container>
        <Typography>
          The below should be confirmed by the appropriate functional area.
        </Typography>
        <FormControl variant="outlined">
          <InputLabel id="site-id-label">Select Site ID Effort</InputLabel>
          <Select
            labelId="site-id-label"
            id="select-site-id"
            value={currentEffort}
            onChange={(event) =>
              handleChange("timeSiteIdSize", event.target.value)
            }
            label="Select Site ID Effort"
          >
            <MenuItem value="Small">
              Small{" "}
              {selectedCountry &&
                `(${selectedCountry.timeSiteIdSmallDays}; 1-2 sites)`}
            </MenuItem>
            <MenuItem value="Medium">
              Medium{" "}
              {selectedCountry &&
                `(${selectedCountry.timeSiteIdMediumDays}; 3-10 sites)`}
            </MenuItem>
            <MenuItem value="Large">
              Large{" "}
              {selectedCountry &&
                `(${selectedCountry.timeSiteIdLargeDays}; 10+ sites)`}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="country-startup-label">
            Select Country Start-Up Time Frame
          </InputLabel>
          <Select
            labelId="country-startup-label"
            id="select-country-startup"
            value={currentTimeframe}
            onChange={(event) =>
              handleChange("timeCountryStartUpSize", event.target.value)
            }
            label="Select Country Start-Up Time Frame"
          >
            <MenuItem value="Super Short">
              Super Short{" "}
              {selectedCountry &&
                `(${selectedCountry.timeCountryStartUpSuperShortDays}; ${(
                  selectedCountry.timeCountryStartUpSuperShortDays / 30.42
                ).toFixed(2)} months)`}
            </MenuItem>
            <MenuItem value="Short">
              Short{" "}
              {selectedCountry &&
                `(${selectedCountry.timeCountryStartUpShortDays}; ${(
                  selectedCountry.timeCountryStartUpShortDays / 30.42
                ).toFixed(2)} months)`}
            </MenuItem>
            <MenuItem value="Medium">
              Medium{" "}
              {selectedCountry &&
                `(${selectedCountry.timeCountryStartUpMediumDays}; ${(
                  selectedCountry.timeCountryStartUpMediumDays / 30.42
                ).toFixed(2)} months)`}
            </MenuItem>
            <MenuItem value="Long">
              Long{" "}
              {selectedCountry &&
                `(${selectedCountry.timeCountryStartUpLongDays}; ${(
                  selectedCountry.timeCountryStartUpLongDays / 30.42
                ).toFixed(2)} months)`}
            </MenuItem>
          </Select>
        </FormControl>

        <ButtonContainer>
          <Button
            variant="outlined"
            onClick={handleCancel}
            disabled={saveLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={saveLoading || !isCountryUpdate}
          >
            {saveLoading ? <CircularProgress size="1em" /> : "Save"}
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default EditCountryModal;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
