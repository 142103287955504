import React from "react";
import styled from "styled-components";
import {
  SpecialTextField,
  Tooltip,
  SubPanel,
} from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const ScenarioB = ({ values, setValues }) => {
  const { studyPlan, updatePlan, isSequential } = useStudyPlan();

  const {
    screenFailRate,
    dropOutRate,
    screeningPeriodDuration,
    overallEnrollmentDuration,
    treatmentDuration,
    followUpDuration,
    parameters,
    milestones,
  } = studyPlan;
  const [sites_to_activate, subjects_to_screen] = studyPlan.parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150
  );

  const handleChangeParam = (param, key, value) => {
    const index = parameters.map((param) => param.parameterName).indexOf(key);
    const new_params = [...parameters];

    new_params[index][param] = value;
    updatePlan("parameters", new_params);
  };

  const handleChangeMilestones = (param, key, value) => {
    const index = milestones
      .map((milestone) => milestone.milestoneName)
      .indexOf(key);
    const new_milestones = [...milestones];
    new_milestones[index][param] = value;
    updatePlan("milestones", new_milestones);
  };

  return (
    <Container>
      <SideBySideCols>
        <FlexCol>
          {!isSequential && (
            <SpecialTextField
              label="Overall Enrollment Duration (days)"
              value={overallEnrollmentDuration}
              onChange={(value) => {
                updatePlan("overallEnrollmentDuration", value);
              }}
              required
            />
          )}
          <SpecialTextField
            label={sites_to_activate.parameterDescr}
            value={sites_to_activate.lowerBound}
            onChange={(value) => {
              handleChangeParam(
                "lowerBound",
                sites_to_activate.parameterName,
                value
              );
              // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }}
            key={sites_to_activate.parameterName}
            required
          />
          <SpecialTextField
            label={subjects_to_screen.parameterDescr}
            value={subjects_to_screen.lowerBound}
            onChange={(value) => {
              handleChangeParam(
                "lowerBound",
                subjects_to_screen.parameterName,
                value
              );
              // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }}
            key={subjects_to_screen.parameterName}
            required
          />
        </FlexCol>
        <FlexCol>
          <SpecialTextField
            label="Screening Period Per Patient (days)"
            value={screeningPeriodDuration}
            onChange={(value) => updatePlan("screeningPeriodDuration", value)}
            required
          />
          <SpecialTextField
            label="Treatment Period Per Patient (days)"
            value={treatmentDuration}
            onChange={(value) => updatePlan("treatmentDuration", value)}
            required
          />
          <SpecialTextField
            label="Follow-Up Period Per Patient (days)"
            value={followUpDuration}
            onChange={(value) => updatePlan("followUpDuration", value)}
            endadornment={
              <Tooltip info="If applicable to protocol. Make sure to also complete the Follow-Up Period Per Patients (days) field." />
            }
          />
        </FlexCol>
      </SideBySideCols>
      <SideBySideRows>
        <SubPanel>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SpecialTextField
              label="Screen Fail Rate (in decimals)"
              value={screenFailRate}
              disabled={typeof number_patients_enrolled.lowerBound === "number"}
              onChange={(value) => updatePlan("screenFailRate", value)}
              inputProps={{ step: 0.01 }}
              min={0.01}
            />
            <Tooltip info="Either the Screen Fail Rate OR the Number of Patients Enrolled must be completed here." />
          </div>
          <SpecialTextField
            label="Number of Patients Enrolled"
            value={number_patients_enrolled.lowerBound}
            onChange={(value) => {
              handleChangeMilestones(
                "lowerBound",
                number_patients_enrolled.milestoneName,
                value
              );
              // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }}
            disabled={typeof screenFailRate === "number"}
            key={number_patients_enrolled.milestoneName}
          />
        </SubPanel>

        <SubPanel>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SpecialTextField
              label="Drop Out Rate (in decimals)"
              value={dropOutRate}
              disabled={
                typeof number_patients_treatment.lowerBound === "number"
              }
              onChange={(value) => updatePlan("dropOutRate", value)}
              inputProps={{ step: 0.01 }}
              min={0.01}
            />
            <Tooltip info="Either the Drop Out Rate OR the Number of Patients Completing Treatment must be completed here." />
          </div>
          <SpecialTextField
            key={number_patients_treatment.milestoneId}
            label={number_patients_treatment.milestoneLabel}
            value={number_patients_treatment.lowerBound}
            onChange={(value) => {
              handleChangeMilestones(
                "lowerBound",
                number_patients_treatment.milestoneName,
                value
              );
              // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }}
            disabled={typeof dropOutRate === "number"}
          />
        </SubPanel>
      </SideBySideRows>
      <SideBySideRows>
        <SpecialTextField
          key={number_patients_follow_up.milestoneId}
          label={number_patients_follow_up.milestoneLabel}
          value={number_patients_follow_up.lowerBound}
          onChange={(value) => {
            handleChangeMilestones(
              "lowerBound",
              number_patients_follow_up.milestoneName,
              value
            );
            // trigger formic form update so that we can validate fields
            setValues({ ...values, updated: new Date().getTime() });
          }}
          endadornment={
            <Tooltip
              info={MILESTONE_INFO[number_patients_follow_up.milestoneName]}
            />
          }
        />
      </SideBySideRows>
    </Container>
  );
};

export default ScenarioB;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const MILESTONE_INFO = {
  NUM_SUBJECTS_ENROLLED:
    "If a certain number of enrolled patients is required in a specific country, also complete this at the country level. Either the Screen Fail Rate OR the Number of Patients Enrolled must be completed here or at the country level.",
  NUM_SUBJECTS_COMPLETED:
    "If applicable to protocol. Make sure to also complete the Number of Patients Completing Follow-up field.",
};

const SideBySideRows = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const SideBySideCols = styled.div`
  display: flex;
  gap: 1em;
`;

const FlexCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
