import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";

const SiteFatigue = (props) => {
  const { studyPlan, STUDY_PLAN_TYPES } = useStudyPlan();
  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;

  let renderScenario;
  if (studyPlanType === SCENARIO_A) {
    renderScenario = <ScenarioA {...props} />;
  }

  if (studyPlanType === SCENARIO_B) {
    renderScenario = <ScenarioB {...props} />;
  }

  return (
    <Container id="site-fatigue">
      <SectionHeader>Site Fatigue</SectionHeader>
      <Typography>
        Site Fatigue or a time-based erosion factor for enrollment can be
        applied using the below fields. This should be only applied when you
        expect the enrollment rate to decrease after a period of time. First,
        complete the number of days after site activation when site fatigue is
        expected to start. In the second field include the new lower enrollment
        rate that you expect.
      </Typography>
      <Content>{renderScenario}</Content>
    </Container>
  );
};

export default SiteFatigue;

const Container = styled.div``;

const Content = styled.div`
  margin-top: 1em;
`;
