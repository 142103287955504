import React from "react";
import styled from "styled-components";

export default function DisplayField({ label, value, direction, width }) {
  const Container =
    direction === "vertical" ? VerticalContainer : HorizontalContainer;
  return (
    <Container style={width ? { width: width } : {}}>
      <Label>{label}:</Label>
      <div>{value ?? "-"}</div>
    </Container>
  );
}

const Label = styled.div`
  color: gray;
  font-weight: bold;
`;

const HorizontalContainer = styled.div`
  display: flex;
  gap: 2em;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
