import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { SectionHeader, Tooltip } from "../../../../app/components";
import SpecialRadioButton from "../../../../app/components/special.radiobutton";

export default function CountrySites({ errors, touched, values, setValues }) {
  const renderCountrySites = () => {
    return values?.siteGroups?.map((siteGroup, index) => {
      return (
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="scenario"
            name="scenarios"
            value={siteGroup.sitesSelection}
            onChange={(e) => {
              if (!e.target.value) return;
              let siteGroupLocal = siteGroup;
              siteGroupLocal["sitesSelection"] = parseFloat(e.target.value);
              let siteGroups = values.siteGroups.map((sg, i) => {
                if (i === index) return siteGroupLocal;
                return sg;
              });
              setValues({ ...values, siteGroups });
            }}
          >
            <Row>
              <Cell flex={1}>
                <Typography style={{ textAlign: "left", width: "100%" }}>
                  {siteGroup.siteGroupName}
                </Typography>
              </Cell>
              <Cell flex={2}>
                {!(siteGroup?.sitesPlanned === null) && (
                  <FormControlLabel
                    style={{ minWidth: "5rem" }}
                    value={1}
                    control={<Radio color="primary" />}
                    label={siteGroup?.sitesPlanned}
                  />
                )}
              </Cell>
              <Cell flex={2}>
                {!(siteGroup?.sitesActual === null) && (
                  <FormControlLabel
                    style={{ minWidth: "5rem" }}
                    value={2}
                    control={<Radio color="primary" />}
                    label={siteGroup?.sitesActual}
                  />
                )}
              </Cell>
              <Cell flex={2}>
                <SpecialRadioButton
                  type="number"
                  currentRadioValue={3}
                  onChange={(value) => {
                    let siteGroupLocal = siteGroup;
                    siteGroupLocal["sitesOther"] = parseFloat(value);
                    let siteGroups = values.siteGroups.map((sg, i) => {
                      if (i === index) return siteGroupLocal;
                      return sg;
                    });
                    setValues({ ...values, siteGroups });
                  }}
                  value={siteGroup?.sitesOther}
                  error={
                    errors?.siteGroups?.length >= index &&
                    !!errors?.siteGroups[index]?.sitesOther
                  }
                />
              </Cell>
            </Row>
          </RadioGroup>
        </FormControl>
      );
    });
  };

  return (
    <Container>
      <SectionHeader
        hasStartAdornment={false}
        endAdornment={
        <Tooltip
          info={
            <>
              Choose the number of sites in each country that you want to include in the remodel. If you want to add additional sites in a country, input a new value in the Other option. The Actual/Projected value includes all sites in the Remarque Start-up Module that are currently either active or have a projected activation date.
              <br /><br />
              For countries that you do not want to include in a Remodel (i.e., there are not active or closed sites in this country and there are no longer plans to include sites or patients in this country), select the radio button under Other and include 0 for this country. This country will not be included in the remodel plan.
              <br /><br />
              If there is a country that has closed sites and no additional sites or patients planned in this country, use the Actual/Projected radio button (with 0) so that the closed sites appear in the Site Activation Details.
            </>
          }
        />
        
        }
      >
        Country - Number of Sites to Include in Projections
      </SectionHeader>
      <TableContainer>
        <Table>
          <Row>
            <Cell variant="header" flex={1}></Cell>
            <Cell variant="header" flex={2}>
              <Typography align="center">
                <strong>Planned</strong>
              </Typography>
            </Cell>
            <Cell variant="header" flex={2}>
              <Typography align="center">
                <strong>Actual/Projected</strong>
              </Typography>
            </Cell>
            <Cell variant="header" flex={2}>
              <Typography align="center">
                <strong>Other</strong>
              </Typography>
            </Cell>
          </Row>
          {renderCountrySites()}
        </Table>
      </TableContainer>
      {errors.countrySites && (
        <ErrorMessageContainer className="MuiFormHelperText-root">
          {errors.countrySites}
        </ErrorMessageContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-between;
  flex-grow: 1;
  border-bottom: 1px solid #ccc;
  height: 6em;
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  flex: ${(p) => p.flex || "1"};
  align-items: center;
  justify-content: ${(p) => p.alignText || "center"};
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 1em;
  padding-left: 3em;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ErrorMessageContainer = styled.div`
  color: #f44336 !important;
  margin: 3px 14px !important;
`;
