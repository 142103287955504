import { FIELD_TYPES, VISIT_REQUIRED_FIELDS } from "../constants";
import React, { useMemo } from "react";

import { Typography } from "@material-ui/core";
import styled from "styled-components";

/*
  This component is responsible for rendering the table header of the visits table.
  It uses the material ui table cell component.
  It uses the material ui table row component.
  The parent component must pass the remove column width of the table.
  The parent component must pass the value of the table.
  The parent component must pass the visit to use of the table.
  The parent component must pass the field type of the table.
  The parent component must pass the column count of the table.
  The parent component must pass a callback function to handle the edit of the table.
*/
export default function TableHeader({
  removeColWidth,
  value,
  visitToUse,
  fieldType,
  columnCount,
  setEditOpen,
}) {
  const commentType = useMemo(() => {
    if (fieldType === FIELD_TYPES.VISIT) return "Visit Comments";
    if (fieldType === FIELD_TYPES.COST) return "Cost Comments";
    if (fieldType === FIELD_TYPES.MEDICAL) return "Clinical Strategy Comments";
    if (fieldType === FIELD_TYPES.DATA_MANAGEMENT)
      return "Data Management Comments";
    return "";
  }, [fieldType]);
  return (
    <>
      <Row variant="header">
        <Cell
          width={`${(3.5 / columnCount) * (100 - removeColWidth)}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>
              Visit Category{" "}
              {VISIT_REQUIRED_FIELDS["visitCategory"] && (
                <RequiredContainer>*</RequiredContainer>
              )}
            </strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(6 / columnCount) * (100 - removeColWidth)}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>
              Visit Name{" "}
              {VISIT_REQUIRED_FIELDS["visitName"] && (
                <RequiredContainer>*</RequiredContainer>
              )}
            </strong>
          </Typography>
        </Cell>
        <Cell
          width={`${
            ((fieldType === FIELD_TYPES.VISIT ? 3 : 2) / columnCount) *
            (100 - removeColWidth)
          }%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>
              Study {visitToUse?.visitIntervalType || "Day"}{" "}
              {VISIT_REQUIRED_FIELDS["visitStudyDay"] && (
                <RequiredContainer>*</RequiredContainer>
              )}
            </strong>
          </Typography>
        </Cell>

        <Cell
          width={`${(1.5 / columnCount) * (100 - removeColWidth)}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>(+/-)</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${
            ((fieldType === FIELD_TYPES.VISIT ? 2.5 : 1.5) / columnCount) *
            (100 - removeColWidth)
          }%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>
              Visit Window {visitToUse?.visitWindowIntervalType || "Day"}
            </strong>
          </Typography>
        </Cell>

        {(fieldType === FIELD_TYPES.COST || value !== 0) && (
          <>
            <Cell
              width={`${(2 / columnCount) * (100 - removeColWidth)}%`}
              variant="header"
            >
              <Typography variant="body2">
                <strong>
                  Visit Overhead %{" "}
                  {VISIT_REQUIRED_FIELDS["visitCost"] && (
                    <RequiredContainer>*</RequiredContainer>
                  )}
                </strong>
              </Typography>
            </Cell>
            <Cell
              width={`${(2 / columnCount) * (100 - removeColWidth)}%`}
              variant="header"
            >
              <Typography variant="body2">
                <strong>Visit Overhead </strong>
              </Typography>
            </Cell>
          </>
        )}
        {value === 0 && (
          <>
            {/* Medical Informatics Fields */}
            {fieldType === FIELD_TYPES.MEDICAL && (
              <>
                <Cell
                  width={`${(2 / columnCount) * (100 - removeColWidth)}%`}
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>100% SDR/SDV Time (mins)</strong>
                  </Typography>
                </Cell>
                <Cell
                  width={`${(2 / columnCount) * (100 - removeColWidth)}%`}
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>CRA Sampled Visit</strong>
                  </Typography>
                </Cell>
                <Cell
                  width={`${(2 / columnCount) * (100 - removeColWidth)}%`}
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>Onsite SDR/SDV (mins)</strong>
                  </Typography>
                </Cell>
                <Cell
                  width={`${(2 / columnCount) * (100 - removeColWidth)}%`}
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>
                      CDA Review (mins){" "}
                      <EditButton onClick={() => setEditOpen(true)}>
                        {visitToUse.CDAReviewPctTime}
                      </EditButton>
                    </strong>
                  </Typography>
                </Cell>
              </>
            )}

            {/* Data Management Fields */}
            {fieldType === FIELD_TYPES.DATA_MANAGEMENT && (
              <>
                <Cell
                  width={`${(2 / columnCount) * (100 - removeColWidth)}%`}
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>Unique CRFs</strong>
                  </Typography>
                </Cell>
                <Cell
                  width={`${(2 / columnCount) * (100 - removeColWidth)}%`}
                  variant="header"
                >
                  <Typography variant="body2">
                    <strong>Non-unique CRFs</strong>
                  </Typography>
                </Cell>
              </>
            )}
            <Cell
              width={`${(2.5 / columnCount) * (100 - removeColWidth)}%`}
              variant="header"
            >
              <Typography variant="body2">
                <strong>{commentType}</strong>
              </Typography>
            </Cell>
          </>
        )}

        {value === 0 && (
          <Cell width={`${removeColWidth}%`} variant="header"></Cell>
        )}
      </Row>
    </>
  );
}

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid #ccc" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid #ccc" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid #ccc" : "")};
`;

const RequiredContainer = styled.span`
  color: red;
`;
// button like a link
const EditButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
`;
