export const COLUMN_HEADERS = {
  "Project Start Date": "Project Start Date",
  "Protocol Final Date": "Protocol Final Date",
  "Site ID Finish": "Site ID Finish",
  "First Regulatory Submission": "First Regulatory Submission",
  "First Regulatory Approval": "First Site Start-up Activities Complete",
  "First Site Initiated": "First Site Activated",
  "Last Site Initiated": "Last Site Activated",
  "First Screened": "First Screened",
  "First Enrolled": "First Enrolled",
  "Last Enrolled": "Last Enrolled",
};
