import React from "react";
import styled from "styled-components";
import {
  SpecialTextField,
  Tooltip,
  SubPanel,
} from "../../../../../../app/components";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const ScenarioB = ({ country, siteGroup, onUpdate, values, setValues }) => {
  const { parameters, siteGroupId, milestones } = siteGroup;
  const { countryId } = country;
  const { expectedScreenFailureRate, expectedTreatmentDropoutRate } = siteGroup;

  const { updateCountries } = useStudyPlan();
  const [sites_to_activate, subjects_to_screen] = parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150
  );

  const handleUpdateSiteGroup = (property, value) => {
    const update = { [property]: value };
    updateCountries(countryId, siteGroupId, update);
  };

  const handleUpdateSiteGroupParameters = (param, property, value) => {
    const index = siteGroup.parameters
      .map((param) => param.parameterName)
      .indexOf(param.parameterName);
    const updated_param = { ...param, [property]: value };
    const new_parameters = [...siteGroup.parameters];
    new_parameters[index] = updated_param;

    const update = { parameters: new_parameters };
    updateCountries(countryId, siteGroupId, update);
  };
  const handleUpdateSiteGroupMilestones = (milestone, property, value) => {
    const index = siteGroup.milestones
      .map((milestone) => milestone.milestoneName)
      .indexOf(milestone.milestoneName);
    const updated_milestone = { ...milestone, [property]: value };
    const new_milestones = [...siteGroup.milestones];
    new_milestones[index] = updated_milestone;

    const update = { milestones: new_milestones };
    updateCountries(countryId, siteGroupId, update);
  };

  return (
    <Container>
      <SideBySideCols>
        <SpecialTextField
          // altlabel="Lower Bound"
          label={toTitleCase(sites_to_activate.parameterDescr)}
          value={sites_to_activate.lowerBound}
          onChange={(value) => {
            handleUpdateSiteGroupParameters(
              sites_to_activate,
              "lowerBound",
              value
            );
            // trigger formic form update so that we can validate fields
            setValues({ ...values, updated: new Date().getTime() });
          }}
          key={sites_to_activate.parameterName}
          required
        />
        <SpecialTextField
          // altlabel="Lower Bound"
          label={toTitleCase(subjects_to_screen.parameterDescr)}
          value={subjects_to_screen.lowerBound}
          onChange={(value) =>
            handleUpdateSiteGroupParameters(
              subjects_to_screen,
              "lowerBound",
              value
            )
          }
          key={subjects_to_screen.parameterName}
          endadornment={
            <Tooltip info="This field is required if a specific rate (screen fail or dropout) or specific number of patients is needed in this country." />
          }
        />
      </SideBySideCols>
      <SideBySideRows>
        <SubPanel>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SpecialTextField
              label="Screen Fail Rate (in decimals)"
              value={expectedScreenFailureRate}
              onChange={(value) =>
                handleUpdateSiteGroup("expectedScreenFailureRate", value)
              }
              disabled={typeof number_patients_enrolled.lowerBound === "number"}
              inputProps={{ step: 0.01 }}
              min={0.01}
            />
            <Tooltip info="If a specific rate or number of enrolled patients is needed in this country, complete either the Screen Fail Rate OR the Number of Patients Enrolled. You must also complete the Number of Patients Screened. If no specific country numbers are needed, skip this section." />
          </div>
          <SpecialTextField
            label={number_patients_enrolled.milestoneDescr}
            value={number_patients_enrolled.lowerBound}
            onChange={(value) =>
              handleUpdateSiteGroupMilestones(
                number_patients_enrolled,
                "lowerBound",
                value
              )
            }
            disabled={typeof expectedScreenFailureRate === "number"}
            key={number_patients_enrolled.milestoneName}
          />
        </SubPanel>
        <SubPanel>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SpecialTextField
              label="Drop Out Rate (in decimals)"
              value={expectedTreatmentDropoutRate}
              onChange={(value) =>
                handleUpdateSiteGroup("expectedTreatmentDropoutRate", value)
              }
              disabled={
                typeof number_patients_treatment.lowerBound === "number"
              }
              inputProps={{ step: 0.01 }}
              min={0.01}
            />
            <Tooltip info="If a specific rate or number of completed patients is needed in a country, complete either the Drop Out Rate OR the Number of Patients Completing Treatment. You must also complete either the Number of Patients Screened and either the Number of Patients Enrolled or the Screen Fail Rate. If no specific country numbers are needed, skip this section." />
          </div>
          <SpecialTextField
            label={number_patients_treatment.milestoneDescr}
            value={number_patients_treatment.lowerBound}
            onChange={(value) =>
              handleUpdateSiteGroupMilestones(
                number_patients_treatment,
                "lowerBound",
                value
              )
            }
            disabled={typeof expectedTreatmentDropoutRate === "number"}
            key={number_patients_treatment.milestoneName}
          />
        </SubPanel>
      </SideBySideRows>
      <SideBySideRows>
        <SpecialTextField
          label={number_patients_follow_up.milestoneDescr}
          value={number_patients_follow_up.lowerBound}
          onChange={(value) =>
            handleUpdateSiteGroupMilestones(
              number_patients_follow_up,
              "lowerBound",
              value
            )
          }
          key={number_patients_follow_up.milestoneName}
          endadornment={
            <Tooltip info="Only complete this field if it is applicable to the protocol and a specific number of patients is needed in this country. To use this field, you must also complete the other fields in this section. " />
          }
        />
      </SideBySideRows>
    </Container>
  );
};

export default ScenarioB;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const SideBySideRows = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const SideBySideCols = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 1em;
  grid-auto-flow: column;
`;
