import { makeStyles, withStyles } from "@material-ui/core/styles";

import FilterListIcon from "@material-ui/icons/FilterList";
import { IconButton } from "@material-ui/core";
import MuiTableHead from "@material-ui/core/TableHead";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

// create a styled table cell with no horizontal padding
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);
const TableHead = ({
  filterActive,
  setFilterActive,
  orderBy,
  order,
  onRequestSort,
}) => {
  const classes = useStyles();
  const otherClasses = useOtherStyles();
  const headCells = [
    {
      id: "projectId",
      numeric: false,
      disablePadding: false,
      label: "Project ID",
    },
    {
      id: "studyName",
      numeric: false,
      disablePadding: false,
      label: "Study Name",
    },
    {
      id: "sponsorName",
      numeric: false,
      disablePadding: false,
      label: "Sponsor",
    },
    {
      id: "opportunityStage",
      numeric: false,
      disablePadding: false,
      label: "Opportunity Stage",
    },
    {
      id: "enrollmentPlanExists",
      numeric: false,
      disablePadding: false,
      label: "Enroll Plan",
    },
    {
      id: "soeExists",
      numeric: false,
      disablePadding: false,
      label: "SOE",
    },
    {
      id: "soeCosts",
      numeric: false,
      disablePadding: false,
      label: "SOE Costs",
    },
    {
      id: "soeClinicalInformatics",
      numeric: false,
      disablePadding: false,
      label: "SOE Clin Strat",
    },
    {
      id: "soeDataManagement",
      numeric: false,
      disablePadding: false,
      label: "SOE DM",
    },
    {
      id: "enrollmentPlanLastUpdate",
      numeric: false,
      disablePadding: false,
      label: "Enrollment Plan Last Update",
    },
    {
      id: "soeLastUpdate",
      numeric: false,
      disablePadding: false,
      label: "SOE Last Update",
    },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead classes={classes}>
      <TableRow classes={classes}>
        <StyledTableCell>
          <IconButton onClick={() => setFilterActive(!filterActive)}>
            <FilterListIcon fontSize="small" />
          </IconButton>
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
          style={
            ["enrollmentPlanLastUpdate"].includes(headCell.id)
              ? { minWidth: "10rem" }
              : headCell.id === "projectId"
              ? { minWidth: "7rem" }
              : headCell.id === "soeClinicalInformatics"
              ? { minWidth: "7rem" }
              : headCell.id === "soeLastUpdate"
              ? { minWidth: "6rem" }
              : [
                  "enrollmentPlanExists",
                  "soeExists",
                  "soeCosts",
                  "soeDataManagement",
                ].includes(headCell.id)
              ? { width: "5.5rem" }
              : {}
          }
            key={headCell.id}
            align="center"
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={otherClasses.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e7e8",
    borderRadius: "10px",
  },
}));

const useOtherStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
