import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../app/components";
import { Field, getIn } from "formik";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default function SFDOPatients({ errors, touched, values, setValues }) {
  const screenedPatients = getIn(values, "screenPatientsReModel");
  const enrolledPatients = getIn(values, "enrollPatientsReModel");
  const completedPatients = getIn(values, "completePatientsReModel");
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "80px",
    },
  }));

  useEffect(() => {
    let updates = {};
    if (screenedPatients && screenedPatients >= enrolledPatients) {
      updates["enrollPatientsRate"] = (
        (screenedPatients - enrolledPatients) /
        screenedPatients
      ).toFixed(2);
    }
    if (enrolledPatients && enrolledPatients >= completedPatients) {
      updates["completePatientsRate"] = (
        (enrolledPatients - completedPatients) /
        enrolledPatients
      ).toFixed(2);
    }
    setValues({
      ...values,
      ...updates,
    });
    // eslint-disable-next-line
  }, [screenedPatients, enrolledPatients, completedPatients]);

  const classes = useStyles();

  return (
    <Container>
      <SectionHeader hasStartAdornment={false}></SectionHeader>
      <TableContainer>
        <Table>
          <Row>
            <Cell variant="header">
              <Typography align="center">
                <strong></strong>
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">Screened Patients</Typography>
            </Cell>
            <Cell>
              <Typography align="center">Enrolled Patients</Typography>
            </Cell>
            <Cell>
              <Typography align="center">Completed Patients</Typography>
            </Cell>
          </Row>
          <Row>
            <Cell variant="header">
              <Typography align="center">
                <strong>Planned</strong>
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.screenPatientsPlanned}
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.enrollPatientsPlanned}
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.completePatientsPlanned}
              </Typography>
            </Cell>
          </Row>
          <Row>
            <Cell variant="header" alignText={"start"} flex={1}>
              <Typography align="center">
                <strong>Re-model</strong>
              </Typography>
            </Cell>
            <Cell alignText={"start"} flex={1}>
              <Field name="screenPatientsReModel">
                {({ field }) => (
                  <TextField
                    {...field}
                    classes={classes}
                    variant="outlined"
                    type="number"
                    error={
                      touched.screenPatientsReModel &&
                      errors.screenPatientsReModel
                    }
                  />
                )}
              </Field>
            </Cell>
            <Cell alignText={"start"} flex={1}>
              <Field name="enrollPatientsReModel">
                {({ field }) => (
                  <TextField
                    {...field}
                    classes={classes}
                    variant="outlined"
                    type="number"
                    error={
                      touched.enrollPatientsReModel &&
                      errors.enrollPatientsReModel
                    }
                  />
                )}
              </Field>
            </Cell>
            <Cell alignText={"start"} flex={1}>
              <Field name="completePatientsReModel">
                {({ field }) => (
                  <TextField
                    {...field}
                    classes={classes}
                    variant="outlined"
                    type="number"
                    error={
                      touched.completePatientsReModel &&
                      errors.completePatientsReModel
                    }
                  />
                )}
              </Field>
            </Cell>
          </Row>
          <Row>
            <Cell variant="header">
              <Typography align="center">
                <strong>Rate</strong>
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center"></Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.enrollPatientsRate}
              </Typography>
            </Cell>
            <Cell>
              <Typography align="center">
                {values?.completePatientsRate}
              </Typography>
            </Cell>
          </Row>
        </Table>
      </TableContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 19em;
  border-bottom: 1px solid #ccc;
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 8em;
  align-items: center;
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid #ccc;
  padding: 0.5em;
  padding-left: 3em;
`;

const Table = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
