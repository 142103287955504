import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Controls from "../common/controls";
import CountryPanel from "./country.panel";
import CountryTabs from "./country.tabs";
import Stepper from "../../common/stepper";
import styled from "styled-components";
import { useStudyPlan } from "../../../app/contexts/study.context";
import { useUser } from "../../../app/contexts/user.context";

const CountriesAndSiteGroups = ({ values, errors, setValues }) => {
  const history = useHistory();
  const location = useLocation();
  const country = new URLSearchParams(location?.search).get("country");
  const { id, planId } = useParams();
  const [value, setValue] = useState(country ? parseInt(country) : 0);
  const { studyPlan } = useStudyPlan();
  const { countries } = studyPlan;
  const { setURLStudyId } = useUser();

  // listed to country param change in url and update country
  useEffect(() => {
    const country = new URLSearchParams(location?.search).get("country");
    setValue(country ? parseInt(country) : 0);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setURLStudyId(id);
    // eslint-disable-next-line
  }, []);

  // update updated field when study plan changes
  // this is used to trigger formic form update so that we can validate fields
  useEffect(() => {
    if (studyPlan) setValues({ ...values, updated: new Date().getTime() });
    // eslint-disable-next-line
  }, [studyPlan]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!planId) {
      history.push(`/ctpo/study-plans/${id}/${planId}`);
    }
    // trigger formic form update so that we can validate fields
    setValues({ ...values, updated: new Date().getTime() });
    // eslint-disable-next-line
  }, []);

  const sorted_countries = countries?.sort((a, b) =>
    a.countryCodeISO3.localeCompare(b.countryCodeISO3)
  );

  return (
    <Container>
      <Controls errors={errors} />
      <Stepper errors={errors} />
      <CountryTabs value={value} onChange={(event, tab) => setValue(tab)} />
      {sorted_countries?.map((country, index) => (
        <CountryPanel
          country={country}
          id={index}
          key={country.countryId}
          value={value}
          setValues={setValues}
          errors={errors}
        />
      ))}
    </Container>
  );
};

export default CountriesAndSiteGroups;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
