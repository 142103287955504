import { SectionHeader, Tooltip } from "../../../../../app/components";

import DateLabel from "./date.label";
import React from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const CountryGlobalMilestones = ({ country, siteGroup }) => {
  const { updateCountryMilestones } = useStudyPlan();
  const { milestones, siteGroupId } = siteGroup;
  const { countryId } = country;

  const filteredMilestones = milestones.filter(
    (m) =>
      m.milestoneId === 158 ||
      m.milestoneId === 135 ||
      m.milestoneId === 136 ||
      m.milestoneId === 139 ||
      m.milestoneId === 142 ||
      m.milestoneId === 145 ||
      m.milestoneId === 148 ||
      m.milestoneId === 151 ||
      m.milestoneId === 146 ||
      m.milestoneId === 149 ||
      m.milestoneId === 152
  );

  const leftSide = filteredMilestones.slice(0, 6);
  const rightSide = filteredMilestones.slice(7, 11);
  const canEditList = [135, 136, 139, 142, 145, 158];

  const handleChange = (milestoneName, property, value, initialValue) => {
    const milestone_index = milestones
      .map((m) => m.milestoneName)
      .indexOf(milestoneName);
    const milestone = milestones[milestone_index];

    if (property === "milestoneDateMillis") {
      milestone[property] = value;

      const lowerBound = Math.round(
        (value - initialValue) / (1000 * 60 * 60 * 24)
      );
      milestone.lowerBound = lowerBound;
    } else {
      milestone[property] = value;
    }

    const new_milestones = [...milestones];
    new_milestones[milestone_index] = milestone;
    const update = { milestones: new_milestones };
    const changelog_update = [milestone];
    updateCountryMilestones(countryId, siteGroupId, update, changelog_update);
  };

  const renderMilestone = (milestone) => {
    const NUM_MILESTONES = [138, 141, 144, 147, 150];
    const has_num_milestone = NUM_MILESTONES.includes(milestone.milestoneId);
    if (has_num_milestone) {
      return null;
    } else {
      return (
        <DateLabel
          label={milestone.milestoneLabel}
          value={milestone.milestoneDateMillis}
          comments={milestone.comments}
          lowerBound={milestone.lowerBound}
          isUserOverride={milestone.isUserOverride}
          key={milestone.milestoneName}
          hasEdit={canEditList.includes(milestone.milestoneId)}
          onChange={(property, value, initialValue) =>
            handleChange(milestone.milestoneName, property, value, initialValue)
          }
        />
      );
    }
  };

  return (
    <Container id="country-milestones">
      <SectionHeader
        endAdornment={
          <Tooltip info="Certain dates below can be overridden. If you do edit a date, please include the reason for this in the corresponding comment field." />
        }
      >
        Country Milestones
      </SectionHeader>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Content>
          {leftSide.map((milestone) => renderMilestone(milestone))}
        </Content>
        <Content>
          {rightSide.map((milestone) => renderMilestone(milestone))}
        </Content>
      </div>
    </Container>
  );
};

export default CountryGlobalMilestones;

const Container = styled.div``;

const Content = styled.div`
  display: content;
  align-items: left;
  width: 100%;
  margin-right: 1em;
`;
