import React from "react";
import { AccordionTable } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { COLUMN_HEADERS } from "./table.utils";

import TableCell from "@material-ui/core/TableCell";

const CountryAndEnrollmentMilestones = () => {
  const { simulationResults } = useStudyPlan();
  const { enrollmentTable = [] } = simulationResults;

  return (
    <AccordionTable
      summary="Enrollment Milestones"
      columns={COLUMN_HEADERS}
      data={enrollmentTable}
      renderRow={(item) => (
        <>
          <TableCell component="th" scope="row">
            <strong>{item.SortName}</strong>
          </TableCell>
          <TableCell align="center">
            {item["Sites Initiated"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center">{item.Screened ?? "DD/MMM/YYYY"}</TableCell>
          <TableCell align="center">{item.Enrolled ?? "DD/MMM/YYYY"}</TableCell>
          <TableCell align="center">
            {item["Completed Treatment"] ?? "DD/MMM/YYYY"}
          </TableCell>
          <TableCell align="center">
            {item["Completed Follow-up"] ?? "DD/MMM/YYYY"}
          </TableCell>
        </>
      )}
    />
  );
};

export default CountryAndEnrollmentMilestones;
